import { useLocales } from '@/Locales';
import {
  httpStatisticAge,
  httpStatisticAgencyData,
  httpStatisticDepartment,
  httpStatisticDoctor,
  httpStatisticDrugRank,
  httpStatisticEnter,
  httpStatisticEnterSummary,
  httpStatisticGeneAndPathology,
  httpStatisticImage,
  httpStatisticJobTitle,
  httpStatisticMdtTrend,
  httpStatisticOrganization,
  httpStatisticProvinceTotal,
  httpStatisticRank,
  httpStatisticSex,
  httpStatisticSync,
  httpStatisticTrend,
} from '@/services/statistics';
import { userInfoAtom } from '@/store/atom';
import { agencyIdsAtom, agencyListsAtom } from '@/store/statistics';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

/**
 * @description: MDT数据统计 - BI 配置列表
 * @author: likaifeng
 * @param {string} username
 * @return {*}
 * @Date: 2023-12-29 17:16:27
 */
export const useAgency = () => {
  const setAgencyIds = useSetRecoilState(agencyIdsAtom);
  const setAgencyLists = useSetRecoilState(agencyListsAtom);
  const [loadingAgency, setLoadingAgency] = useState(false);
  const user = useRecoilValue(userInfoAtom);
  const userInfo = user?.user;

  /**
   * @description: BI 配置列表
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-12-29 17:24:40
   */
  const httpStatisticAgencyDataReq = async () => {
    try {
      setLoadingAgency(true);
      const res: any = await httpStatisticAgencyData({ username: userInfo?.username });
      if (res?.list?.length) {
        const ids = res?.list[0].zhAgencyUserData.map((item: { agencyId: any }) => item?.agencyId);
        setAgencyIds(ids);
        const list = res?.list[0].zhAgencyUserData.map(
          (item: { agencyId: string; agencyName: string }) => {
            return {
              id: item.agencyId,
              name: item.agencyName,
            };
          },
        );
        setAgencyLists([{ name: '全部', id: 0 }, ...list]);
      } else {
        setAgencyIds([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingAgency(false);
    }
  };

  useEffect(() => {
    userInfo && httpStatisticAgencyDataReq();
  }, []);

  return { loadingAgency };
};

/**
 * @description:  数据概览 - 机构MDT排名
 * @author: likaifeng
 * @param {string} agencyIds
 * @return {*}
 * @Date: 2023-12-29 17:12:29
 */
export const useOrganization = () => {
  const agencyIds = useRecoilValue(agencyIdsAtom);
  const [loadingOrganization, setLoadingOrganization] = useState(false);

  const [organizaSource, setOrganizaSource] = useState<any[]>([]);

  /**
   * @description: 机构MDT排名
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-12-29 17:32:39
   */
  const httpStatisticOrganizationReq = async () => {
    try {
      setLoadingOrganization(true);
      const res: any = await httpStatisticOrganization({ agencyIds });
      // console.log(res);
      if (res) {
        setOrganizaSource(
          res.sort((a: { total: number }, b: { total: number }) => (a.total < b.total ? 1 : -1)),
        );
      } else {
        setOrganizaSource([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingOrganization(false);
    }
  };

  useEffect(() => {
    agencyIds?.length && httpStatisticOrganizationReq();
  }, [agencyIds]);

  return { organizaSource, loadingOrganization };
};

/**
 * @description:  数据概览 - 近12月MDT开展场次趋势
 * @author: likaifeng
 * @param {string} agencyIds
 * @return {*}
 * @Date: 2023-12-29 17:12:29
 */
export const useMdtTrend = () => {
  const agencyIds = useRecoilValue(agencyIdsAtom);
  const [mdtTrendSource, setMdtTrendSource] = useState<any[]>([]);
  const [loadingMdtTrend, setLoadingMdtTrend] = useState(false);

  /**
   * @description: 近12月MDT开展场次趋势
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-12-29 17:32:39
   */
  const httpStatisticMdtTrendReq = async () => {
    try {
      setLoadingMdtTrend(true);
      const res: any = await httpStatisticMdtTrend({ agencyIds });
      // console.log(res);
      if (res) {
        setMdtTrendSource(
          res.sort((a: { time: string }, b: { time: any }) => a.time.localeCompare(b.time)),
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingMdtTrend(false);
    }
  };

  useEffect(() => {
    agencyIds?.length && httpStatisticMdtTrendReq();
  }, [agencyIds]);

  return { mdtTrendSource, loadingMdtTrend };
};

/**
 * @description: MDT 地市分布
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-29 18:02:12
 */
export const useProvinceTotal = () => {
  const agencyIds = useRecoilValue(agencyIdsAtom);
  const [provinceSource, setProvinceSource] = useState<any>();
  const [loadingProvinceTotal, setProvinceTotal] = useState(false);

  /**
   * @description: MDT 地市分布
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-12-29 17:32:39
   */
  const httpStatisticProvinceTotalReq = async () => {
    try {
      setProvinceTotal(true);
      const res: any = await httpStatisticProvinceTotal({ agencyIds });
      // console.log(res);
      setProvinceSource(res);
    } catch (err) {
      console.log(err);
    } finally {
      setProvinceTotal(false);
    }
  };

  useEffect(() => {
    agencyIds?.length && httpStatisticProvinceTotalReq();
  }, [agencyIds]);

  return { provinceSource, loadingProvinceTotal };
};

/**
 * @description: 药品使用排行
 * @author: likaifeng
 * @return {*}
 * @Date: 2024-01-02 15:29:39
 */
export const useDrugRank = () => {
  const agencyIds = useRecoilValue(agencyIdsAtom);
  const [reqParams, setReqParams] = useState({
    pageSize: 20,
    currentPage: 1,
  });
  const [drugRankSource, setDrugRankSource] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [drugRankLoading, setDrugRankLoading] = useState(false);

  /**
   * @description: 药品使用排行
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-02 15:29:47
   */
  const httpStatisticDrugRankReq = async () => {
    try {
      setDrugRankLoading(true);
      const res: any = await httpStatisticDrugRank({ agencyIds, ...reqParams });
      console.log(res);
      if (reqParams.currentPage === 1) {
        setDrugRankSource(res?.list);
      } else {
        setDrugRankSource([...drugRankSource, ...res.list]);
      }
      setTotalPages(res.pages);
    } catch (err) {
      console.log(err);
    } finally {
      setDrugRankLoading(false);
    }
  };

  useEffect(() => {
    agencyIds?.length && httpStatisticDrugRankReq();
  }, [reqParams, agencyIds]);

  return { drugRankSource, drugRankLoading, reqParams, setReqParams, totalPages, setTotalPages };
};

/**
 * @description: 已开展MDT场次，团队数，合作专家数，病例总数
 * @author: likaifeng
 * @return {*}
 * @Date: 2024-01-02 15:29:30
 */
export const useSync = () => {
  const agencyIds = useRecoilValue(agencyIdsAtom);
  const [syncSource, setSyncSource] = useState<any>();
  const [syncLoading, setSyncLoading] = useState(false);

  /**
   * @description: 已开展MDT场次，团队数，合作专家数，病例总数
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-02 15:29:22
   */
  const httpStatisticSyncReq = async () => {
    try {
      setSyncLoading(true);
      const res: any = await httpStatisticSync({ agencyIds });
      console.log(res);
      setSyncSource(res);
    } catch (err) {
      console.log(err);
    } finally {
      setSyncLoading(false);
    }
  };

  useEffect(() => {
    agencyIds?.length && httpStatisticSyncReq();
  }, [agencyIds]);

  return { syncSource, syncLoading };
};

/**
 * @description: 团队MDT排名
 * @author: likaifeng
 * @return {*}
 * @Date: 2024-01-02 15:29:12
 */
export const useRank = () => {
  const agencyIds = useRecoilValue(agencyIdsAtom);
  const [rankSource, setRankSource] = useState<any[]>([]);
  const [loadingRank, setLoadingRank] = useState(false);

  /**
   * @description: 团队MDT排名
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-02 15:29:03
   */
  const httpStatisticRankReq = async () => {
    try {
      setLoadingRank(true);
      const res: any = await httpStatisticRank({ agencyIds });
      // console.log(res);
      if (res) {
        setRankSource(
          res.sort(
            (
              a: { invitationRecordNum: number; consulNum: number },
              b: { invitationRecordNum: number; consulNum: number },
            ) =>
              a.invitationRecordNum + a.consulNum > b.invitationRecordNum + b.consulNum ? 1 : -1,
          ),
        );
      } else {
        setRankSource([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingRank(false);
    }
  };

  useEffect(() => {
    agencyIds?.length && httpStatisticRankReq();
  }, [agencyIds]);

  return { rankSource, loadingRank };
};

/**
 * @description: 科室人数占比
 * @author: likaifeng
 * @return {*}
 * @Date: 2024-01-02 15:28:43
 */
export const useDepart = () => {
  const agencyIds = useRecoilValue(agencyIdsAtom);
  const [departSource, setDepartSource] = useState<any[]>([]);
  const [loadingDepart, setLoadingDepart] = useState(false);

  /**
   * @description: 科室人数占比
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-02 15:28:37
   */
  const httpStatisticDepartmentReq = async () => {
    try {
      setLoadingDepart(true);
      const res: any = await httpStatisticDepartment({ agencyIds });
      // console.log(res);
      setDepartSource(res);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingDepart(false);
    }
  };

  useEffect(() => {
    agencyIds?.length && httpStatisticDepartmentReq();
  }, [agencyIds]);

  return { departSource, loadingDepart };
};

/**
 * @description:  医生职称占比
 * @author: likaifeng
 * @return {*}
 * @Date: 2024-01-02 15:34:50
 */
export const useJobTitle = () => {
  const agencyIds = useRecoilValue(agencyIdsAtom);
  const [jobTitleSource, setJobTitleSource] = useState<any[]>([]);
  const [loadingJobTitle, setLoadingJobTitle] = useState(false);

  /**
   * @description:  医生职称占比
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-02 15:34:45
   */
  const httpStatisticDepartmentReq = async () => {
    try {
      setLoadingJobTitle(true);
      const res: any = await httpStatisticJobTitle({ agencyIds });
      // console.log(res);
      setJobTitleSource(res);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingJobTitle(false);
    }
  };

  useEffect(() => {
    agencyIds?.length && httpStatisticDepartmentReq();
  }, [agencyIds]);

  return { jobTitleSource, loadingJobTitle };
};

/**
 * @description: 医生MDT次数排名TOP10
 * @author: likaifeng
 * @return {*}
 * @Date: 2024-01-02 15:56:47
 */
export const useDoctor = (params: any) => {
  const [doctorSource, setDoctorSource] = useState<any[]>([]);
  const [loadingDoctor, setLoadingDoctor] = useState(false);

  /**
   * @description: 医生MDT次数排名TOP10
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-02 15:56:42
   */
  const httpStatisticDoctorReq = async () => {
    try {
      setLoadingDoctor(true);
      const res: any = await httpStatisticDoctor(params);
      // console.log(res);
      setDoctorSource(res);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingDoctor(false);
    }
  };

  useEffect(() => {
    params?.agencyIds?.length && httpStatisticDoctorReq();
  }, [params]);

  return { doctorSource, loadingDoctor };
};

/**
 * @description: MDT开展场次趋势
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-02 17:35:38
 */
export const useTrend = (params: any) => {
  const agencyIds = useRecoilValue(agencyIdsAtom);
  const [trendSource, setTrendSource] = useState<any[]>([]);
  const [loadingTrend, setLoadingTrend] = useState(false);

  /**
   * @description: MDT开展场次趋势
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-02 15:56:42
   */
  const httpStatisticTrendReq = async () => {
    const p =
      params?.agencyId !== 0
        ? { agencyIds: JSON.stringify([params?.agencyId]) }
        : { agencyIds: JSON.stringify(agencyIds) };

    const start = new Date(params.startDate).getTime();
    const end = new Date(params.endDate).getTime();
    const type = end - start > 30 * 24 * 60 * 60 * 1000 ? 0 : 1;
    try {
      setLoadingTrend(true);
      const res: any = await httpStatisticTrend({
        ...p,
        type,
        startDate: params.startDate,
        endDate: params.endDate,
      });
      // console.log(res);
      if (res) {
        setTrendSource(
          res.sort((a: { month: string }, b: { month: any }) => a.month.localeCompare(b.month)),
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingTrend(false);
    }
  };

  useEffect(() => {
    agencyIds.length && httpStatisticTrendReq();
  }, [params, agencyIds]);

  return { trendSource, loadingTrend };
};

/**
 * @description: 入库病例增长趋势
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-03 09:58:25
 */
export const useEnter = (params: any) => {
  const agencyIds = useRecoilValue(agencyIdsAtom);
  const [enterSource, setEnterSource] = useState<any[]>([]);
  const [loadingEnter, setLoadingEnter] = useState(false);

  /**
   * @description: 入库病例增长趋势
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-03 09:58:18
   */
  const httpStatisticEnterReq = async () => {
    const p = params?.agencyId !== 0 ? { agencyIds: [params?.agencyId] } : { agencyIds };

    const start = new Date(params.startDate).getTime();
    const end = new Date(params.endDate).getTime();
    const type = end - start > 30 * 24 * 60 * 60 * 1000 ? 2 : 0;
    try {
      setLoadingEnter(true);
      const res: any = await httpStatisticEnter({
        ...p,
        type,
        startDate: params.startDate,
        endDate: params.endDate,
      });
      // console.log(res);
      if (res) {
        setEnterSource(
          res.sort((a: { createTime: string }, b: { createTime: any }) =>
            a.createTime.localeCompare(b.createTime),
          ) || [],
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingEnter(false);
    }
  };

  useEffect(() => {
    agencyIds.length && httpStatisticEnterReq();
  }, [params, agencyIds]);

  return { enterSource, loadingEnter };
};

/**
 * @description: 入库病例
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-03 10:06:34
 */
export const useEnterSummary = (params: any) => {
  const agencyIds = useRecoilValue(agencyIdsAtom);
  const [enterSummarySource, setEnterSummarySource] = useState<any>();
  const [loadingSummary, setLoadingSummary] = useState(false);

  /**
   * @description: 入库病例
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-03 10:06:29
   */
  const httpStatisticEnterSummaryReq = async () => {
    const p = params?.agencyId !== 0 ? { agencyIds: [params?.agencyId] } : { agencyIds };

    const start = new Date(params.startDate).getTime();
    const end = new Date(params.endDate).getTime();
    const type = end - start > 30 * 24 * 60 * 60 * 1000 ? 0 : 1;
    try {
      setLoadingSummary(true);
      const res: any = await httpStatisticEnterSummary({
        ...p,
        type,
        startDate: params.startDate,
        endDate: params.endDate,
      });
      // console.log(res);
      setEnterSummarySource(res);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingSummary(false);
    }
  };

  useEffect(() => {
    agencyIds.length && httpStatisticEnterSummaryReq();
  }, [params, agencyIds]);

  return { enterSummarySource, loadingSummary };
};

/**
 * @description: 患者年龄分布
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2024-01-03 10:19:55
 */
export const useAge = () => {
  const agencyIds = useRecoilValue(agencyIdsAtom);
  const [ageSource, setAgeSource] = useState<any[]>([]);
  const [loadingAge, setLoadingAge] = useState(false);
  const { locales } = useLocales();

  /**
   * @description: 患者年龄分布
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-03 10:19:20
   */
  const httpStatisticAgeReq = async () => {
    try {
      setLoadingAge(true);
      const res: any = await httpStatisticAge({ agencyIds });
      // console.log(res);
      setAgeSource(
        res?.map((item: any) => {
          return {
            ...item,
            name: locales === 'en' ? transItemName(item.name) : item.name,
          };
        }) || [],
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingAge(false);
    }
  };

  const transItemName = (name: string) => {
    let res = name;
    res = res.replace('小于', 'Less than ');
    res = res.replace('大于', 'Greater than ');
    res = res.replace('未知', 'Unknown');
    return res;
  };

  useEffect(() => {
    agencyIds?.length && httpStatisticAgeReq();
  }, [agencyIds]);

  return { ageSource, loadingAge };
};

/**
 * @description: 性别
 * @author: likaifeng
 * @return {*}
 * @Date: 2024-01-03 10:26:28
 */
export const useSex = () => {
  const agencyIds = useRecoilValue(agencyIdsAtom);
  const [sexSource, setSexSource] = useState<any[]>([]);
  const [loadingSex, setLoadingSex] = useState(false);

  /**
   * @description: 性别
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-03 10:26:21
   */
  const httpStatisticSexReq = async () => {
    try {
      setLoadingSex(true);
      const res: any = await httpStatisticSex({ agencyIds });
      // console.log(res);
      setSexSource(res);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingSex(false);
    }
  };

  useEffect(() => {
    agencyIds?.length && httpStatisticSexReq();
  }, [agencyIds]);

  return { sexSource, loadingSex };
};

/**
 * @description: 检测比例
 * @author: likaifeng
 * @return {*}
 * @Date: 2024-01-03 10:36:18
 */
export const useGeneAndPathology = () => {
  const agencyIds = useRecoilValue(agencyIdsAtom);
  const [geneAndPathologySource, setGeneAndPathologySource] = useState<any>();
  const [loadingPathology, setLoadingPathology] = useState(false);

  /**
   * @description: 检测比例
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-03 10:36:14
   */
  const httpStatisticGeneAndPathologyReq = async () => {
    try {
      setLoadingPathology(true);
      const res: any = await httpStatisticGeneAndPathology({ agencyIds });
      // console.log(res);
      setGeneAndPathologySource(res);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingPathology(false);
    }
  };

  useEffect(() => {
    agencyIds?.length && httpStatisticGeneAndPathologyReq();
  }, [agencyIds]);

  return { geneAndPathologySource, loadingPathology };
};

/**
 * @description: 影像类型比例
 * @author: likaifeng
 * @return {*}
 * @Date: 2024-01-03 10:45:00
 */
export const useImage = () => {
  const agencyIds = useRecoilValue(agencyIdsAtom);
  const [imageSource, setImageSource] = useState<any[]>([]);
  const [loadingImage, setLoadingImage] = useState(false);

  /**
   * @description: 影像类型比例
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-03 10:44:55
   */
  const httpStatisticImageReq = async () => {
    try {
      setLoadingImage(true);
      const res: any = await httpStatisticImage({ agencyIds });
      // console.log(res);
      if (res) {
        setImageSource(res.filter((el: any) => el.type));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingImage(false);
    }
  };

  useEffect(() => {
    agencyIds?.length && httpStatisticImageReq();
  }, [agencyIds]);

  return { imageSource, loadingImage };
};
