import usePermission from '@/hooks/usePermission';
import { useLocales } from '@/Locales';
import PatientInfo from '@/pages/patient/detail/components/PatientInfo';
import PatientTabs from '@/pages/patient/detail/components/PatientTabs';
import useAgencyScoringStrategy from '@/pages/patient/hooks/useAgencyScoringStrategy';
import usePatientOcrResult from '@/pages/patient/hooks/usePatientOcrResult';
import { getPatientInfo } from '@/services/patient';
import { CheckCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Spin, Tooltip } from '@sinohealth/butterfly-ui-components';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';
import Header from '../components/Header';
import styles from './index.less';

export const PatientInfoAtom = atom<any>({
  key: 'PatientInfo',
  default: null,
});
export const PatientInfoStatus = atom<any>({
  key: 'PatientInfoStatus',
  default: {
    edit: false,
  },
});
const PatientDetail = (props: any) => {
  const { disabled } = props;
  const [patientInfo, setPatientInfo] = useRecoilState<any>(PatientInfoAtom);
  const [patientInfoStatus, setPatientInfoStatus] = useRecoilState<any>(PatientInfoStatus);
  const permission = usePermission('patientDetail');
  const [params] = useSearchParams();
  const type = params.get('type');
  // 禁用，不可编辑
  const queryDisabled = params.get('disabled') === '1';
  const patientId = props.patientId || params.get('id') || '';
  const strategy = useAgencyScoringStrategy(); // 病例评分
  const showStrategy = !type; // 病例库、共享病例库进入不显示评分
  const [ocrResult] = usePatientOcrResult(patientId);
  const { t } = useLocales();

  const isMiniModal = !!props.patientId; // 是否小弹窗打开
  useEffect(() => {
    fetchPatientInfo();
    return () => {
      setPatientInfo(null);
    };
  }, []);

  useEffect(() => {
    setPatientInfoStatus({
      edit: permission.edit && !disabled && !queryDisabled,
    });
  }, [permission]);

  const fetchPatientInfo = () => {
    // setPatientInfo(null);
    getPatientInfo(patientId, type === 'share').then((res) => {
      setPatientInfo({
        ...res,
        update: fetchPatientInfo,
      });
    });
  };

  if (!patientInfo) {
    return null;
  }
  if (isMiniModal) {
    return <PatientTabs style={{ padding: 0 }} showBaseInfo />;
  }
  return (
    <div>
      <Header patientInfo={patientInfo} score={strategy && strategy?.strategyId}>
        {patientInfoStatus.edit ? (
          <>
            <Link to={`/patient/detail/patientOcr?patientId=${patientId}`}>
              <Button className={styles.btn} style={{ marginLeft: 0 }} type="primary" shape="round">
                <span className="iconfont icon-ocr" />
                &nbsp;
                {ocrResult.id && ocrResult.status !== 'new'
                  ? t('查看识别进度')
                  : t('上传病历智能识别')}
              </Button>
            </Link>
            <Tooltip
              placement="topLeft"
              arrowPointAtCenter
              color="#656565"
              title={
                <div>
                  {t(
                    '上传病历智能识别：支持上传多张图片后，系统智能识别图片内容，并自动新建到该患者病历对应模块中。',
                  )}
                </div>
              }
            >
              <QuestionCircleOutlined style={{ cursor: 'pointer', color: '#A8A8A8' }} />
            </Tooltip>
            {(ocrResult.status === 'taskCompleted' ||
              ['historyParsing', 'medicalParsing'].includes(ocrResult.status)) && (
              <span style={{ color: '#0b2145d9', marginLeft: 8, fontWeight: 400 }}>
                {['historyParsing', 'medicalParsing'].includes(ocrResult.status) && (
                  <span>
                    <Spin />
                    &nbsp;&nbsp;{t('智能识别中，请耐心等候…')}
                  </span>
                )}
                {ocrResult.status === 'taskCompleted' && (
                  <span>
                    <CheckCircleOutlined style={{ color: '#67C23A' }} />
                    &nbsp;{t('识别完成')}
                  </span>
                )}
              </span>
            )}
          </>
        ) : null}
      </Header>
      {/* {strategy && strategy?.strategyId && <QualityScore />} */}
      <PatientInfo />
      <div className={styles.patientDetailTabs}>
        <div className="but-title">{t('详细信息')}</div>
        <PatientTabs />
      </div>
    </div>
  );
};

export default PatientDetail;
