/* eslint-disable indent */
import { initSortable } from '@/pages/combinedWard/hook/useTimeline';
import { transformTumorStage } from '@/utils';
import classNames from 'classnames';
import { forwardRef, useEffect, useMemo } from 'react';
import { ToolFooter } from '..';
import styles from '../index.less';

interface InspecProps {
  edit?: boolean;
  move?: boolean;
  inspecData: any[];
  cardHeight: any;
  onDelete?: (val?: any) => void;
  onUpdate?: (value?: any) => void;
  onSort?: (val?: any) => void;
  onRela?: (id: string, ty: string) => void;
}

const Inspec = forwardRef((props: InspecProps, ref: any) => {
  const { edit, move, inspecData, cardHeight, onDelete, onRela, onUpdate, onSort } = props;

  const memoized = useMemo(() => inspecData, [inspecData]);

  useEffect(() => {
    if (!move && edit && inspecData.length > 1) {
      // console.log(move, edit);
      initSortable(ref, onSort, move || !edit);
    }
  }, [memoized, edit, move]);

  return (
    <div
      ref={ref}
      className={classNames(styles.card, styles['inspec-card'], styles['line-border-bottom'])}
      style={
        {
          '--lineHeight': `${cardHeight.imageHeight / 2 + cardHeight.inspectHeight / 2}px`,
        } as any
      }
    >
      {memoized?.map((el) => (
        <div
          key={el?.id}
          id={JSON.stringify(el)}
          className={classNames(edit && !move && 'handle', styles.inspec)}
        >
          <div className={classNames(styles.name)}>
            <span className={classNames(styles.round)} />
            <span className={classNames(styles.txt)}>
              {el?.nodeType === 'INSPECTION_REPORT'
                ? `${el?.tumorMarkers}：${el?.resultValueItem || ''}${el?.unit || ''}`
                : ''}
              {el?.nodeType === 'PATHOLOGY_REPORT'
                ? `${`病理：${el?.diagnosisDescription?.summary || ''}`}${`, 免疫组化：${
                    el?.diagnosisDescription?.immunohistochemistry || ''
                  }`}${`, 病理分期：${transformTumorStage(
                    el?.diagnosisDescription?.tumorStaging || '',
                  )}`}`
                : ''}
              {el?.nodeType === 'GENETIC_TESTING'
                ? `${`基因：${el?.sampleType ? `${el?.sampleType},` : ''}${el?.description || ''}`}`
                : ''}
            </span>
          </div>
          {el?.refContent && (
            <div className={classNames(styles['link-box'])}>
              <a
                onClick={() =>
                  onRela?.(
                    el?.nodeType === 'INSPECTION_REPORT' ? el?.checkingId : el?.refId,
                    el?.refType,
                  )
                }
              >
                ({el?.refContent})
              </a>
            </div>
          )}
          <ToolFooter
            edit={edit}
            onEdit={(e: any) => {
              e.preventDefault();
              onUpdate?.(el);
            }}
            onDel={() => onDelete?.(el)}
          />
        </div>
      ))}
    </div>
  );
});

export default Inspec;
