/* eslint-disable indent */
import BaseFormModal from '@/common/components/BaseFormModal';
import Image from '@/components/Image';
import useDict from '@/hooks/dict/useDict';
import useCurrentLoginUserInfo from '@/hooks/useCurrentLoginUserInfo';
import usePermission from '@/hooks/usePermission';
import { useLocales } from '@/Locales';
import FormModalContent from '@/pages/doctor/components/DoctorInfoCard/components/PasswordForm';
import AddForm from '@/pages/doctor/list/components/AddForm';
import { getDoctorAccount, getDoctorInfo, updateDoctorAccount } from '@/services/doctor';
import { updateDoctorInfo } from '@/services/doctor/doctorInfo';
import { message } from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './index.less';

const avatar = require('@/assets/images/doctor/doctorAssistantAvatar.png');

const BaseInfoCard = (props: any) => {
  const { disabled = false, showHeader = true, showAccount = true } = props;
  const formModal = useRef<any>();
  const passwordModal = useRef<any>();
  const [params] = useSearchParams();
  const [doctocDetail, setDoctocDetail] = useState<any>({});
  const [accountInfo, setAccountInfo] = useState<any>({});
  const [userInfo] = useCurrentLoginUserInfo();
  const id = props.id || params.get('id') || '';
  const genderDict = useDict('gender');
  const permission = usePermission('doctorDetail');
  const { t } = useLocales();

  useEffect(() => {
    httpGetDoctorInfoReq();
  }, []);

  const httpGetDoctorInfoReq = () => {
    getDoctorInfo(id).then((res) => {
      setDoctocDetail(res);
      // fetchDoctorAccount(res.userId);
    });
  };

  const fetchDoctorAccount = (userId: string = doctocDetail.userId) => {
    getDoctorAccount({
      id: userId,
    }).then((res) => {
      setAccountInfo(res || {});
    });
  };

  const handleOpenFormModal = () => {
    formModal.current?.openModal({
      ...doctocDetail,
      mdtNames,
      // userName: accountInfo?.username,
      // password: accountInfo?.pwd,
      agency: {
        label: doctocDetail.agencyName,
        value: doctocDetail.agencyId,
      },
      hospital: {
        label: doctocDetail.hospitalName,
        value: doctocDetail.hospitalId,
      },
      department: {
        label: doctocDetail.departmentName,
        value: doctocDetail.departmentId,
      },
      jobTitleObj: doctocDetail.jobTitleId
        ? {
            label: doctocDetail.jobTitle,
            value: doctocDetail.jobTitleId,
          }
        : null,
    });
  };

  const handleSubmit = async (formValue: any) => {
    // eslint-disable-next-line no-shadow
    const {
      // eslint-disable-next-line no-shadow
      id,
      password,
      userName,
      phone,
      agency,
      hospital,
      department,
      jobTitleObj = {},
      notify,
      ...other
    } = formValue;
    await handleChangePassword(
      {
        phone,
        password,
        avatar: formValue.avatar,
        username: userName,
      },
      true,
    );

    const paramsData = {
      id: doctocDetail.id,
      avatar: formValue.avatar,
      gender: formValue.gender,
      name: formValue.name,
      userName: formValue.userName,
      hospitalId: hospital.value,
      hospitalName: hospital.label,
      departmentId: department.value,
      departmentName: department.label,
      jobTitle: jobTitleObj?.label || '',
      jobTitleId: jobTitleObj?.value || null,
      phone: formValue.phone,
      introduction: formValue.introduction,
      lastEditorId: userInfo.user.id,
      lastEditorName: userInfo.user.realName,
    };
    await updateDoctorInfo(paramsData);
    message.success(t('修改成功'));
    httpGetDoctorInfoReq();
  };

  const handleOpenPasswordModal = () => {
    passwordModal.current?.openModal({
      password: accountInfo?.pwd,
      lastLoginTime: accountInfo?.lastLoginTime
        ? dayjs(accountInfo?.lastLoginTime).format('YYYY-MM-DD HH:mm:ss')
        : '',
    });
  };

  const handleChangePassword = (formData: any, noMsg = false) => {
    const d = { ...formData };
    delete d.lastLoginTime;
    return updateDoctorAccount({
      id: doctocDetail.userId,
      ...d,
    }).then(() => {
      if (!noMsg) {
        message.success(t('修改成功'));
      }
      fetchDoctorAccount();
    });
  };
  const mdtNames =
    doctocDetail.zhMdtTeams && doctocDetail.zhMdtTeams.length > 0
      ? doctocDetail.zhMdtTeams?.map((item: any) => item.name).join(';')
      : '--';
  return (
    <div className={styles.info}>
      {showHeader && (
        <div className={styles.header}>
          <div className="but-title">
            {t('医生详情')}
            {permission.edit && !disabled && (
              <div className={styles.action}>
                <a onClick={handleOpenFormModal}>{t('编辑')}</a>
              </div>
            )}
          </div>
        </div>
      )}
      <div className={styles.body}>
        <div className={styles.avatarBox}>
          <Image className={styles.avatar} src={doctocDetail.avatar || avatar} />
          <div className={styles.name}>
            <div>{doctocDetail.name}</div>
            <div>{doctocDetail.hospitalName}</div>
          </div>
        </div>
        <div
          className={[styles.fields, styles.baseInfoFields].join(' ')}
          style={{ background: '#F4F8FE' }}
        >
          <div className={styles.item}>
            {t('性别')}：{doctocDetail?.gender}
          </div>
          <div className={styles.item}>
            {t('科室')}：{doctocDetail?.departmentName}
          </div>
          <div className={styles.item}>
            {t('临床职称')}：{doctocDetail.jobTitleName || '--'}
          </div>
        </div>
        <div className={styles.fields}>
          <div className={styles.title}>
            <span className="iconfont icon-yishengjibenxinxi" />
            {t('账号信息')}
            {permission.edit && !disabled && (
              <a onClick={handleOpenPasswordModal}>{t('登录管理')}</a>
            )}
          </div>
          {showAccount && (
            <>
              <div className={styles.item}>
                {t('账号')}：{doctocDetail?.username}
              </div>
              <div className={styles.item}>
                {t('手机号码')}：{doctocDetail?.phone || '--'}
              </div>
            </>
          )}
          <div className={styles.item}>
            {t('所属MDT团队')}：
            <span className="text-ellipsis" style={{ width: 220 }} title={mdtNames}>
              {mdtNames}
            </span>
          </div>
          <div className={styles.item}>
            {t('所属机构')}：{doctocDetail.organizeName}
          </div>
          <div className={styles.item}>
            {t('所属医助')}：{doctocDetail?.assistantName}
          </div>
        </div>

        <div className={styles.fields} style={{ borderTop: '1px #0b214540 dashed' }}>
          <div className={styles.title}>
            <span className="iconfont icon-yishengjianjie" />
            {t('医生简介')}
          </div>
          <div>{doctocDetail.introduction || '--'}</div>
        </div>
      </div>

      <BaseFormModal
        width={560}
        title={t('编辑医生信息')}
        confirmCancel={true}
        formModal={formModal}
        FormContent={(p: any) => <AddForm isUpdate {...p} />}
        onSubmit={handleSubmit}
      />
      <BaseFormModal
        title={t('登录管理')}
        okText={t('修改密码')}
        formModal={passwordModal}
        onSubmit={handleChangePassword}
        FormContent={FormModalContent}
      />
    </div>
  );
};

export default BaseInfoCard;
