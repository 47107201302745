/* eslint-disable indent */
import DateInput from '@/components/DateInput';
import Label from '@/components/Label';
import { useLocales } from '@/Locales';
import { updatePatientMedicalOcrItem } from '@/services/patient';
import { Badge, Form, Popconfirm, message } from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';
import lodash from 'lodash';
import React, { useCallback, useImperativeHandle } from 'react';
import styles from './index.less';

export const dateConfig: any = {
  medicalRecords: {
    label: '入院时间',
    key: 'recordTime',
  },
  hospitalDischargeRecords: {
    label: '出院时间',
    key: 'dischargeTime',
  },
  imageReports: {
    label: '检查时间',
    key: 'recordTime',
  },
  surgicalRecords: {
    label: '手术时间',
    key: 'recordTime',
  },
  pathologyReports: {
    label: '检查时间',
    key: 'recordTime',
  },
  inspectionReports: {
    label: '检查时间',
    key: 'recordTime',
  },
  geneticTestings: {
    label: '检查时间',
    key: 'recordTime',
  },
  drugRecords: {
    label: '医嘱时间',
    key: 'recordTime',
  },
  otherInformations: {
    label: '资料时间',
    key: 'informationDate',
  },
};
const RecordItemBox = (props: any, ref: any) => {
  const { formContent, type, value, onValuesChange, onDelete } = props;
  const [form] = Form.useForm();
  const { t } = useLocales();

  useImperativeHandle(
    ref,
    () => {
      return {
        form,
      };
    },
    [form],
  );
  const handleDelete = () => {
    onDelete && onDelete(value.bizId, type);
  };
  const handleValuesChange = (changeValue: any, formValue: any) => {
    const data = {
      formValue: {
        ...value,
        ...formValue,
      },
      type,
    };
    onValuesChange && onValuesChange(data);
    handleSubmit(data);
  };
  const handleSubmit = useCallback(
    lodash.debounce((data: any) => {
      const formValue = { ...data.formValue };
      if (type === 'drugRecords' && formValue.medicationTime?.length > 0) {
        formValue.startMedicationTime = formValue.medicationTime[0];
        formValue.endMedicationTime = formValue.medicationTime[1];
      }
      updatePatientMedicalOcrItem({
        id: data.formValue.bizId,
        jsonValue: JSON.stringify(formValue),
      }).then(() => {
        message.success(t('自动保存成功'));
      });
    }, 1500),
    [],
  );

  const initialValues =
    type === 'drugRecords'
      ? {
          ...value,
          // 用药时间字段特殊处理
          medicationTime:
            value.startMedicationTime && value.endMedicationTime
              ? [value.startMedicationTime, value.endMedicationTime].map(dayjs)
              : null,
        }
      : value;
  return (
    <div className={styles.recordItemBox}>
      <Form
        layout="vertical"
        form={form}
        validateTrigger="onSubmit"
        initialValues={initialValues}
        onValuesChange={handleValuesChange}
      >
        <div className={styles.header}>
          <Badge status="processing" />
          <span className={styles.label}>
            <span style={{ color: '#ff4d4f', marginRight: 4, fontFamily: 'SimSun,sans-serif' }}>
              *
            </span>
            {t(dateConfig[type].label)}
          </span>
          <Form.Item
            name={dateConfig[type].key}
            rules={[{ required: true, message: t('该字段为必填项') }]}
          >
            <DateInput style={{ width: 320 }} />
          </Form.Item>
          <Popconfirm
            title={t('删除该条识别内容？')}
            placement="left"
            onConfirm={handleDelete}
            okButtonProps={{ danger: true }}
            okText={t('确定')}
            cancelText={t('取消')}
          >
            <a className={styles.action}>{t('删除')}</a>
          </Popconfirm>
        </div>
        <div className={styles.body}>
          <div className={styles.left}>{formContent}</div>
          <div className={styles.right}>
            <Label>OCR识别结果</Label>
            <div className={styles.content}>{value?.content || t('无')}</div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default React.forwardRef(RecordItemBox);
