import localEnum from '@/hooks/dict/localEnum';
import { useLocales } from '@/Locales';
import * as echarts from 'echarts';
import React, { CSSProperties, useEffect } from 'react';

interface IssueBarEchartProps {
  title: string;
  seriesData: any;
  style?: CSSProperties | undefined; // 样式
}

const IssueBarEchart: React.FC<IssueBarEchartProps> = (props) => {
  const { LETTER_LIST } = localEnum;
  const domId = `issue-options-id-${Math.ceil(Math.random() * 10000)}`;
  const { title, seriesData } = props;
  const { t, locales } = useLocales();

  useEffect(() => {
    const xAxisData =
      (seriesData &&
        seriesData?.percentageResult?.map((item: any, inx: number) => {
          return LETTER_LIST[inx];
        })) ||
      [];
    const opt = {
      title: {
        show: true,
        text: t(title) || '',
        left: '10px',
        top: '10px',
        padding: 5,
        textStyle: {
          fontSize: 14,
        },
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: seriesData.percentageResult,
          type: 'bar',
          label: {
            show: true,
            position: 'top',
            formatter: (v: any) => {
              return `${v.value}%\n${seriesData.peopleResult[LETTER_LIST.indexOf(v.name)]}`;
            },
          },
        },
      ],
      grid: {
        left: '40px',
        right: '10px',
        top: '70px',
        bottom: '30px',
      },
    };
    const chartDom = document.getElementById(domId);
    const mychart = echarts.init(chartDom) as any;
    mychart.setOption(opt);

    setTimeout(() => {
      mychart?.resize();
    }, 500);

    function resize() {
      if (mychart) {
        mychart?.resize();
      }
    }
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [seriesData, locales]);

  return (
    <div
      id={domId}
      style={{ maxWidth: 382, width: '100%', height: '300px', background: '#fff', ...props?.style }}
    />
  );
};

export default IssueBarEchart;
