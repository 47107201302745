/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { httpPatientInfo } from '@/services/consultation';
import {
  httpDrugRecordsGetOne,
  httpGeneticTestingGetOne,
  httpHospitalDischargeDetail,
  httpImagesGetOne,
  httpInspectionGetOne,
  httpMedicalGetOne,
  httpOtherInfoDetail,
  httpPathologyGetOne,
  httpSurgicalGetOne,
  httpTimeLineLectureNoteDetail,
  httpTimeLineLectureNoteSave,
} from '@/services/unionward';
import { formatToDate } from '@/utils';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// @ts-ignore
import Sortable from 'sortablejs';

/**
 * @description: 处理时间轴数据
 * @author: KaifengLi
 * @param {any} list 数据源
 * @param {any} val 需要处理的数据
 * @param {string} fileId 对应的字段
 * @version: v2.24.0
 * @Date: 2024-10-15 11:18:25
 */
export const handleDispose = (list: any, val: any, fileId: string, currentTime: string) => {
  const timeLineList = list;
  // 1. 找到对应的dateStr数组
  const d = timeLineList.filter(
    (el: any) => el?.dateStr === formatToDate(val?.recordTime, 'YYYY-MM'),
  )?.[0];
  console.log(d);
  // 2. 判断是否已有存在的数据
  if (d) {
    // 找到对应的dateStr的索引
    const patientIndex = timeLineList.findIndex(
      (el: any) => el?.dateStr === formatToDate(val?.recordTime, 'YYYY-MM'),
    );
    // 如果是编辑数据，有id，则更新
    if (val.id) {
      // 找到对应的id，更新数据
      const index = d[fileId].findIndex((el: any) => el.id === val.id);
      if (index !== -1) {
        // 删除更新
        d[fileId].splice(index, 1, val);
        console.log({ d });

        const newTimeLineList = timeLineList.map((item: any, index: number) => {
          return index === patientIndex ? d : item;
        });
        // 更新数据源
        return newTimeLineList;
      }
      console.log('没有找到对应的id');
      d[fileId].push(val);
      const newTimeLineList = timeLineList.map((item: any, index: number) => {
        return index === patientIndex ? d : item;
      });
      // 更新数据源
      return newTimeLineList;
    }
    d[fileId].push(val);
    const newTimeLineList = timeLineList.map((item: any, index: number) => {
      return index === patientIndex ? d : item;
    });
    // 更新数据源
    return newTimeLineList;
  }
  // 没有数据
  const newData = {
    dateStr: formatToDate(val?.recordTime, 'YYYY-MM'),
    imageReportRecords: [val],
    detectionRecords: [],
    treatmentRecords: [],
    diagnosisRecords: [],
  };
  timeLineList.push(newData);

  return [...timeLineList].sort(
    (a, b) => new Date(a.dateStr).getTime() - new Date(b.dateStr).getTime(),
  );
};

/**
 * @description: 获取时间轴讲稿详情
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-16 16:02:19
 */
export const useTimelineDetail = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];

  const [reloadDetail, setReloadDetail] = useState<boolean>(false);
  const [hasTreatmentRecords, setHasTreatmentRecords] = useState<boolean>(false);
  const [timelineDetail, setTimelineDetail] = useState<any>();

  const httpTimeLineLectureNoteDetailReq = async () => {
    try {
      const res = await httpTimeLineLectureNoteDetail({
        patientId,
      });
      const timelineList = res?.timeLineList.map((el: any) => {
        el?.detectionRecords.map((item: any) => {
          if (item.diagnosisDescription) {
            // eslint-disable-next-line no-param-reassign
            item.diagnosisDescription = JSON.parse(item.diagnosisDescription);
          }

          return item;
        });
        return el;
      });
      const treat = timelineList.filter((el: any) => el.treatmentRecords.length > 0).length > 0;
      setHasTreatmentRecords(treat);
      setTimelineDetail({ ...res, timeLineList: timelineList });
    } catch (err) {
      console.log(err);
    } finally {
      setReloadDetail(false);
    }
  };

  useEffect(() => {
    patientId && httpTimeLineLectureNoteDetailReq();
  }, [patientId]);

  useEffect(() => {
    reloadDetail && httpTimeLineLectureNoteDetailReq();
  }, [reloadDetail]);

  return { timelineDetail, setTimelineDetail, setReloadDetail, hasTreatmentRecords };
};

/**
 * @description: 保存时间轴
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-16 16:02:35
 */
export const useTimeLineSave = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const [formParams, setFormParams] = useState<any>({});
  const [timelineParams, setTimelineParams] = useState<any>();
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);

  const httpTimeLineLectureNoteSaveReq = async () => {
    try {
      const res = await httpTimeLineLectureNoteSave(timelineParams);
      setSaveSuccess(true);
    } catch (err) {
      console.log(err);
    } finally {
      setTimelineParams(undefined);
      setSaveSuccess(false);
    }
  };

  useEffect(() => {
    timelineParams && httpTimeLineLectureNoteSaveReq();
  }, [timelineParams]);

  return { setTimelineParams, saveSuccess, formParams, setFormParams, patientId };
};

/**
 * @description: 患者列表
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-16 16:02:49
 */
export const usePatientDetail = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const [reload, setReload] = useState<boolean>(false);
  const [patientDetail, setPatientDetail] = useState<any>({
    MEDICAL_RECORD: [], // 入院记录
    HOSPITAL_DISCHARGE_RECORD: [], // 出院记录
    IMAGE_REPORT: [], // 影像报告
    SURGICAL_RECORD: [], // 手术信息
    PATHOLOGY_REPORT: [], // 病理报告
    INSPECTION_REPORT: [], // 检验报告
    GENETIC_TESTING: [], // 基因检测
    DRUG_RECORD: [], // 用药记录
    OTHER_INFORMATION: [], // 其他信息
    COMPARISO_CHART: [], // 对比图
    CORRELATION_CHART: [], // 趋势图
  });

  const httpPatientInfoReq = async () => {
    try {
      const result: any = await httpPatientInfo(patientId, '');
      const data = {
        MEDICAL_RECORD: result?.MedicalRecords || [], // 入院记录
        HOSPITAL_DISCHARGE_RECORD: result?.hospitalDischargeRecords || [], // 出院记录
        IMAGE_REPORT: result?.zhImageReports || [], // 影像报告
        SURGICAL_RECORD: result?.zhSurgicalRecords || [], // 手术信息
        PATHOLOGY_REPORT:
          result?.zhPathologyReports?.map((el: any) => {
            el.diagnosisDescription = JSON.parse(el.diagnosisDescription);
            return el;
          }) || [], // 病理报告
        INSPECTION_REPORT: result?.zhInspectionReports || [], // 检验报告
        GENETIC_TESTING: result?.zhGeneticTestings || [], // 基因检测
        DRUG_RECORD: result?.zhDrugRecords || [], // 用药记录
        OTHER_INFORMATION: result?.otherInformations || [], // 其他信息
        COMPARISO_CHART: result?.comparisoCharts || [], // 对比图
        CORRELATION_CHART: result?.correlationCharts || [], // 趋势图
      };
      // console.log(data);
      setPatientDetail(data);
    } catch (err) {
      console.log(err);
    } finally {
      setReload(false);
    }
  };

  useEffect(() => {
    patientId && httpPatientInfoReq();
  }, [patientId]);

  useEffect(() => {
    reload && httpPatientInfoReq();
  }, [reload]);

  return { patientDetail, setReload };
};

/**
 * @description: 拖拽排序初始化
 * @author: KaifengLi
 * @param {any} ref 实例
 * @param {any} onSort 排序回调
 * @version: v2.24.0
 * @Date: 2024-10-16 16:03:19
 */
export const initSortable = (ref: any, onSort?: any, disabled?: boolean) => {
  const el: any = ref.current;
  if (!el) {
    return null;
  }
  let isSortIng = false;
  const save = _.debounce(() => {
    if (isSortIng) {
      return;
    }
    const data = [];
    for (let i = 0; i < el.children.length; i += 1) {
      const item = el.children[i];
      if (item.id) {
        data.push({
          ...JSON.parse(item.id),
          sort: i + 1,
        });
      }
    }
    onSort?.(
      data.map((item: any) => {
        if (item?.diagnosisDescription) {
          // eslint-disable-next-line no-param-reassign
          item.diagnosisDescription = JSON.stringify(item.diagnosisDescription);
        }
        return item;
      }),
    );
  }, 3000);
  // eslint-disable-next-line no-new
  const sortable = new Sortable(el, {
    handle: '.handle',
    disabled, // 禁止拖拽
    filter: '[data-no-drag="true"]', // 禁止 data-no-drag 属性为 "true" 的元素拖拽
    onStart() {
      isSortIng = true;
    },
    onMove(e: any) {
      return e.related.className.indexOf('filtered') === -1;
    },
    onEnd() {
      isSortIng = false;
      save();
    },
  });

  return sortable;
};

/**
 * @description: 根据患者id,会诊id 查询会诊议题数据
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-18 13:44:23
 */
export const useIssueVote = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const consultationId = params.getAll('consultationId')[0];

  const [pushedIssueVote, setPushedIssueVote] = useState(false);
  const [patientMdtIssues, setPatientMdtIssues] = useState<any[]>([]);

  const httpPatientInfoReq = async () => {
    try {
      const res: any = await httpPatientInfo(patientId, consultationId);
      console.log(res);
      setPushedIssueVote(res?.pushedIssueVote);
      setPatientMdtIssues(res?.zhPatientMdtIssues);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    patientId && consultationId && httpPatientInfoReq();
  }, [patientId, consultationId]);

  return { pushedIssueVote, patientMdtIssues };
};

/**
 * @description: 时间轴讲稿-关联数据
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-18 13:53:24
 */
export const useTimelineRelaDetail = () => {
  const [relaParams, setRelaParams] = useState<any>();
  const [refType, setRefType] = useState<string>('MEDICAL_RECORD');

  const apiEmuns: any = {
    MEDICAL_RECORD: httpMedicalGetOne, // 入院记录
    HOSPITAL_DISCHARGE_RECORD: httpHospitalDischargeDetail, // 出院记录
    IMAGE_REPORT: httpImagesGetOne, // 影像报告
    SURGICAL_RECORD: httpSurgicalGetOne, // 手术信息
    PATHOLOGY_REPORT: httpPathologyGetOne, // 病理报告
    INSPECTION_REPORT: httpInspectionGetOne, // 检验报告
    GENETIC_TESTING: httpGeneticTestingGetOne, // 基因检测
    DRUG_RECORD: httpDrugRecordsGetOne, // 用药记录
    OTHER_INFORMATION: httpOtherInfoDetail, // 其他信息
    COMPARISO_CHART: 'compareImage', // 对比图
    CORRELATION_CHART: 'correlationCharts', // 趋势图
  };
  const httpTimelineRelaDetailReq = async () => {
    try {
      const res = await apiEmuns[refType](relaParams);
      console.log(res);
    } catch (err) {
      console.log(err);
    } finally {
      setRelaParams(undefined);
      setRefType('');
    }
  };

  useEffect(() => {
    relaParams && refType && httpTimelineRelaDetailReq();
  }, [relaParams]);

  return { setRelaParams, setRefType };
};

/**
 * @description: 表单按钮颜色
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-15 17:27:13
 */
export const formButtonColor: any = {
  ImageForm: '#1370F2',
  InspectForm: '#38B51F',
  GenesForm: '#38B51F',
  OtherForm: '#7446E0',
  PathologyForm: '#38B51F',
  TreatmentForm: '#E65370',
};

export const tranType: any = {
  MEDICAL_RECORD: 'medicalRecord', // 入院记录
  HOSPITAL_DISCHARGE_RECORD: 'hospitalDischargeRecords', // 出院记录
  IMAGE_REPORT: 'imageReport', // 影像报告
  SURGICAL_RECORD: 'surgicalRecord', // 手术信息
  PATHOLOGY_REPORT: 'pathologyReport', // 病理报告
  INSPECTION_REPORT: 'inspectionReport', // 检验报告
  GENETIC_TESTING: 'geneticTesting', // 基因检测
  DRUG_RECORD: 'drugRecord', // 用药记录
  OTHER_INFORMATION: 'otherInformation', // 其他信息
  COMPARISO_CHART: 'compareImage', // 对比图
  CORRELATION_CHART: 'correlationCharts', // 趋势图
  TREATMENT_OTHER_INFORMATION: 'medicalRecord', // 治疗-其他信息
  DIAGNOSIS: 'medicalRecord', // 诊断信息
  MDT_CONCLUSION: 'medicalRecord', // MDT结论
  TREATMENT_RADIOTHERAPY: 'medicalRecord', // 治疗-放疗
};

/**
 * @description: 关联讲稿时的转换
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-18 16:31:27
 */
export const tranRelaType: any = {
  MEDICAL_RECORD: '入院记录', // 入院记录
  HOSPITAL_DISCHARGE_RECORD: '出院记录', // 出院记录
  IMAGE_REPORT: '影像报告', // 影像报告
  SURGICAL_RECORD: '手术信息', // 手术信息
  PATHOLOGY_REPORT: '病理报告', // 病理报告
  INSPECTION_REPORT: '检验报告', // 检验报告
  GENETIC_TESTING: '基因检测', // 基因检测
  DRUG_RECORD: '用药记录', // 用药记录
  OTHER_INFORMATION: '其他信息', // 其他信息
  COMPARISO_CHART: '对比图', // 对比图
  CORRELATION_CHART: '趋势图', // 趋势图
};
