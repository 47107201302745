/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

/**
 * @description: position 拖拽
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-14 14:49:57
 */
export function usePositionDrag() {
  const [isDragging, setIsDragging] = useState(false);
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const [initialMousePosition, setInitialMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseDown = _.debounce(
    useCallback((e) => {
      setIsDragging(true);
      setInitialMousePosition({ x: e.clientX, y: e.clientY });
      setInitialPosition({ x: e.currentTarget.offsetLeft, y: e.currentTarget.offsetTop });
    }, []),
    1000,
  );

  const handleMouseMove = _.debounce(
    useCallback(
      (e) => {
        if (isDragging) {
          const dx = e.clientX - initialMousePosition.x;
          const dy = e.clientY - initialMousePosition.y;
          e.currentTarget.style.left = `${initialPosition.x + dx}px`;
          e.currentTarget.style.top = `${initialPosition.y + dy}px`;
        }
      },
      [isDragging, initialMousePosition, initialPosition],
    ),
    100,
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  return {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  };
}

/**
 * @description: 点击缩放
 * @author: KaifengLi
 * @param {*} initialScale
 * @version: v2.24.0
 * @Date: 2024-10-14 15:19:32
 */
export function useZoom(can?: boolean, top?: boolean) {
  const canvasRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);
  const [scaleFactor, setScaleFactor] = useState(1);

  const handleWheel = useCallback(
    (e) => {
      if (e.deltaY !== 0) {
        const newScale = scale + (e.deltaY > 0 ? -0.05 : 0.05);
        setScale(newScale < 0.5 ? 0.5 : newScale); // 防止缩放过小
        e.currentTarget.style.transform = `scale(${newScale})`;
      }
    },
    [scale],
  );

  // 在handleZoom函数中，添加调整滚动位置的逻辑
  const handleZoom = (direction: 'in' | 'out') => {
    if (!canvasRef.current) return;

    let newScale = scaleFactor;
    if (direction === 'in') {
      if (newScale <= 2) {
        newScale += 0.1; // 每次增加 10%
      }
    } else if (newScale >= 0.6) {
      newScale -= 0.1; // 每次减少 10%
    }

    // 设置新的缩放比例
    setScaleFactor(newScale);

    // 调整容器位置
    adjustContainerPosition();
  };

  const adjustContainerPosition = () => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;

    // 计算容器相对于视口的位置
    const containerWidth = canvas.clientWidth;
    const containerHeight = canvas.clientHeight;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // 计算新的位置
    const newX = (viewportWidth - containerWidth * scaleFactor) / 2;
    const newY = (viewportHeight - containerHeight * scaleFactor) / 2;

    // translate(${-newX / 2}px, ${-newY / 2}px)
    canvas.style.transform = `translate(${-newX}px, ${
      top && scaleFactor !== 1 ? -newY / 2 : 100
    }px) scale(${scaleFactor})`;
    canvas.style.padding = `${Math.abs(newX / 4)}px`;

    // if (containerRef.current) {
    //   containerRef.current.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'center',
    //   });
    // }
  };

  useEffect(() => {
    if (can) {
      adjustContainerPosition();
    }
  }, [scaleFactor]);

  return {
    handleWheel,
    handleZoom,
    scaleFactor,
    scale,
    canvasRef,
    containerRef,
  };
}

/**
 * @description: 利用 scrollTop,scrollLeft实现滚动条拖拽
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-14 14:57:18
 */
export const useScrollDrag = () => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [canDrag, setCanDrag] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [dragStartY, setDragStartY] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const handleMouseDown = (e: React.MouseEvent) => {
    // e.preventDefault();
    if (!canDrag) return;
    if (!sliderRef.current) {
      return;
    }
    const slider = sliderRef.current;
    setIsDragging(true);

    setDragStartX(e.clientX - slider.offsetLeft);
    setDragStartY(e.clientY - slider.offsetTop);

    setScrollLeft(slider.scrollLeft);
    setScrollTop(slider.scrollTop);
  };

  const handleMouseMove = (e: MouseEvent) => {
    // e.preventDefault();
    if (!isDragging || !sliderRef.current) {
      return;
    }

    const slider = sliderRef.current;

    const x = e.clientX - slider.offsetLeft;
    const y = e.clientY - slider.offsetTop;
    const dx = x - dragStartX;
    const dy = y - dragStartY;

    slider.scrollLeft = scrollLeft - dx;
    slider.scrollTop = scrollTop - dy;
  };

  const handleMouseUp = (e: MouseEvent) => {
    // e.preventDefault();
    setIsDragging(false);
    // if (!sliderRef.current) {
    //   return;
    // }

    // const slider = sliderRef.current;
  };

  const handleEmptyKeyDown = (e: KeyboardEvent) => {
    if (e.code === 'Space') {
      // 在这里添加你想要执行的代码
      setCanDrag(true);
    }
  };
  const handleEmptyKeyUp = (e: KeyboardEvent) => {
    if (e.code === 'Space') {
      // 在这里添加你想要执行的代码
      setCanDrag(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('keydown', handleEmptyKeyDown);
    document.addEventListener('keyup', handleEmptyKeyUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('keydown', handleEmptyKeyDown);
      document.removeEventListener('keyup', handleEmptyKeyUp);
    };
  }, [isDragging, canDrag]);

  return {
    sliderRef,
    handleMouseDown,
    isDragging,
    setIsDragging,
    handleMouseMove,
    handleMouseUp,
    canDrag,
    setCanDrag,
  };
};

export const useCenter = (parentEl: any, childEl: any) => {
  useLayoutEffect(() => {
    if (parentEl && childEl) {
      // parentEl代表父元素，也就是设置了overflow为可滚动的元素
      // childEl代表需要滚动到父元素正中间的直接子元素

      // 水平滚动
      setTimeout(() => {
        const offsetHeight = childEl.offsetHeight;
        const offsetWidth = childEl.offsetWidth;

        // console.log({ offsetHeight, offsetWidth });

        parentEl.scrollTo({
          left: offsetWidth / 1.5,
          top: offsetHeight / 3.5,
          behavior: 'smooth',
        });
      }, 500);
    }
  }, [parentEl, childEl]);
};
