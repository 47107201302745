import { useLocales } from '@/Locales';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from '@sinohealth/butterfly-ui-components';
import React from 'react';

interface FormLabelProps {
  label: string;
  tooltip?: boolean;
  toolText?: string;
}

const FormLabel: React.FC<FormLabelProps> = (props) => {
  const {
    toolText = '选择日期关联后，可在讲稿模式中，点击日期查看对应模块的图片/视频/dicom链接等详细内容',
  } = props;
  const { t } = useLocales();

  return (
    <div>
      {t(props.label)}
      {props?.tooltip && (
        <Tooltip title={t(toolText)} placement="right">
          {' '}
          <QuestionCircleOutlined />
        </Tooltip>
      )}
    </div>
  );
};

export default FormLabel;
