import AsyncSelect from '@/components/AsyncSelect';
import useCurrentLoginUserInfo from '@/hooks/useCurrentLoginUserInfo';
import { useLocales } from '@/Locales';
import { httpMdtTeamByAssistantId } from '@/services/mdtTeam';

const MdtTeamSelect = (props: any) => {
  const [userInfo] = useCurrentLoginUserInfo();
  const { t } = useLocales();

  const fetchMdtList = (v: string) => {
    return httpMdtTeamByAssistantId({
      assistantId: userInfo.employee.id,
      pageSize: 99999,
      currentPage: 1,
    }).then((res: any) => {
      if (!res) return [];
      return res.list.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    });
  };
  return (
    <AsyncSelect {...props} fetchOptions={fetchMdtList} placeholder={t('请选择所属MDT团队')} />
  );
};

export default MdtTeamSelect;
