import AsyncSelect from '@/components/AsyncSelect';
import { useLocales } from '@/Locales';
import { getDepartmentList } from '@/services/common';

const DepartmentSelect = (props: any) => {
  const { t } = useLocales();
  // 科室列表
  const fetchDepartmentList = (v: string) => {
    return getDepartmentList({
      pageSize: 99999,
      currentPage: 1,
      name: v,
    }).then((res) => {
      return res.list.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    });
  };
  return (
    <AsyncSelect
      {...props}
      showSearch
      fetchOptions={fetchDepartmentList}
      placeholder={t('请选择所在科室')}
    />
  );
};

export default DepartmentSelect;
