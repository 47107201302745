import { apiDoctorGetUserName } from '@/services/masterData';
import { existUser } from '@/services/sys';

export const phoneReg =
  /^\d{11}$/;

export const idCardReg =
  /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
/**
 * 校验手机号码是否符合
 * @param mobile 手机号码
 * @returns boolean
 */
export const validIsMobile = (mobile: string) => !!phoneReg.test(mobile);

/**
 * 验证密码是否含有字母，数字，符号的两种
 * @param value 密码
 * @returns boolean
 */
export const validIsPasswordReg = /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?.]+)$)^[\w~.!@#$%^&*?]{6,20}$/;
export const validIsPassword = (value: string) => {
  if (validIsPasswordReg.test(value)) return true;
  return false;
};
export const isNull = (str: string | undefined | null): boolean => {
  if (typeof str !== 'string') {
    return true;
  }
  if (str.length === 0) {
    return true;
  }
  return false;
};

export const validateUrl = (url: string) => {
  const re =
    /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~/])+$/;
  return re.test(url);
};

export const validPwd = (rule: any, value: any) => {
  const str = /(?!.*\s)(?!^[\u4e00-\u9fa5]+$)(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{8,16}$/;
  if (!value || value === '') {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('密码不能为空');
  }
  if (!str.test(value)) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('密码格式不正确');
  }
  return Promise.resolve();
};

export const validAccount = async (value: any, ignore?: string) => {
  const str = /^\w+$/;
  if (!value || value === '') {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('用户名不能为空');
  }
  if (!str.test(value)) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('用户名格式不正确');
  }
  if (value === ignore) {
    return Promise.resolve();
  }
  let res;
  try {
    res = await existUser({ username: value });
  } catch (e) {
    console.error(e);
  }
  // eslint-disable-next-line prefer-promise-reject-errors
  if (res) return Promise.reject('用户名重复');
  return Promise.resolve();
};

export default {
  validIsMobile,
  validIsPassword,
  idCardReg,
};
