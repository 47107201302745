import { getSystemUserInfo, setLocalStorage, setToken } from '@/utils/cookies';
import { CaretDownOutlined } from '@ant-design/icons';
import { Select } from '@sinohealth/butterfly-ui-components';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styles from './index.less';
import useCurrentLoginUserInfo from '@/hooks/useCurrentLoginUserInfo';
import { useLocales } from '@/Locales';
import { switchOrganize } from '@/services/common';

const Team = () => {
  const [userInfo, isError, reloadUserInfo] = useCurrentLoginUserInfo();
  const { t } = useLocales();
  const navigate = useNavigate();
  const options = userInfo.agencys?.map((agencyItem: any) => {
    return {
      label: agencyItem.organizeName,
      value: agencyItem.organizeId,
    };
  });

  const handleChangeTeam = (organizeId: string) => {
    // setUserInfo({
    //   ...userInfo,
    //   agency: userInfo.agencys.filter((item: any) => item.organizeId === agencyId)[0] || {},
    // });
    switchOrganize({
      organizeId,
    }).then(async (token: string) => {
      setToken(token);
      reloadUserInfo && await reloadUserInfo();
      navigate('/');
    });
  };

  if (options.length <= 1) {
    return null;
  }

  return (
    <div className={styles.team}>
      <div className={styles.left}>{t('当前访问项目')}</div>
      <div className={styles.right}>
        <Select
          onSelect={handleChangeTeam}
          value={userInfo.employee.organizeId}
          dropdownMatchSelectWidth={false}
          bordered={false}
          suffixIcon={<CaretDownOutlined style={{ color: 'rgba(11, 33, 69, 0.65)', pointerEvents: 'none' }} />}
          options={options}
        />
      </div>
    </div>
  );
};

export default Team;
