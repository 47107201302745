/*
 * @Description: 讲稿演示-主要内容-患者信息
 * @Author: likaifeng
 * @Date: 2023-11-30 16:12:41
 * @LastEditTime: 2024-10-18 16:59:31
 * @LastEditors: 阿峰
 */
import { FormItem } from '@/components/BaseForm';
import TumorStageSelect from '@/components/TumorStageSelect';
import { useLocales } from '@/Locales';
import { usePatientDetail } from '@/pages/speechLecture/hook/makeSpeech';
import SpeechCard from '@/pages/speechLecture/lecture/components/SpeechCard';
import { updatePatientInfo } from '@/services/patient';
import { transformTumorStage } from '@/utils';
import { gender } from '@/utils/constant';
import { Form, Input, InputNumber, Popover, Select } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import styles from './index.less';

const { TextArea } = Input;

interface SpeechPatientProps {
  edit?: boolean;
  canSave?: boolean;
}

/**
 * @description: 联合病房-时间轴讲稿-病例时间轴-患者数据
 * @author: KaifengLi
 * @param {*} props
 * @version: v2.24.0
 * @Date: 2024-10-16 15:38:26
 */
const TimelinePatient: React.FC<SpeechPatientProps> = (props) => {
  const { edit, canSave } = props;
  const [form] = Form.useForm();
  const { t } = useLocales();

  const { patientDetail, setReload } = usePatientDetail(); // 获取患者信息

  useEffect(() => {
    if (edit) {
      form.setFieldsValue(patientDetail);
    }
  }, [edit, patientDetail]);

  useEffect(() => {
    if (canSave) {
      form?.validateFields().then(
        (val) => {
          updatePatientInfo({
            id: patientDetail?.id,
            ...val,
          }).then(() => {
            console.log('更新成功');
            setReload(true);
          });
        },
        (err) => {
          // console.log(err);
        },
      );
    }
  }, [canSave]);

  return (
    <SpeechCard id="baseInfo" title="患者信息" style={{ marginBottom: 16 }}>
      <div className={styles['speech-patient']}>
        <Form form={form} name="patient" initialValues={patientDetail || {}} autoComplete="off">
          <div className={styles.container}>
            <ul className={styles.patient}>
              <Form.Item className={styles['speech-form-item']} name="name">
                <li className={styles['patient-item']}>
                  <span className={styles['item-label']}>{t('姓名')}</span>
                  {!edit ? (
                    <span className={styles['item-name']}>
                      {patientDetail?.name || t('未填写')}
                    </span>
                  ) : (
                    <Form.Item name="name" noStyle>
                      <Input placeholder="请输入患者名称" />
                    </Form.Item>
                  )}
                </li>
              </Form.Item>
              <Form.Item className={styles['speech-form-item']} name="gender">
                <li className={styles['patient-item']}>
                  <span className={styles['item-label']}>{t('性别')}</span>
                  {!edit ? (
                    <span className={styles['item-name']}>
                      {t(gender[patientDetail?.gender]) || t('未填写')}
                    </span>
                  ) : (
                    <Form.Item name="gender" noStyle>
                      <Select
                        placeholder="请选择"
                        options={[
                          { value: '0', label: '男' },
                          { value: '1', label: '女' },
                          { value: '2', label: '保密' },
                        ]}
                      />
                    </Form.Item>
                  )}
                </li>
              </Form.Item>
              <Form.Item className={styles['speech-form-item']} name="age">
                <li className={styles['patient-item']}>
                  <span className={styles['item-label']}>{t('年龄')}</span>
                  {!edit ? (
                    <span className={styles['item-name']}>{patientDetail?.age || t('未填写')}</span>
                  ) : (
                    <Form.Item name="age" noStyle>
                      <InputNumber placeholder="请输入患者年龄" style={{ width: '100%' }} />
                    </Form.Item>
                  )}
                </li>
              </Form.Item>
              <Form.Item className={styles['speech-form-item']} name="hospitalName">
                <li className={styles['patient-item']}>
                  <span className={styles['item-label']}>{t('医院')}</span>
                  <Popover content={patientDetail?.hospitalName || t('未填写')}>
                    {!edit ? (
                      <span className={styles['item-name']}>
                        {patientDetail?.hospitalName || t('未填写')}
                      </span>
                    ) : (
                      <Form.Item name="hospitalName" noStyle>
                        <Input placeholder="请输入医院名称" />
                      </Form.Item>
                    )}
                  </Popover>
                </li>
              </Form.Item>
              <FormItem
                className={styles['speech-form-item']}
                name="tumorStage"
                span={6}
                transformText={transformTumorStage}
              >
                <li className={styles['patient-item']}>
                  <span className={styles['item-label']}>{t('肿瘤分期')}</span>
                  {!edit ? (
                    <span className={styles['item-name']}>
                      {transformTumorStage(patientDetail?.tumorStage) || t('未填写')}
                    </span>
                  ) : (
                    <FormItem name="tumorStage" transformText={transformTumorStage} noStyle>
                      <TumorStageSelect style={{ width: 260 }} />
                    </FormItem>
                  )}
                </li>
              </FormItem>
            </ul>
            <Form.Item className={styles['speech-form-item']} name="latestDiagnosis">
              <div className={classNames(styles.footer, edit && styles['form-footer'])}>
                <span className={styles['footer-label']}>{t('最新诊断')}</span>
                <Popover content={patientDetail?.latestDiagnosis || t('未填写')}>
                  {!edit ? (
                    <span className={styles['footer-desc']}>
                      {patientDetail?.latestDiagnosis || t('未填写')}
                    </span>
                  ) : (
                    <Form.Item name="latestDiagnosis" noStyle>
                      <TextArea
                        placeholder="请输入最新诊断"
                        autoSize={{ minRows: 4, maxRows: 4 }}
                      />
                    </Form.Item>
                  )}
                </Popover>
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
    </SpeechCard>
  );
};

export default TimelinePatient;
