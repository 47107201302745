import { useLocales } from '@/Locales';
import {
  transformTumorStage,
  tumorStageM,
  tumorStageN,
  tumorStagePrefix,
  tumorStageT,
} from '@/utils';
import { Button, Checkbox, Col, Input, Row, Space } from '@sinohealth/butterfly-ui-components';
import classes from 'classnames';
import { useEffect, useRef, useState } from 'react';
import styles from './index.less';

const Column = (props: any) => {
  const { title, value, data, onSelect } = props;

  const handleClick = (event: any, val: number) => {
    event.stopPropagation();
    event.preventDefault();
    onSelect(val);
  };
  return (
    <div className={styles.column}>
      <div className={styles.title}>{title}</div>
      <div className={styles.body}>
        {data.map((text: string, index: number) => {
          const classNames = classes({
            [styles.selected]: value === index,
            [styles.selectItem]: true,
          });
          return (
            <div className={classNames} onClick={(e) => handleClick(e, index)} key={text}>
              {text}
            </div>
          );
        })}
      </div>
    </div>
  );
};
const TumorStageSelect = (props: any) => {
  const { value, onChange, disabled, placeholder, ...other } = props;
  const [open, setOpen] = useState(false);
  const [cacheValue, setCacheValue] = useState<any>([]);
  const [notClear, setNotClear] = useState(false); // 是否 未明确
  const input = useRef<any>(null);
  const { t } = useLocales();
  const defaultPlaceholder = t('请选择病理分期');

  useEffect(() => {
    if (!value) return;

    if (value === '未明确') {
      setCacheValue([]);
      setNotClear(true);
    } else {
      initCacheValue();
    }
  }, [value]);

  const handleChangeOpen = () => {
    if (disabled) return;
    setOpen(!open);
  };

  const initCacheValue = () => {
    // value格式为 '[1,2,3],[4]'
    // 转成[4,1,2,3]，分别对应4个列的值
    if (value) {
      try {
        const d = JSON.parse(`[${value}]`);
        setCacheValue(d.reverse().flat());
      } catch {
        console.error(t('肿瘤分期数据格式有误'));
      }
    }
  };

  const handleSelect = (type: number, val: string) => {
    input?.current?.focus();
    const newCacheValue = [...cacheValue];
    newCacheValue[type] = val;
    setCacheValue(newCacheValue);
    setNotClear(false);
  };

  const handleChangeNotClear = () => {
    setNotClear(!notClear);
    setCacheValue([]);
  };

  const handleCancel = () => {
    initCacheValue();
    handleChangeOpen();
  };

  const handleConfirm = () => {
    const returnData = [...cacheValue];
    // 数据转换：[4,1,2,3] =》"[1,2,3],[4]"
    const returnStr = JSON.stringify([returnData.splice(1, 3), returnData]);
    const res = returnStr.substring(1, returnStr.length - 1);
    onChange && onChange(notClear ? '未明确' : res);
    handleChangeOpen();
  };

  const content = (
    <div className={styles.content}>
      <Row>
        <Col span={5}>
          <Column
            title={t('前缀')}
            data={tumorStagePrefix}
            value={cacheValue[0]}
            onSelect={(v: string) => handleSelect(0, v)}
          />
        </Col>
        <Col span={9}>
          <Column
            title={t('T分期')}
            data={tumorStageT}
            value={cacheValue[1]}
            onSelect={(v: string) => handleSelect(1, v)}
          />
        </Col>
        <Col span={5}>
          <Column
            title={t('N分期')}
            data={tumorStageN}
            value={cacheValue[2]}
            onSelect={(v: string) => handleSelect(2, v)}
          />
        </Col>
        <Col span={5}>
          <Column
            title={t('M分期')}
            data={tumorStageM}
            value={cacheValue[3]}
            onSelect={(v: string) => handleSelect(3, v)}
          />
        </Col>
      </Row>
      <div className={styles.footer}>
        <Checkbox checked={notClear} onChange={handleChangeNotClear}>
          未明确
        </Checkbox>
        <Space>
          <Button size="small" onClick={handleCancel}>
            {t('取消')}
          </Button>
          <Button
            disabled={
              cacheValue.filter((item: any) => item !== undefined && item !== null).length !== 4 &&
              !notClear
            }
            size="small"
            type="primary"
            onClick={handleConfirm}
          >
            {t('确定')}
          </Button>
        </Space>
      </div>
    </div>
  );
  return (
    <div className={styles.tumorStageSelect} {...other}>
      <Input
        ref={input}
        value={transformTumorStage(value)}
        disabled={disabled}
        placeholder={placeholder || defaultPlaceholder}
        readOnly
        onClick={handleChangeOpen}
      />
      {open && content}
    </div>
  );
};

export default TumorStageSelect;
