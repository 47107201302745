import { useLocales } from '@/Locales';
import { Form, Input, InputNumber } from '@sinohealth/butterfly-ui-components/lib';

const AddFollowUpModal = (props: any) => {
  const { label, sort, ...etc } = props;
  const { t } = useLocales();
  // const changeValue = (e: any) => {
  //   const val: any = e.target.value;
  //   const weight = /^[0-9]*$/.test(val) ? String(val).replace('.', '') : '';
  //   etc.form.setFieldsValue({ weight });
  // };

  return (
    <Form {...etc} layout="vertical" labelCol={{ span: 24 }}>
      <Form.Item
        label={t(label)}
        name="name"
        rules={[{ required: true, message: t('请输入名称') }]}
      >
        <Input style={{ width: '100%' }} placeholder={t('请输入名称')} showCount maxLength={15} />
      </Form.Item>
      <Form.Item label={t(sort)} name="sort" rules={[{ required: true, message: t('请输入排序') }]}>
        <InputNumber
          style={{ width: '100%' }}
          placeholder={t('最多输入5位数字，数字越大，排序越靠前')}
          // onChange={changeValue}
          max={99999}
          maxLength={5}
        />
      </Form.Item>
    </Form>
  );
};

export default AddFollowUpModal;
