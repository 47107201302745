/* eslint-disable indent */
/*
 * @Description: 讲稿演示-MDT议题
 * @Author: likaifeng
 * @Date: 2023-11-30 19:23:34
 * @LastEditTime: 2024-10-28 17:22:36
 * @LastEditors: 阿峰
 */
import BaseFormModal from '@/common/components/BaseFormModal';
import localEnum from '@/hooks/dict/localEnum';
import { useLocales } from '@/Locales';
import { useIssueVote } from '@/pages/combinedWard/hook/useTimeline';
import IssueBarEchart from '@/pages/consultationCenter/components/IssueBarEchart';
import AddForm from '@/pages/patient/detail/components/MdtIssuesTab/components/AddForm';
import { useIssueVoteStatistics } from '@/pages/speechLecture/hook/make';
import { useIssueList } from '@/pages/speechLecture/hook/makeSpeech';
import SpeechCard from '@/pages/speechLecture/lecture/components/SpeechCard';
import { addPatientIssue, deletePatientIssue } from '@/services/patient';
import { PlusOutlined } from '@ant-design/icons';
import { Button, message } from '@sinohealth/butterfly-ui-components';
import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './index.less';
import IssueForm from './IssueForm';

interface SpeechIssueProps {
  edit?: boolean;
}

/**
 * @description: 讲稿演示-MDT议题
 * @author: likaifeng
 * @param {*} props
 * @return {*}
 * @Date: 2023-12-04 14:27:11
 */
const SpeechIssue: React.FC<SpeechIssueProps> = (props) => {
  const { edit } = props;

  const [params] = useSearchParams();
  const consultationId = params.getAll('consultationId')[0];

  const formModal = useRef<any>();
  const [beforeSeriesDataMap, setBeforeSeriesDataMap] = useState<any>({});
  const [afterSeriesDataMap, setAfterSeriesDataMap] = useState<any>({});
  const [issue, setIssue] = useState<any[]>([]);
  const { t } = useLocales();

  const { pushedIssueVote, patientMdtIssues } = useIssueVote(); // 获取患者信息
  const { setReload, issueList, patientId } = useIssueList();
  const { issueVoteStatistics } = useIssueVoteStatistics(consultationId) as any;

  const { MDT_ISSUES_TYPE, LETTER_LIST } = localEnum;

  const handleCreate = () => {
    formModal.current?.openModal();
  };

  const onDelete = (item: any) => {
    deletePatientIssue({
      id: item.id,
    }).then(() => {
      message.success(t('删除成功'));
      setReload(true);
    });
  };

  const handleSubmit = (formValue: any) => {
    const newFormValue = JSON.parse(JSON.stringify(formValue));

    newFormValue.type = !newFormValue.type ? MDT_ISSUES_TYPE.VOTING : newFormValue.type;
    if (newFormValue.type === MDT_ISSUES_TYPE.VOTING) {
      if (!newFormValue?.options || newFormValue?.options.length < 2) {
        message.error('最少需要2个选项');
        return Promise.reject();
      }
      const empty = newFormValue?.options?.filter((el: string) => !el);
      if (empty.length) {
        message.error('问题选项不能为空');
        return Promise.reject();
      }
    }
    return addPatientIssue({
      ...newFormValue,
      patientId,
    }).then(() => {
      setReload(true);
    });
  };

  useEffect(() => {
    if (consultationId) {
      setIssue(patientMdtIssues);
    } else {
      setIssue(issueList);
    }
  }, [consultationId, patientMdtIssues, issueList]);

  useEffect(() => {
    if (issueVoteStatistics && issueVoteStatistics?.length > 0) {
      const tempBefore = {} as any;
      const tempAfter = {} as any;
      issueVoteStatistics.forEach((item: any) => {
        if (item.timing === 'before') {
          tempBefore[item.issueId] = {
            percentageResult: item.percentageResult || [],
            peopleResult: item.peopleResult || [],
          };
        } else {
          tempAfter[item.issueId] = {
            percentageResult: item.percentageResult || [],
            peopleResult: item.peopleResult || [],
          };
        }
      });
      if (Object.keys(tempBefore).length) {
        setBeforeSeriesDataMap(tempBefore);
      }
      if (Object.keys(tempAfter).length) {
        setAfterSeriesDataMap(tempAfter);
      }
    }
  }, [issueVoteStatistics]);

  return (
    <SpeechCard
      id="baseInfo"
      title="MDT议题"
      label={
        edit && (
          <a onClick={handleCreate}>
            <PlusOutlined /> {t('新增议题')}
          </a>
        )
      }
      style={{ marginBottom: 16 }}
      onClick={handleCreate}
    >
      <div className={styles['speech-issue']}>
        <div className={styles['issue-container']}>
          {issue?.map((el: any, index: number) => (
            <div key={el.id} className={styles['issue-border']}>
              <div className={styles['issue-item']}>
                <div className={styles['item-header']}>
                  <div className={styles['header-icon']}>
                    {t('议题')}
                    {index + 1}
                  </div>
                  {edit && (
                    <Button type="link" danger onClick={() => onDelete(el)}>
                      {t('删除')}
                    </Button>
                  )}
                </div>
                {edit ? (
                  <IssueForm
                    initialValues={el}
                    type={el.type}
                    edit={edit}
                    name={`issue_${el.id}`}
                    layout={edit ? 'inline' : 'horizontal'}
                    onUpdate={() => setReload(true)}
                  />
                ) : (
                  <>
                    <div style={{ marginLeft: edit ? 0 : 16 }} className={styles.container}>
                      {el.issue}
                    </div>

                    {el.type === MDT_ISSUES_TYPE.VOTING && el.options && (
                      <div style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: 12 }}>
                        {el.options.map((opt: string, inx: number) => {
                          const key = `${opt}-${inx.toString()}`;
                          return (
                            <span key={key} style={{ marginRight: '16px' }}>
                              {LETTER_LIST[inx]}.{opt}
                            </span>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>
              {pushedIssueVote === true && el.type === MDT_ISSUES_TYPE.VOTING && (
                <div className={styles['issue-chart']}>
                  {beforeSeriesDataMap[el.id] && (
                    <IssueBarEchart
                      style={{ marginRight: 12 }}
                      title="MDT前"
                      seriesData={beforeSeriesDataMap[el.id]}
                    />
                  )}
                  {afterSeriesDataMap[el.id] && (
                    <IssueBarEchart title="MDT后" seriesData={afterSeriesDataMap[el.id]} />
                  )}
                </div>
              )}
            </div>
          ))}
          {!issue.length && <div style={{ padding: '16px 0 16px 0' }}>{t('暂无数据')}</div>}
        </div>
        <BaseFormModal
          title={t('新增MDT议题')}
          formModal={formModal}
          onSubmit={handleSubmit}
          FormContent={AddForm}
          width={600}
        />
      </div>
    </SpeechCard>
  );
};

export default SpeechIssue;
