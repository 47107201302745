/*
 * @Description: 讲稿预览文件处理
 * @Author: likaifeng
 * @Date: 2023-12-03 18:22:40
 * @LastEditTime: 2024-09-20 11:02:27
 * @LastEditors: 阿峰
 */
import useFetchImage from '@/pages/speechLecture/hook/fileDeal';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Image } from '@sinohealth/butterfly-ui-components';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import SpeechVideoModel from '../SpeechVideoModel';
import styles from './index.less';

/**
 * @description: 讲稿预览文件处理
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-03 18:27:18
 */
const SpeechFile: React.FC<any> = (props) => {
  const { url, type, className, request = true, preview, id, onClick, onDelete, ...other } = props;

  const [videoUrl, setVideoUrl] = useState('');

  const { intactFileUlr, setOriginUrl } = useFetchImage(url, request);

  /**
   * @description: 预览视频
   * @author: likaifeng
   * @param {string} url
   * @return {*}
   * @Date: 2023-12-03 17:54:44
   */
  const handlePreviewVideo = (u: string) => {
    setVideoUrl(u);
  };

  useEffect(() => {
    if (!request) return;
    setOriginUrl(url);
  }, [url, request]);

  if (!intactFileUlr && request) return null;

  return type === 'image' ? (
    <div className={classNames(className, onDelete && styles['speech-file'])} id={id}>
      {onDelete && <CloseCircleOutlined className={styles['close-icon']} onClick={onDelete} />}
      <Image src={request ? intactFileUlr : url} onClick={onClick} preview={preview} {...other} />
    </div>
  ) : (
    <>
      <video
        muted
        loop
        autoPlay
        id={id}
        className={className}
        {...other}
        onClick={() => handlePreviewVideo(request ? intactFileUlr : url)}
      >
        <source src={request ? intactFileUlr : url} />
      </video>
      {/* 预览video */}
      <SpeechVideoModel
        url={videoUrl}
        urlType="VIDEO"
        title="查看报告视频"
        onClose={() => setVideoUrl('')}
      />
    </>
  );
};

export default SpeechFile;
