import DataBase from '@/pages/dataBase';
import MdtStatistics from '@/pages/mdtStatistics';
import Lecture from '@/pages/speechLecture/lecture';
import Preview from '@/pages/speechLecture/preview';
import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Home from '../pages/home';
import Login from '../pages/user/login';
import UserProtocol from '../pages/user/login/userProtocol';
import NoFind from '../pages/user/noFind';
import PersonalCenter from '../pages/user/personalCenter';

// 懒加载只能针对挂载在Home组件下的组件，因为Suspense组件放在Home中
const Index = lazy(() => import('../pages/index'));
const PersonalUserInfo = lazy(() => import('../pages/personal/usercenter'));
const IndexPatientDetail = lazy(() => import('../pages/index/patientDetail'));
const PatientList = lazy(() => import('../pages/patient/list'));
const PatientDetail = lazy(() => import('../pages/patient/detail'));
const PatientSummary = lazy(() => import('../pages/patient/patientSummary'));
const PatientOcrImage = lazy(() => import('../pages/patient/patientOcrImage'));
const PatientInfoDetail = lazy(() => import('../pages/patient/patientInfoDetail'));
/* ----- MDT团队 ------- */
const MdtTeam = lazy(() => import('../pages/mdtTeam'));
const MdtDetail = lazy(() => import('../pages/mdtTeam/mdtDetail'));
/* ----- 会诊中心 ------- */
const ConsultationCenter = lazy(() => import('../pages/consultationCenter'));
const ConsultationDetail = lazy(() => import('../pages/consultationCenter/detail'));
const ConsultationCreate = lazy(() => import('../pages/consultationCenter/create'));
const MeetingMinutesEdit = lazy(() => import('../pages/consultationCenter/editMeetingMinutes'));
const IssueVoteDetail = lazy(() => import('../pages/consultationCenter/issueVoteDetail'));
/* ----- 病例库 ------- */
const CaseHistory = lazy(() => import('../pages/caseHistory'));
const ShareCaseHistory = lazy(() => import('../pages/caseHistory/share'));
const CombinedCaseHistory = lazy(() => import('../pages/caseHistory/case'));
const ConsultatDetail = lazy(() => import('../pages/caseHistory/consultatDetail'));
/* ----- 会诊讲稿 ------- */
const SpeechLecture = lazy(() => import('../pages/speechLecture'));
const Making = lazy(() => import('../pages/speechLecture/making'));
/* ----- 合同 ------- */
const ContractVerifica = lazy(() => import('../pages/contract/verification'));
const ContractTemplate = lazy(() => import('../pages/contract/template'));
const ContractPreview = lazy(() => import('../pages/contract/preview'));
// const FieldsConfig = lazy(() => import('../pages/contract/fieldsConfig'));
// const FieldsFormEditor = lazy(() => import('../pages/contract/fieldsFormEditor'));

// 系统管理
const AccountNumber = lazy(() => import('../pages/sysAdmin/accountNumber'));
const RoleAdmin = lazy(() => import('../pages/sysAdmin/roleAdmin'));
const MenuAdmin = lazy(() => import('../pages/sysAdmin/menuAdmin'));
const GroupAdmin = lazy(() => import('../pages/sysAdmin/groupAdmin'));
const DiseaseTypeAdmin = lazy(() => import('../pages/sysAdmin/diseaseTypeAdmin'));
// 主数据管理
const LabelAdmin = lazy(() => import('../pages/masterDataManagement/labelAdmin'));
const RegionAdmin = lazy(() => import('../pages/masterDataManagement/regionAdmin'));
const HospitalAdmin = lazy(() => import('../pages/masterDataManagement/hospitalAdmin'));
const DepartmentAdmin = lazy(() => import('../pages/masterDataManagement/departmentAdmin'));
const JobTitleAdmin = lazy(() => import('../pages/masterDataManagement/jobTitleAdmin'));
const BIConfiguration = lazy(() => import('../pages/bIModule/bIConfiguration'));
// 申请管理
const ConsultationApply = lazy(() => import('../pages/applyAdmin/consultationApply'));
const CooperationApply = lazy(() => import('../pages/applyAdmin/cooperationApply'));
// 机构管理
const OrganizationalList = lazy(
  () => import('../pages/organizationalManagement/organizational/list'),
);
const OrganizationalEdit = lazy(
  () => import('../pages/organizationalManagement/organizational/edit'),
);
const ProjectList = lazy(() => import('../pages/organizationalManagement/project/list'));
const OrganizationalProjectList = lazy(
  () => import('../pages/organizationalManagement/project/organizationalProjectList'),
);
const ProjectEdit = lazy(() => import('../pages/organizationalManagement/project/edit'));

// 医生管理
const DoctorList = lazy(() => import('../pages/doctor/list'));
const DoctorDetail = lazy(() => import('../pages/doctor/detail'));
const DoctorInfoList = lazy(() => import('../pages/doctor/infoList'));
const DoctorInfoDetail = lazy(() => import('../pages/doctor/infoDetail'));
const DoctorEdit = lazy(() => import('../pages/doctor/edit'));

// 医助管理
const DoctorAssistantList = lazy(() => import('../pages/doctorAssistant/list'));
const DoctorAssistantDetail = lazy(() => import('../pages/doctorAssistant/detail'));

// 公众号管理
const OfficialList = lazy(() => import('../pages/officialAccount/officialList'));
const AllianceDetails = lazy(() => import('../pages/officialAccount/allianceDetails'));
const EditorialAlliance = lazy(() => import('../pages/officialAccount/editorialAlliance'));
const CreateTeam = lazy(() => import('../pages/officialAccount/createTeam'));
const CreateActivity = lazy(() => import('../pages/officialAccount/createActivity'));

// MDT评分
const Scorelist = lazy(() => import('../pages/score/scorelist'));
const ScoreDetails = lazy(() => import('../pages/score/scoreDetails'));
// 订单管理
const OrderList = lazy(() => import('../pages/orderAdmin/orderLIst'));
const OrderDetails = lazy(() => import('../pages/orderAdmin/orderDetails'));
// 交易流水
const TransactionList = lazy(() => import('../pages/transactionFlow/transactionList'));

/** 会诊招募 **/
// 招募管理页
const RecruitmentManage = lazy(() => import('../pages/consultationRecruitment/recruitmentManage'));
const RecruitmentManageDetail = lazy(
  () => import('../pages/consultationRecruitment/recruitmentManageDetail'),
);
const SignUp = lazy(() => import('../pages/consultationRecruitment/signUp'));
const Recruitment = lazy(() => import('../pages/consultationRecruitment/recruitment'));
const RecruitmentDetails = lazy(
  () => import('../pages/consultationRecruitment/recruitment/detail'),
);

// 表单库
const FormilyList = lazy(() => import('../pages/formily/list'));
const FormilyEditor = lazy(() => import('../pages/formily/editor'));

// 随访管理
const FollowList = lazy(() => import('../pages/followUp/list'));
const FollowDetail = lazy(() => import('../pages/followUp/detail'));

const QualityControlScore = lazy(() => import('../pages/qualityControlScore/list'));
const QualityControlScoreDetail = lazy(() => import('../pages/qualityControlScore/detail'));

const SettledApplyList = lazy(() => import('../pages/settledApply/list'));

// 活动管理
const ActivityManage = lazy(() => import('../pages/activity/activityManage'));
const ActivityEdit = lazy(() => import('../pages/activity/activityEdit'));
const ActivityUserManage = lazy(() => import('../pages/activity/activityUserManage'));
const ActivityUserDetail = lazy(() => import('../pages/activity/activityUserDetail'));
const ActivitySubjectManage = lazy(() => import('../pages/activity/activitySubjectManage'));
const ActivityStatistics = lazy(() => import('../pages/activity/activityStatistics'));
const ActivityStatisticsDetail = lazy(() => import('../pages/activity/activityStatisticsDetail'));
const ActivityOperationalAnalysis = lazy(
  () => import('../pages/activity/activityOperationalAnalysis'),
);

/************ 联合病房-相关路由配置 ************/
const CombinedWardMdt = lazy(() => import('../pages/combinedWard/mdt'));
const CombinedWardMdtDetail = lazy(() => import('../pages/combinedWard/mdtDetail'));
const CombinedWardImChat = lazy(() => import('../pages/combinedWard/imChat'));
const CombinedWardMdtConsult = lazy(() => import('../pages/combinedWard/mdtConsult'));
const CombinedWardMdtConsultCreate = lazy(() => import('../pages/combinedWard/mdtConsultCreate'));
const CombinedWardMdtConsultDetail = lazy(() => import('../pages/combinedWard/mdtConsultDetail'));
const CombinedWardScoreResults = lazy(() => import('../pages/combinedWard/scoreResults'));
const CombinedWardScoreDetail = lazy(() => import('../pages/combinedWard/qualityScoreDetail'));
const CombinedWardTimelineLecture = lazy(() => import('../pages/combinedWard/timelineLecture'));
const CombinedWardPatientCaseTag = lazy(() => import('../pages/combinedWard/patientCaseTag'));
const CombinedWardCaseRecordsComments = lazy(
  () => import('../pages/combinedWard/caseRecordsComments'),
);

export type routerConfigItem = {
  path: string;
  name?: string;
  code?: string;
  element?: React.ReactNode;
  hideInMenu?: boolean;
  children?: routerConfigItem[];
  meta?: any;
};

export const baseRouterConfig = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/login/user-protocol',
    element: <UserProtocol />,
  },
  {
    path: '*',
    element: <NoFind />,
  },
];
const routerConfig: routerConfigItem[] = [
  {
    path: '/',
    element: <Home />,
    children: [
      {
        path: '/',
        element: <Navigate to="/index" replace />,
      },
      {
        path: '/index',
        name: '我的首页',
        element: <Index />,
      },
      {
        path: '/index/patient-detail',
        name: '完善患者病例',
        element: <IndexPatientDetail />,
        code: 'IndexPatientDetail',
      },
      {
        path: '/personal/userinfo',
        name: '个人中心',
        element: <PersonalUserInfo />,
      },
      {
        path: '/personal-center/index',
        name: '个人中心',
        element: <PersonalCenter />,
        code: 'PersonalCenter',
      },
      {
        path: '/patient/list',
        name: '患者列表',
        element: <PatientList />,
        code: 'PatientManageList',
      },
      {
        path: '/patient/detail',
        name: '患者详情',
        element: <PatientDetail />,
      },
      {
        path: '/patient/detail/patientCaseTag',
        name: '编辑标签',
        element: <CombinedWardPatientCaseTag />,
      },
      {
        path: '/patient/detail/patientSummary',
        name: '编辑病史摘要',
        element: <PatientSummary />,
      },
      {
        path: '/patient/detail/patientInfoDetail',
        name: '更多基本信息',
        element: <PatientInfoDetail disabled />,
      },
      {
        path: '/patient/detail/patientInfoEdit',
        name: '编辑基本信息',
        element: <PatientInfoDetail />,
      },
      {
        path: '/patient/detail/patientOcr',
        name: '上传病历智能识别',
        element: <PatientOcrImage />,
      },
      // mdt团队
      {
        path: '/mdt-team/index',
        name: 'MDT团队',
        element: <MdtTeam />,
        code: 'MdtTeam',
      },
      {
        path: '/mdt-team/detail',
        name: '团队详情',
        element: <MdtDetail />,
        code: 'MdtDetail',
      },
      // 会诊中心
      {
        path: '/consultation-center/index',
        name: '会诊中心',
        element: <ConsultationCenter />,
        code: 'ConsultationCenterList',
      },
      {
        path: '/consultation-center/detail',
        name: '会诊详情',
        element: <ConsultationDetail />,
        code: 'ConsultationDetail',
      },
      {
        path: '/consultation-center/create',
        name: '创建会诊',
        element: <ConsultationCreate />,
        code: 'ConsultationCreate',
      },
      {
        path: '/consultation-center/editMeetingMinutes',
        name: '编辑MDT会议纪要',
        element: <MeetingMinutesEdit />,
        code: 'MeetingMinutesEdit',
      },
      {
        path: '/consultation-center/issueVoteDetail',
        name: '议题投票详情',
        element: <IssueVoteDetail />,
        code: 'IssueVoteDetail',
      },

      /* ----- 病例库 ------- */
      {
        path: '/case-history/index',
        name: '病例列表',
        element: <CaseHistory />,
        code: 'CaseHistoryList',
      },
      {
        path: '/case-history/case',
        name: '项目病例库',
        element: <CombinedCaseHistory />,
        code: 'CombinedCaseBank',
      },
      {
        path: '/case-history/share',
        name: '共享病例库',
        element: <ShareCaseHistory />,
        code: 'ShareCaseHistoryList',
      },
      {
        path: '/case-history/consultat-detail',
        name: '会诊详情',
        element: <ConsultatDetail />,
        code: 'ShareCaseConsultatDetail',
      },
      /* ----- 会诊讲稿 ------- */
      {
        path: '/speech-lecture/index',
        name: '会诊讲稿',
        element: <SpeechLecture />,
        code: 'SpeechLectureList',
      },
      {
        path: '/speech-lecture/making',
        name: '制作讲稿',
        element: <Making />,
        code: 'SpeechMakingList',
      },
      /* ----- 合同 ------- */
      {
        path: '/contract/verification',
        name: '合同审核',
        element: <ContractVerifica />,
        code: 'ContractVerificaList',
      },
      {
        path: '/contract/template',
        name: '合同模板',
        element: <ContractTemplate />,
        code: 'ContractTemplateList',
      },
      {
        path: '/contract/preview',
        name: '合同模板',
        element: <ContractPreview />,
        code: 'ContractPreview',
      },
      // {
      //   path: '/contract/fields',
      //   name: '合同字段表',
      //   element: <FieldsConfig />,
      //   code: 'FieldsConfig',
      // },
      // {
      //   path: '/contract/fieldsForm',
      //   name: '编辑字段',
      //   element: <FieldsFormEditor />,
      // },
      {
        path: '/sys/accountNumber',
        name: '账号管理',
        element: <AccountNumber />,
        code: 'AccountNumber',
      },
      {
        path: '/sys/roleAdmin',
        name: '角色管理',
        element: <RoleAdmin />,
        code: 'RoleAdmin',
      },
      {
        path: '/sys/menuAdmin',
        name: '菜单管理',
        element: <MenuAdmin />,
        code: 'MenuAdmin',
      },
      {
        path: '/sys/groupAdmin',
        name: '权限组管理',
        element: <GroupAdmin />,
        code: 'GroupAdmin',
      },
      {
        path: '/sys/diseaseTypeAdmin',
        name: '病种管理',
        element: <DiseaseTypeAdmin />,
        code: 'DiseaseTypeAdmin',
      },
      {
        path: '/masterDataManagement',
        name: '主数据管理',
        code: 'MasterDataManagement',
      },
      {
        path: '/masterDataManagement/labelAdmin',
        name: '系统标签管理',
        element: <LabelAdmin />,
        code: 'LabelAdmin',
      },
      {
        path: '/masterDataManagement/regionAdmin',
        name: '区域管理',
        element: <RegionAdmin />,
        code: 'RegionAdmin',
      },
      {
        path: '/masterDataManagement/hospitalAdmin',
        name: '医院管理',
        element: <HospitalAdmin />,
        code: 'HospitalAdmin',
      },
      {
        path: '/masterDataManagement/departmentAdmin',
        name: '科室管理',
        element: <DepartmentAdmin />,
        code: 'DepartmentAdmin',
      },
      {
        path: '/masterDataManagement/jobTitleAdmin',
        name: '职称管理',
        element: <JobTitleAdmin />,
        code: 'JobTitleAdmin',
      },

      {
        path: '/bIModule/bIConfiguration',
        name: 'BI配置',
        element: <BIConfiguration />,
        code: 'BIConfiguration',
      },

      {
        path: '/applyAdmin/consultationApply',
        name: '会诊申请',
        element: <ConsultationApply />,
        code: 'ConsultationApply',
      },
      {
        path: '/applyAdmin/cooperationApply',
        name: '合作申请',
        element: <CooperationApply />,
        code: 'CooperationApply',
      },
      // {
      //   path: '/organizationalManagement/institutionAdmin',
      //   name: '机构管理',
      //   element: <InstitutionAdmin />,
      //   code: 'InstitutionAdmin',
      // },
      // {
      //   path: '/organizationalManagement/institutionalDetails',
      //   name: '机构详情',
      //   element: <InstitutionalDetails />,
      //   code: 'InstitutionalDetails',
      // },
      {
        path: '/organizational',
        name: '机构信息',
        element: <OrganizationalList />,
        code: 'OrganizationalList',
      },
      {
        path: '/organizational/edit',
        name: '机构详情',
        element: <OrganizationalEdit />,
        // code: 'OrganizationalEdit',
      },
      {
        path: '/project',
        name: '项目信息',
        element: <ProjectList />,
        code: 'ProjectList',
      },
      {
        path: '/organizational/project',
        name: '项目信息',
        element: <OrganizationalProjectList />,
        code: 'OrgProjectList',
      },
      {
        path: '/project/edit',
        name: '项目详情',
        element: <ProjectEdit />,
        // code: 'OrganizationalEdit',
      },

      {
        path: '/officialAccount/officialList',
        name: '公众号网站管理',
        element: <OfficialList />,
        code: 'OfficialList',
      },
      {
        path: '/doctor/list',
        name: '医生管理',
        element: <DoctorList />,
        code: 'DoctorList',
      },
      {
        path: '/doctor/edit',
        name: '医生编辑',
        element: <DoctorEdit />,
      },
      {
        path: '/officialAccount/allianceDetails',
        name: '联盟详情',
        element: <AllianceDetails />,
        code: 'AllianceDetailsNew',
        hideInMenu: true,
      },
      {
        path: '/officialAccount/editorialAlliance',
        name: '编辑联盟',
        element: <EditorialAlliance />,
        code: 'EditorialAlliance',
        hideInMenu: true,
      },
      {
        path: '/officialAccount/createTeam',
        name: '编辑团队',
        element: <CreateTeam />,
        code: 'CreateTeam',
        hideInMenu: true,
      },
      {
        path: '/officialAccount/createActivity',
        name: '编辑活动',
        element: <CreateActivity />,
        code: 'CreateActivity',
        hideInMenu: true,
      },
      {
        path: '/doctor/detail',
        name: '医生详情',
        element: <DoctorDetail />,
      },
      {
        path: '/doctor/info/list',
        name: '医生信息库',
        element: <DoctorInfoList />,
        code: 'DoctorInfoList',
      },
      {
        path: '/doctor/info/detail',
        name: '医生信息库-详情',
        element: <DoctorInfoDetail />,
        code: 'DoctorInfoList',
      },
      {
        path: '/doctor/assistant/list',
        name: '医助管理',
        element: <DoctorAssistantList />,
        code: 'DoctorAssistantList',
      },
      {
        path: '/doctor/assistant/detail',
        name: '医助管理-详情',
        element: <DoctorAssistantDetail />,
      },
      {
        path: '/scoreModule/scorelist',
        name: 'MDT评分',
        element: <Scorelist />,
      },
      {
        path: '/scoreModule/scoreDetails',
        name: '评分详情',
        element: <ScoreDetails />,
      },

      {
        path: '/orderAdmin/orderList',
        name: '订单管理',
        element: <OrderList />,
      },
      {
        path: '/orderAdmin/orderDetails',
        name: '订单详情',
        element: <OrderDetails />,
      },
      {
        path: '/transactionFlow/transactionList',
        name: '交易流水',
        element: <TransactionList />,
      },
      {
        path: '/consultationRecruitment/recruitmentManage',
        name: '招募管理',
        element: <RecruitmentManage />,
        code: 'RecruitmentManage',
      },
      {
        path: '/consultationRecruitment/recruitmentManageDetail',
        name: '招募详情',
        element: <RecruitmentManageDetail />,
      },
      {
        path: '/consultationRecruitment/signUp',
        name: '报名管理',
        element: <SignUp />,
        code: 'RecruitmentRegister',
      },
      {
        path: '/consultationRecruitment/recruitment',
        name: '招募列表',
        element: <Recruitment />,
        code: 'RecruitmentDoctor',
      },
      {
        path: '/consultationRecruitment/recruitmentDetails',
        name: '会诊招募详情',
        element: <RecruitmentDetails />,
      },
      {
        path: '/formily/list',
        name: '表单库',
        element: <FormilyList />,
        code: 'FormilyList',
      },
      {
        path: '/formily/editor',
        name: '编辑字段',
        element: <FormilyEditor />,
      },
      {
        path: '/followUp/list',
        name: '随访管理',
        element: <FollowList />,
        code: 'FollowUpList',
      },
      {
        path: '/followUp/detail',
        name: '随访详情',
        element: <FollowDetail />,
      },
      {
        path: '/qualityControlScore/list',
        name: '质控评分管理',
        element: <QualityControlScore />,
      },
      {
        path: '/qualityControlScore/detail',
        name: '评分表详情',
        element: <QualityControlScoreDetail />,
      },
      {
        path: '/settledApply/list',
        name: '入驻申请',
        element: <SettledApplyList />,
        code: 'SettledApplyList',
      },
      {
        path: '/activity/list',
        name: '活动管理',
        element: <ActivityManage />,
        code: 'ActivityManage',
      },
      {
        path: '/activity/add',
        name: '新增活动',
        element: <ActivityEdit />,
      },
      {
        path: '/activity/edit',
        name: '编辑活动',
        element: <ActivityEdit />,
      },
      {
        path: '/activity/user',
        name: '活动用户管理',
        element: <ActivityUserManage />,
        code: 'ActivityUserManage',
      },
      {
        path: '/activity/user/detail',
        name: '活动用户管理',
        element: <ActivityUserDetail />,
        code: 'ActivityUserDetail',
      },
      {
        path: '/activity/subject',
        name: '活动专题管理',
        element: <ActivitySubjectManage />,
        code: 'ActivitySubjectManag',
      },
      {
        path: '/activity/statistics',
        name: '活动数据列表',
        element: <ActivityStatistics />,
        code: 'AcStatisticManage',
      },
      {
        path: '/activity/statistics/detail',
        name: '活动数据详情',
        element: <ActivityStatisticsDetail />,
      },
      {
        path: '/activity/operational',
        name: '活动运营分析',
        element: <ActivityOperationalAnalysis />,
        code: 'AcOperatorManage',
      },
      /************ 联合病房-相关路由配置 ************/
      {
        path: '/combinedWard/mdt',
        name: '联合病房MDT团队',
        element: <CombinedWardMdt />,
        code: 'CombinedWardMdt',
      },
      {
        path: '/combinedWard/mdtDetail',
        name: '联合病房MDT团队详情',
        element: <CombinedWardMdtDetail />,
        code: 'CombinedWardMdtDetail',
      },
      {
        path: '/combinedWard/mdtConsult',
        name: '团队会诊中心',
        element: <CombinedWardMdtConsult />,
        code: 'CombinedWardMdtConsu',
      },
      {
        path: '/combinedWard/mdtConsultCreate',
        name: '创建团队会诊',
        element: <CombinedWardMdtConsultCreate />,
        code: 'CombinedWardMdtConsultCreate',
      },
      {
        path: '/combinedWard/mdtConsultDetail',
        name: '团队会诊详情',
        element: <CombinedWardMdtConsultDetail />,
        code: 'CombinedWardMdtConsultDetail',
      },
      {
        path: '/combinedWard/scoreResults',
        name: '确认评分结果',
        element: <CombinedWardScoreResults />,
        code: 'CombinedWardScoreResults',
      },
      {
        path: '/combinedWard/qualityScoreDetail',
        name: '质控参考评分详情',
        element: <CombinedWardScoreDetail />,
        code: 'CombinedWardScoreDetail',
      },
      {
        path: '/combinedWard/timelineLecture',
        name: '编辑病例时间轴',
        element: <CombinedWardTimelineLecture />,
        code: 'CombinedWardTimelineLecture',
      },
      {
        path: '/combinedWard/caseRecordsComments',
        name: '病例更新及点评记录',
        element: <CombinedWardCaseRecordsComments />,
        code: 'CaseRecordsComments',
      },
    ],
  },
  {
    path: '/speech-lecture/preview',
    name: '讲稿预览',
    element: <Preview />,
    code: 'SpeechMakingList',
    hideInMenu: true,
  },
  {
    path: '/combinedWard/imChat',
    name: '图文会诊',
    element: <CombinedWardImChat />,
    code: 'CombinedWardImChat',
    hideInMenu: true,
  },
  {
    path: '/speech-lecture/lecture',
    name: '讲稿预览',
    element: <Lecture />,
    code: 'SpeechMakingList',
    hideInMenu: true,
  },
  {
    path: '/dataBase',
    name: '数据统计',
    element: <DataBase />,
    code: 'DataBase',
    hideInMenu: true,
  },
  {
    path: '/mdt-statistics',
    name: 'MDT数据统计',
    element: <MdtStatistics />,
    code: 'MdtStatistics',
    hideInMenu: true,
  },
  ...baseRouterConfig,
];

export default routerConfig;
