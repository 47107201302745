import useCurrentLoginUserInfo from '@/hooks/useCurrentLoginUserInfo';
import { useMenuConfig } from '@/hooks/useMenu';
import usePermission from '@/hooks/usePermission';
import { useLocales } from '@/Locales';
import Navbar from '@/pages/home/components/Navbar';
import UserInfo from '@/pages/home/components/UserInfo';
import {
  CaretDownOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu, Space, Spin } from '@sinohealth/butterfly-ui-components';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Team from './components/Team';
import styles from './index.less';

const logoImg = require('@/assets/images/common/logo.png');

const { Header, Sider, Content } = Layout;

const Home: React.FC = () => {
  const [userInfo] = useCurrentLoginUserInfo();
  const location = useLocation(); // 获取当前路由
  const pathname = location.pathname;
  const [collapsed, setCollapsed] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const navigate = useNavigate();
  const { menuData, defaultOpenKeys, defaultSelectedKeys } = useMenuConfig();
  const permission = usePermission('commonLayout');
  const { locales, changeLocales, t } = useLocales();

  const fullPage = () => {
    setFullscreen(!fullscreen);
    if (fullscreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  const handleMenuClick = (e: any) => {
    navigate(e.key);
  };

  useEffect(() => {
    if (userInfo) {
      const roleCode = userInfo?.roleCode || '';
      const closeUrl = ['/index', '/index/patient-detail'];
      if (roleCode === 'doctor' && closeUrl.includes(pathname)) {
        // console.log('Current pathname:', pathname);
        // console.log(roleCode);
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    }
  }, [userInfo, pathname]);

  if (!userInfo) {
    return (
      <div style={{ display: 'flex', height: '100vh' }}>
        <Spin size="large" style={{ margin: 'auto' }} />
      </div>
    );
  }

  const localesItems = [
    {
      key: 'zh-cn',
      label: '中文',
      onClick: () => changeLocales('zh-cn'),
    },
    {
      key: 'en',
      label: 'English',
      onClick: () => changeLocales('en'),
    },
  ];
  // @ts-ignore
  return (
    <Layout className={styles.layout}>
      <Sider
        className={styles.sider}
        theme="light"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className={styles.logo}>
          <div style={{ width: !collapsed ? '200px' : 'auto' }}>
            <img
              src={logoImg}
              style={{ height: !collapsed ? '21px' : '14px', marginTop: !collapsed ? '0' : '7px' }}
              alt=""
            />
            {!collapsed && (
              <div
                className={styles.name}
                style={{ fontSize: locales === 'zh-cn' ? '20px' : '16px' }}
              >
                {t('管理平台')}
              </div>
            )}
          </div>
        </div>
        <Menu
          onClick={handleMenuClick}
          key={defaultSelectedKeys[0] + defaultOpenKeys[0]}
          mode="inline"
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={defaultOpenKeys}
          items={menuData}
        />
      </Sider>
      <Layout>
        <Header className={styles.header}>
          <div className={styles.pageInfo}>
            <Button
              className={styles.collapsedBtn}
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
            />
            <Navbar />
          </div>
          <div className={styles.toolbar}>
            <Space>
              {/* <a onClick={changeLocales}> */}
              {/*  {locales === 'zh-cn' ? '中文' : 'English'} */}
              {/* </a> */}
              <Dropdown menu={{ items: localesItems }}>
                <span style={{ cursor: 'pointer' }}>
                  {t('语言')}&nbsp;
                  <CaretDownOutlined
                    style={{ color: 'rgba(11, 33, 69, 0.65)', pointerEvents: 'none' }}
                  />
                </span>
              </Dropdown>
              <Team />
              <UserInfo />
              <Button
                className={styles.fullBtn}
                type="text"
                icon={!fullscreen ? <FullscreenOutlined /> : <FullscreenExitOutlined />}
                onClick={() => fullPage()}
              />
            </Space>
          </div>
        </Header>
        <Content className={styles.content}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Home;
