/* eslint-disable quote-props */
/*
 * @Description:
 * @Author: likaifeng
 * @Date: 2023-11-21 15:55:07
 * @LastEditTime: 2024-09-26 17:07:00
 * @LastEditors: 阿峰
 */

// 星期
export const weekList = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];

// 24小时时间列表
// 时间间隔
export const twentyFourHalfHours = [
  '8:00-8:30',
  '8:30-9:00',
  '9:00-9:30',
  '9:30-10:00',
  '10:00-10:30',
  '10:30-11:00',
  '11:00-11:30',
  '11:30-12:00',
  '12:00-12:30',
  '12:30-13:00',
  '13:00-13:30',
  '13:30-14:00',
  '14:00-14:30',
  '14:30-15:00',
  '15:00-15:30',
  '15:30-16:00',
  '16:00-16:30',
  '16:30-17:00',
  '17:00-17:30',
  '17:30-18:00',
  '18:00-18:30',
  '18:30-19:00',
  '19:00-19:30',
  '19:30-20:00',
  '20:00-20:30',
  '20:30-21:00',
  '21:00-21:30',
  '21:30-22:00',
  '22:00-22:30',
  '22:30-23:00',
  '23:00-23:30',
  '23:30-24:00',
];

/**
 * @description: 合同模板状态
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 17:21:13
 */
export const verificationStatus: any = {
  '0': '未审核',
  '1': '审核通过',
  '2': '审核不通过',
};

// 24小时
export const hours24 = [
  '8:00',
  '8:30',
  '9:00',
  '9:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
  '24:00',
];

// 会诊状态
export const cstatus = ['创建中', '未开始', '进行中', '已失效', '已完成'];

// 肿瘤分期
export const tumorStagePrefix = ['c', 'p', 'r', 'y'];
export const tumorStageT = [
  'T0',
  'Tis',
  'Tis(DCIS)',
  'Tis(LCIS)',
  "Tis(Paget's)",
  'T1',
  'T1mi',
  'T1a',
  'T1b',
  'T1c',
  'T2',
  'T3',
  'T4',
  'T4a',
  'T4b',
  'T4c',
  'T4d',
  'Tx',
  '不适用',
];
export const tumorStageN = ['Nx', 'N0', 'N1', 'N1a', 'N1b', 'N1c', 'N2', 'N2a', 'N2b', 'N3', 'N+'];
export const tumorStageM = ['Mx', 'M0', 'M1', 'M1a', 'M1b', 'M1c'];

// 性别
export const gender: any = ['男', '女', '保密'];

// 团队被邀约后处理状态
export const tstatus = ['未处理', '同意', '拒绝'];

// 检验项目
export const inspctionType = ['肿瘤标志物', '其他'];

/** 影像类型 */
export const imageType = [
  { label: 1, name: 'CT' },
  { label: 2, name: 'X光' },
  { label: 3, name: 'US' },
  { label: 4, name: 'MRI' },
  { label: 5, name: 'PET-CT' },
  { label: 6, name: '其他' },
];

/** 订单状态颜色 */
export const orderStatusColor: any = {
  created: '#FAAD14', // 已创建
  effictive: '#4288FF', // 已生效
  invalid: '#C2C7D0', // 已失效
  closed: '#00B793', // 已关闭
};

/** 支付状态颜色 */
export const payStatusColor: any = {
  waitPay: '#FAAD14', // 待支付
  refund: '#C2C7D0', // 已退费
  paid: '#00B793', // 已支付
  refundFail: '#E44444', // 退费失败
};
/** 会诊状态颜色 */
export const consultationStatusColor: any = {
  '0': '#4288FF', // 创建中
  '3': '#C2C7D0', // 已失效
  '1': '#FAAD14', // 未开始
  '2': '#E44444', // 进行中
  '4': '#00B793', // 已完成
};

// "会诊状态(创建中 0 已失效 3 未开始 1 进行中 2 已完成 4)"
export const consultationStatus: any = {
  '0': '创建中',
  '3': '已失效',
  '1': '未开始',
  '2': '进行中',
  '4': '已完成',
};

/** 订单状态查询条件集合 */
export const orderStatusSearch: any = [
  {
    value: 'orderStatus',
    label: '订单状态',
    color: 'orderStatusColor',
    children: [
      {
        value: 'created',
        label: '已创建',
      },
      {
        value: 'effictive',
        label: '已生效',
      },
      {
        value: 'invalid',
        label: '已失效',
      },
      {
        value: 'closed',
        label: '已关闭',
      },
    ],
  },
  {
    value: 'payStatus',
    label: '支付状态',
    color: 'payStatusColor',
    children: [
      {
        value: 'waitPay',
        label: '待支付',
      },
      {
        value: 'refund',
        label: '已退费',
      },
      {
        value: 'paid',
        label: '已支付',
      },
      {
        value: 'refundFail',
        label: '退费失败',
      },
    ],
  },
  {
    value: 'consultationStatus',
    label: '会诊状态',
    color: 'consultationStatusColor',
    children: [
      {
        value: '0',
        label: '创建中',
      },
      {
        value: '3',
        label: '已失效',
      },
      {
        value: '1',
        label: '未开始',
      },
      {
        value: '2',
        label: '进行中',
      },
      {
        value: '4',
        label: '已完成',
      },
    ],
  },
];
/** 订单状态 */
export const orderStatus: any = {
  created: '已创建',
  effictive: '已生效',
  invalid: '已失效',
  closed: '已关闭',
};

/** 订单详情状态 */
export const webStatus: any = {
  waitPay: '订单待支付',
  payOverTime: '付款超时，取消订单',
  notPayCreatorCancel: '订单未支付，创建人取消订单',
  teamNotConfirm: '会诊团队超时未确认',
  creatorCancel: '会诊创建人取消订单',
  neddTeamConfirm: '会诊团队待确认',
  consultationNotBegin: '会诊未开始',
  consultationBegin: '会诊进行中',
  consultationCompleted: '会诊已完成',
  refundFail: '退费失败',
};

/** 订单详情状态 */
export const webStatusBgColor: any = {
  waitPay: {
    bgColor: '#e8f0ff',
    textColor: '#4288FF',
  },
  neddTeamConfirm: {
    bgColor: '#e8f0ff',
    textColor: '#4288FF',
  },
  consultationNotBegin: {
    bgColor: '#fef5e2',
    textColor: '#FAAD14',
  },
  payOverTime: {
    bgColor: '#e1e4e8',
    textColor: '#0B2145',
  },
  notPayCreatorCancel: {
    bgColor: '#e1e4e8',
    textColor: '#0B2145',
  },
  teamNotConfirm: {
    bgColor: '#e1e4e8',
    textColor: '#0B2145',
  },
  creatorCancel: {
    bgColor: '#e1e4e8',
    textColor: '#0B2145',
  },
  consultationBegin: {
    bgColor: '#fbe8e8',
    textColor: '#E44444',
  },
  refundFail: {
    bgColor: '#fbe8e8',
    textColor: '#E44444',
  },
  consultationCompleted: {
    bgColor: '#e0f6f2',
    textColor: '#00B793',
  },
};

/** 支付状态 */
export const payStatus: any = {
  waitPay: '待支付',
  refund: '已退费',
  paid: '已支付',
  refundFail: '退费失败',
};

/** 交易类型颜色 */
export const tradeTypeColor: any = {
  pay: '#4288FF', // 收费
  refund: '#E44444', // 退费
};
/** 交易类型 */
export const tradeType: any = {
  pay: '收费',
  refund: '退费',
};

/** 会诊报名 - 审核结果 */
export const AuditStatus: any = {
  not_passed: '不通过',
  passed: '审核通过',
};

/** 病例录入状态 */
export const enterStatuses: any = {
  1: '待上传病例',
  2: '待确认讲稿',
  3: '已确认讲稿',
};

/** 联合病房-会诊方式(video-视频，text-图文) */
export const UnionConsultaType: any = {
  video: '视频会诊',
  text: '图文会诊',
};

/** 联合病房-会诊状态(0创建中 1未开始 2进行中 3已失效4已完成) */
export const UnionConsultaStatus: any = ['', '未开始', '进行中', '已失效', '已完成'];

export const ScoreIndex: any = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];

/** 评分结果 */
export const MatchStatus: any = {
  matched: '满足',
  no_match: '不满足',
  not_provided: '未提供',
};
export const MatchStatusColor: any = {
  matched: '#37B03D',
  no_match: '#FF8D1A',
  not_provided: '#E44444',
};
