import Image from '@/components/Image';
import { baseURL } from '@/config/base';
import { useLocales } from '@/Locales';
import { apiPrefix } from '@/services';
import { getImageFileSize } from '@/utils';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload, UploadProps, message } from '@sinohealth/butterfly-ui-components';
import { useState } from 'react';
import styles from './index.less';

interface CellUploadProps extends UploadProps {
  maxSize?: any;
  minDimension?: any; // 最小图片宽高
  maxDimension?: any; // 最大图片宽高
  onUploadSuccess?: any;
  value?: string;
  noStyle?: boolean;
}
const CellUpload = (props: CellUploadProps) => {
  const {
    onUploadSuccess,
    children,
    value,
    onChange,
    maxSize,
    maxDimension,
    minDimension,
    noStyle,
    ...otherProps
  } = props;
  const [uploading, setUploading] = useState(false);
  const { t } = useLocales();
  const uploadParams: UploadProps = {
    name: 'file',
    showUploadList: false,
    listType: 'picture-card',
    action: `${baseURL + apiPrefix}/cos/upload`,
    disabled: uploading,
    beforeUpload: async (file: any) => {
      if (file.type.indexOf('image') > -1 && (maxDimension || minDimension)) {
        const imgDimension: any = await getImageFileSize(file);
        if (imgDimension.width > maxDimension || imgDimension.height > maxDimension) {
          message.error(`${t('图片宽/高不能大于')}${maxDimension}!`);
          return false;
        }
        if (imgDimension.width < minDimension || imgDimension.height < minDimension) {
          message.error(`${t('图片宽/高不能小于')}${minDimension}!`);
          return false;
        }
      }
      if (maxSize) {
        const isLimit = file.size / 1024 / 1024 < maxSize;
        if (!isLimit) {
          message.error(`${t('上传文件不能大于')}${maxSize}MB!`);
          return false;
        }
      }
      setUploading(true);
      return true;
    },
    onChange(info: any) {
      const { status } = info.file;
      // 所有文件都上传完才修改loading状态
      setUploading(info.fileList.map((item: any) => item.status).includes('uploading'));
      if (status === 'done') {
        try {
          onUploadSuccess ? onUploadSuccess(info) : info.file?.response?.data;
          onChange && onChange(info.file?.response?.data);
        } catch (e) {
          console.error(e);
        }
      }
    },
    ...otherProps,
  };
  return (
    <Upload className={noStyle ? '' : styles.upload} {...uploadParams}>
      {children || (
        <div className={styles.content}>
          {
            // eslint-disable-next-line no-nested-ternary
            uploading ? (
              <>
                <LoadingOutlined style={{ fontSize: 18 }} />
                <div style={{ marginTop: 8 }}>{t('上传中...')}</div>
              </>
            ) : value ? (
              <Image key={value} src={value} className={styles.img} />
            ) : (
              <PlusOutlined />
            )
          }
        </div>
      )}
    </Upload>
  );
};

export default CellUpload;
