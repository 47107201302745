import { useLocales } from '@/Locales';
import {
  httpPatientScoreConfirm,
  httpPatientScoreDetail,
  httpPatientScoreRatingStatus,
  httpPatientScoreStart,
} from '@/services/unionward';
import { message } from '@sinohealth/butterfly-ui-components';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

/**
 * @description: 质控评分 - 确认评分
 * @author: KaifengLi
 * @version: 2.33.0
 * @Date: 2024-09-26 09:41:33
 */
export const useScoreConfirm = () => {
  const [scoreConfirmParams, setScoreConfirmParams] = useState<any>();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useLocales();

  const httpPatientScoreConfirmReq = async () => {
    try {
      setConfirmLoading(true);
      const res = await httpPatientScoreConfirm(scoreConfirmParams);
      message.success(t('确认成功'));
      navigate(`/combinedWard/qualityScoreDetail?id=${scoreConfirmParams?.patientId}`, {
        replace: true,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };

  useEffect(() => {
    scoreConfirmParams && httpPatientScoreConfirmReq();
  }, [scoreConfirmParams]);

  return {
    scoreConfirmParams,
    setScoreConfirmParams,
    confirmLoading,
  };
};

/**
 * @description: 质控评分 - 开始计算评分
 * @author: KaifengLi
 * @version: v2.33.0 not_rated-未评分，rating-评分中，rated-已评分，not_confirmed-未确认
 * @Date: 2024-09-26 09:45:14
 */
export const useScoreStart = () => {
  const { t } = useLocales();
  const [scoreStartParams, setScoreStartParams] = useState<any>();
  const [startSuccess, setStartSuccess] = useState<boolean>(false);

  const httpPatientScoreStartReq = async () => {
    try {
      await httpPatientScoreStart(scoreStartParams);
      setStartSuccess(true);
      message.success(t('开始计算评分成功'));
    } catch (err: any) {
      console.log(err);
      message.error(err?.retMsg);
    } finally {
      setStartSuccess(false);
    }
  };

  useEffect(() => {
    scoreStartParams && httpPatientScoreStartReq();
  }, [scoreStartParams]);

  return {
    scoreStartParams,
    setScoreStartParams,
    startSuccess,
  };
};

/**
 * @description: 质控评分 - 患者病历评分详情
 * @author: KaifengLi
 * @version: v2.33.0
 * @Date: 2024-09-26 09:47:18
 */
export const useScoreDetail = () => {
  const [params] = useSearchParams();
  const id = params.getAll('id')[0];

  const [scoreDetail, setScoreDetail] = useState<any>();
  const [contentJson, setContentJson] = useState<any>();
  const [formData, setFormData] = useState<any>(); // 表单数据
  const [scoreForm, setScoreForm] = useState<any>(); // 表单选中数据

  const httpPatientScoreDetailReq = async () => {
    try {
      const res = await httpPatientScoreDetail({ patientId: id });
      setScoreDetail(res);
      setContentJson(JSON.parse(res?.contentJson));
      if (res.value) {
        const formD = JSON.parse(res?.value);
        Object.keys(formD).forEach((key) => {
          if (key !== 'patientInfo') {
            if (!formD[key].length) {
              formD[key] = [{}];
            }
          }
        });
        setFormData(formD);
      }
      const form: any = {};
      setScoreForm(
        res?.ruleMatchList?.forEach((el: any) => {
          form[el.id] = el?.matchStatus;
        }),
      );
      setScoreForm(form);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    id && httpPatientScoreDetailReq();
  }, [id]);

  return {
    scoreDetail,
    contentJson,
    formData,
    patientId: id,
    scoreForm,
  };
};

/**
 * @description: 质控评分 - 查询患者评分状态
 * @author: KaifengLi
 * @version: v2.33.0
 * @Date: 2024-09-26 09:48:30
 */
export const useScoreRatingStatus = (reload?: boolean) => {
  const [params] = useSearchParams();
  const id = params.getAll('id')[0];
  const [scoreRatingStatusParams, setScoreRatingStatusParams] = useState<any>();
  const [scoreRatingStatus, setScoreRatingStatus] = useState<any>();

  const httpPatientScoreRatingStatusReq = async () => {
    try {
      const res = await httpPatientScoreRatingStatus(
        id ? { patientId: id } : scoreRatingStatusParams,
      );
      setScoreRatingStatus(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // 定时器变量
    let timer: NodeJS.Timeout | null | any = null;

    if (scoreRatingStatus) {
      // no_rating-未评分，rating-评分中，rated-已评分，not_confirmed-未确认
      // 评分中需要轮询接口
      if (scoreRatingStatus?.status === 'rating') {
        timer = setTimeout(() => {
          httpPatientScoreRatingStatusReq();
        }, 3000);
      }
    }

    // 清理定时器
    return () => {
      console.log('Cleaning up timer');
      timer && clearTimeout(timer);
      timer = null; // 确保定时器变量被重置
    };
  }, [scoreRatingStatus]);

  useEffect(() => {
    (id || scoreRatingStatusParams) && httpPatientScoreRatingStatusReq();
  }, [id, scoreRatingStatusParams]);

  useEffect(() => {
    reload && httpPatientScoreRatingStatusReq();
  }, [reload]);

  return {
    id,
    scoreRatingStatusParams,
    setScoreRatingStatusParams,
    scoreRatingStatus,
    setScoreRatingStatus,
  };
};
