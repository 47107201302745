import { useLocales } from '@/Locales';
import { useScoreRatingStatus, useScoreStart } from '@/pages/combinedWard/hook/usePatientScore';
import { OneToOneOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Divider, message, Space, Tooltip } from '@sinohealth/butterfly-ui-components';
import React, { CSSProperties, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styles from './index.less';

interface QualityScoreProps {
  style?: CSSProperties | undefined;
  patientId?: string; // 患者列表页-进来
  onSuccess?: () => void;
}

/**
 * @description: 质控评分 - 查询患者评分状态
 * @author: KaifengLi
 * @version: v2.23.0
 * @Date: 2024-09-26 15:36:02
 */
const QualityScore: React.FC<QualityScoreProps> = (props) => {
  const { t } = useLocales();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  // 是否从MDT详情进来
  const mdtDetailFlag = params.get('mdtDetail') === 'true';
  // 是否为团队管理员/或主治医生/负责人可查看质控参考评分，其他人员（非自己病例的医生）不可查看
  const leaderFlag = params.get('leader') === 'true';
  // not_rated-未评分，rating-评分中，rated-已评分，not_confirmed-未确认
  const { setScoreStartParams, startSuccess } = useScoreStart();
  const { scoreRatingStatus, setScoreRatingStatus, id, setScoreRatingStatusParams } =
    useScoreRatingStatus(!props?.patientId ? startSuccess : false);

  useEffect(() => {
    if (scoreRatingStatus && props?.patientId) {
      if (scoreRatingStatus?.status === 'not_rated') {
        setScoreStartParams({ patientId: props?.patientId });
      } else if (scoreRatingStatus?.status === 'not_confirmed') {
        navigate(`/combinedWard/scoreResults?id=${props?.patientId}`);
      } else {
        message.success(`${t('质控评分正在计算中')}...`);
        props?.onSuccess && props?.onSuccess();
      }
      setScoreRatingStatus(undefined);
    }
  }, [scoreRatingStatus, props?.patientId]);

  useEffect(() => {
    if (startSuccess && props?.patientId) {
      props?.onSuccess && props?.onSuccess();
    }
  }, [props?.patientId, startSuccess]);

  return !props?.patientId ? (
    <div
      className={styles['quality-score']}
      style={{ display: !leaderFlag && mdtDetailFlag ? 'none' : 'flex', ...props?.style }}
    >
      {/* 正常患者列表-患者详情 */}
      {!mdtDetailFlag ? (
        <>
          <Space>
            <span className={styles.title}> {t('质控参考评分')}</span>
            {scoreRatingStatus?.status === 'rated' && (
              <a className={styles.score}>{scoreRatingStatus?.score}</a>
            )}
          </Space>
          <Space split={<Divider type="vertical" />}>
            {scoreRatingStatus?.status === 'not_rated' && (
              <a type="link" onClick={() => setScoreStartParams({ patientId: id })}>
                <i className="iconfont icon-zhongxinjisuanpingfen" /> {t('计算质控评分')}
              </a>
            )}
            {scoreRatingStatus?.status === 'not_confirmed' && (
              <a type="link" onClick={() => navigate(`/combinedWard/scoreResults?id=${id}`)}>
                <i className="iconfont icon-chakanpingfenxiangqing" /> {t('确认评分结果')}
              </a>
            )}
            {scoreRatingStatus?.status === 'rating' && (
              <Button type="link" shape="round" icon={<OneToOneOutlined />} disabled loading>
                {t('质控评分计算中')}...
              </Button>
            )}
            {scoreRatingStatus?.status === 'rated' && (
              <>
                <a
                  type="link"
                  onClick={() => navigate(`/combinedWard/qualityScoreDetail?id=${id}`)}
                >
                  <i className="iconfont icon-chakanpingfenxiangqing" /> {t('查看评分详情')}
                </a>
                <span>
                  <a type="link" onClick={() => navigate(`/combinedWard/scoreResults?id=${id}`)}>
                    <i className="iconfont icon-zhongxinjisuanpingfen" /> {t('重新计算评分')}
                  </a>{' '}
                  <Tooltip
                    placement="right"
                    title={t(
                      '补充更多病例信息后，请点击重新计算评分，获取最新的质控参考评分结果。',
                    )}
                  >
                    <QuestionCircleOutlined style={{ color: '#A8A8A8' }} />
                  </Tooltip>
                </span>
              </>
            )}
          </Space>
        </>
      ) : (
        <Space>
          <span className={styles.title}>{t('质控参考评分')}</span>
          {scoreRatingStatus?.status === 'rated' && leaderFlag ? (
            <Button
              type="link"
              icon={
                <i style={{ marginRight: 4 }} className="iconfont icon-chakanpingfenxiangqing" />
              }
              shape="round"
              onClick={() =>
                navigate(`/combinedWard/qualityScoreDetail?id=${id}&mdtDetail=${mdtDetailFlag}`)
              }
            >
              {t('查看评分详情')}
            </Button>
          ) : (
            <a style={{ fontSize: 16 }}>{t('暂未进行评分')}</a>
          )}
        </Space>
      )}
    </div>
  ) : (
    <a
      onClick={() => {
        setScoreRatingStatusParams({ patientId: props?.patientId });
      }}
    >
      {t('计算质控评分')}
    </a>
  );
};

export default QualityScore;
