/*
 * @Description:
 * @Author: likaifeng
 * @Date: 2023-11-17 15:33:36
 * @LastEditTime: 2023-12-01 16:08:06
 * @LastEditors: likaifeng
 */
import request from '@/common/request';

const apiPrefix = '';

/**
 * @description: 查询医生团队列表分页-成功
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpMdtGetDoctorMdtTeam = (params: any) => {
  return request.post(`${apiPrefix}/mdt/getDoctorMdtTeam`, params);
};

/**
 * @description: 查询团队列表 - 医助
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpMdtTeamByAssistantId = (params: any) => {
  return request.post(`${apiPrefix}/mdt/listPage`, params);
};

/**
 * @description: 查询团队详情
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpMdtTeamDetails = (id?: number | string) => {
  return request.post(`${apiPrefix}/mdt/teamDetails`, { id });
};

/**
 * @description: 更新团队
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpMdtTeamUpdate = (params?: any) => {
  return request.post(`${apiPrefix}/mdt/update`, params);
};

/**
 * @description: 团队列表
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpGetAgencyList = (id?: number | string) => {
  return request.post(`${apiPrefix}/agency/getAgencyList`, { id });
};

/**
 * @description: 医生列表
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpDoctorListPage = (params?: any) => {
  return request.post(`${apiPrefix}/doctor/page`, params);
};

/**
 * @description: 添加mdt团队
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpMdtCreate = (params?: any) => {
  return request.post(`${apiPrefix}/mdt/add`, params);
};

/**
 * @description: 删除mdt团队
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpMdtDelete = (id?: number | string) => {
  return request.post(`${apiPrefix}/mdt/delete`, { id });
};

/**
 * @description: 详情-删除mdt团队成员
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpMdtMemberDelete = (id?: number | string) => {
  return request.post(`${apiPrefix}/mtdMember/delete`, { id });
};

/**
 * @description: 详情-添加mdt团队成员
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpMdtMemberAdd = (params: any) => {
  return request.post(`${apiPrefix}/mtdMember/add`, params);
};

/**
 * @description: 详情-设置管理mdt团队成员
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpMdtMemberUpdate = (params: any) => {
  return request.post(`${apiPrefix}/mtdMember/update`, params);
};
