/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/*
 * @Description: 讲稿演示-主要内容-内容展示组件
 * @Author: likaifeng
 * @Date: 2023-11-30 16:36:37
 * @LastEditTime: 2024-09-20 11:23:53
 * @LastEditors: 阿峰
 */
import ReactViewer from '@/components/ReactViewer';
import { useLocales } from '@/Locales';
import { download } from '@/services';
import className from 'classnames';
import _ from 'lodash';
import { memo, useEffect, useRef, useState } from 'react';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
// @ts-ignore
import Sortable from 'sortablejs';
import SpeechCompareModel from '../SpeechCompareModel';
import SpeechFile from '../SpeechFile';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-内容展示组件
 * @author: likaifeng
 * @param {*} props
 * @return {*}
 * @Date: 2023-12-04 14:26:03
 */
const SpeechField = memo((props: any) => {
  const {
    style,
    border,
    color, // 是否深色
    title,
    compareTitle,
    description,
    imagesSource, // 图片数据 对象数组 [{ type: 'image', url: '' },{ type: 'video', url: '' }]
    imageStyle,
    zhWedcmTask,
    dicomId,
    type = 'TEXT', // TEXT IMAGE BUTTON OTHER COMPARE LINK
    sort, // 排序
    onSort,
    uploadChildren, // 上传组件
    onDelete, // 是否需要删除
  } = props;

  const listDom = useRef<any>();
  const [compare, setCompare] = useState<any[]>([]);
  const [downloadImage, setDownloadImage] = useState<any[]>([]);
  const [viewImages, setViewImages] = useState<ImageDecorator[]>([]);
  const [viewImagesIndex, setViewImagesIndex] = useState(0);
  const { t } = useLocales();

  /**
   * @description: 查看 dicom 影像
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-10-31 14:19:37
   */
  const handleViewDicom = () => {
    if (dicomId !== -1) {
      // const query = `id=${dicomId}`;
      // window.open(`/#//patientManagement/dicom?${query}`);
      window.open(zhWedcmTask.openPath);
    }
  };

  const handleDownloadImage = (img: any[]) => {
    Promise.all(
      img.map((el) => {
        if (el?.previewUrl) {
          return Promise.resolve({
            ...el,
            url: el?.previewUrl,
          });
        }
        return download(el.key).then((url) => {
          return {
            ...el,
            url,
          };
        });
      }),
    ).then((res) => {
      setDownloadImage(res);
      sort && initSortable();
    });
  };

  const initSortable = () => {
    const el: any = listDom.current;
    // console.log(el);
    if (!el) {
      return;
    }
    let isSortIng = false;
    const save = _.debounce(() => {
      if (isSortIng) {
        return;
      }
      const ids = [];
      for (let i = 0; i < el.children.length; i += 1) {
        const item = el.children[i];
        if (item.id) {
          ids.push({
            id: item.id,
            sort: i,
          });
        }
      }
      // console.log({ ids });
      onSort && onSort(ids.map((item: any) => item.id));
    }, 500);
    // eslint-disable-next-line no-new
    new Sortable(el, {
      handle: '.handle',
      filter: '[data-no-drag="true"]', // 禁止 data-no-drag 属性为 "true" 的元素拖拽
      onStart() {
        isSortIng = true;
      },
      onMove(e: any) {
        return e.related.className.indexOf('filtered') === -1;
      },
      onEnd() {
        isSortIng = false;
        save();
      },
    });
  };

  /**
   * @description: 父容器的类
   * @author: likaifeng
   * @return {*}
   * @Date: 2024-01-09 14:32:04
   */
  const faClass = () => {
    return className(
      styles['field-item'],
      border && styles['field-border'],
      (type === 'TEXT' || type === 'LINK') && styles['field-text'],
      type === 'BUTTON' && styles['field-button'],
      type === 'IMAGE' && styles['field-image'],
      type === 'COMPARE' && styles['field-image'],
      type === 'OTHER' && styles['field-image'],
    );
  };

  useEffect(() => {
    if (imagesSource && imagesSource?.length) {
      handleDownloadImage(imagesSource);
    } else {
      setDownloadImage([]);
    }
  }, [imagesSource]);

  return description ||
    title ||
    (downloadImage && downloadImage.length) ||
    uploadChildren ||
    props.children ? (
    <div className={faClass()} style={style}>
      {/* 标题 */}
      {title ? (
        <div className={styles['label-wrapper']}>
          <div className={className(styles['field-label'], color && styles['deep-label'])}>
            {t(title)}
          </div>
        </div>
      ) : null}

      {/* 文本类型内容 */}
      {type === 'TEXT' ? <div className={styles['field-desc']}>{description || '-'}</div> : null}
      {/* 链接类型 */}
      {type === 'LINK' ? (
        <div className={styles['field-desc']}>
          <a href={description} target="_blank" rel="noreferrer">
            {description || '-'}
          </a>
        </div>
      ) : null}
      {/* 按钮类型内容 description 按钮描述 */}
      {type === 'BUTTON' ? (
        <div className={styles['desc-button']} onClick={handleViewDicom}>
          <i className="el-icon-search" />
          {description}
        </div>
      ) : null}

      {/* 图片类型内容 */}
      {type === 'IMAGE' ? (
        <div ref={listDom} className={styles['images-container']}>
          {uploadChildren && (
            <div className="filtered" data-no-drag="true">
              {uploadChildren}
            </div>
          )}
          {downloadImage &&
            downloadImage?.map((el: any, index: number) => (
              <SpeechFile
                key={`${el.key}_${index}`}
                id={el.key}
                className={className(
                  'handle',
                  styles.images,
                  el.type === 'video' && styles['video-label'],
                )}
                type={el.type}
                url={el.url}
                request={false}
                preview={false}
                onDelete={onDelete ? () => onDelete(el.key, index) : false}
                onClick={() => {
                  const fil = downloadImage.filter((lis) => lis.type !== 'video');
                  setViewImages(
                    fil.map((item: { url: string }) => {
                      return {
                        src: item.url,
                      };
                    }),
                  );
                  const cur = fil.findIndex((item) => el.url === item.url);
                  // console.log({ cur });
                  setViewImagesIndex(cur);
                }}
              />
            ))}
          {(downloadImage && downloadImage?.length) || uploadChildren ? null : (
            <div className={styles['no-images']}>{t('暂无图片数据')}</div>
          )}
        </div>
      ) : null}

      {/* 对比图 - 图片类型内容 */}
      {type === 'COMPARE' ? (
        <div className={styles['images-container']}>
          {downloadImage &&
            downloadImage?.map((el: any, index: number) => (
              <SpeechFile
                key={`${el.key}_${index}`}
                preview={false}
                request={false}
                className={className(styles.images, el.type === 'video' && styles['video-label'])}
                type={el.type}
                url={el.url}
                onClick={() => setCompare(downloadImage)}
              />
            ))}

          {downloadImage && downloadImage?.length ? null : (
            <div className={styles['no-images']}>{t('暂无图片数据')}</div>
          )}
        </div>
      ) : null}
      {/* 其他类型 */}
      {type === 'OTHER' ? props.children : null}

      {/* 对比图查看 */}
      <SpeechCompareModel images={compare} name={compareTitle} onClose={() => setCompare([])} />
      {/* 自定义图片-大屏预览 */}
      <ReactViewer
        images={viewImages}
        activeIndex={viewImagesIndex}
        onClose={() => {
          setViewImages([]);
          setViewImagesIndex(0);
        }}
      />
    </div>
  ) : null;
});

export default SpeechField;
