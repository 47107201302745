/*
 * @Description: 讲稿演示-主要内容-卡片
 * @Author: likaifeng
 * @Date: 2023-11-30 16:13:11
 * @LastEditTime: 2024-10-18 11:43:32
 * @LastEditors: 阿峰
 */
import { useLocales } from '@/Locales';
import {
  speechFilterEditAtom,
  speechMainDetailsAtom,
  speechMainDetailTabsAtom,
} from '@/store/speech';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Space } from '@sinohealth/butterfly-ui-components';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import styles from './index.less';

interface SpeechCardProps {
  id?: string;
  title: string;
  subTitle?: string;
  addTitle?: string;
  close?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children?: any;
  style?: React.CSSProperties;
  titleId?: string;
  without?: boolean;
  label?: React.ReactNode;
}

/**
 * @description: 讲稿演示-主要内容-卡片
 * @author: likaifeng
 * @param {*} props
 * @return {*}
 * @Date: 2023-12-04 14:24:48
 */
const SpeechCard: React.FC<SpeechCardProps> = (props) => {
  const setSpeechMainDetail = useSetRecoilState(speechMainDetailsAtom);
  const setSpeechMainDetailTab = useSetRecoilState(speechMainDetailTabsAtom);
  const speechFilterEditFlag = useRecoilValue(speechFilterEditAtom);

  const { t } = useLocales();

  if (props?.without) {
    return props.children;
  }

  return (
    <div id={props?.id} className={styles['speech-card']} style={props.style}>
      <div id={props?.titleId} className={styles['card-title']}>
        <Space>
          <div className={styles.title}>{t(props.title)}</div>
          {props?.subTitle && <div className={styles['sub-title']}>({t(props.subTitle)})</div>}
          {props?.label}
        </Space>
        {/* <i className={`iconfont icon-quxiao ${styles.close}`} /> */}
        {props.addTitle && speechFilterEditFlag ? (
          <Button
            type="primary"
            disabled={props?.disabled}
            icon={<PlusCircleOutlined />}
            onClick={props?.onClick}
          >
            {t('新增')} {t(props.addTitle)}
          </Button>
        ) : null}
        {props?.close ? (
          <CloseOutlined
            onClick={() => {
              setSpeechMainDetail(null);
              setSpeechMainDetailTab(null);
            }}
          />
        ) : null}
      </div>
      {props.children}
    </div>
  );
};

export default SpeechCard;
