import { download } from '@/services/index';
import { previewFile } from '@/utils';
import { Image as AntdImage } from '@sinohealth/butterfly-ui-components';
import React, { useEffect, useState } from 'react';

export const useFetchImageData = (rootSrc: any = '') => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    handleSetUrl(rootSrc);
  }, []);

  const handleSetUrl = (src: string) => {
    if (
      src &&
      typeof src === 'string' &&
      src.indexOf('http') === -1 &&
      src.indexOf('static') === -1
    ) {
      fetchImageData(src).catch(() => {
        // 失败后查多一次，接口不是很稳定
        fetchImageData(src);
      });
    } else {
      setImageUrl(src);
    }
  };

  const fetchImageData = (src: string) => {
    return download(src).then((res: any) => {
      setImageUrl(res);
    });
  };
  return { imageUrl, setSrc: handleSetUrl };
};

const Image = React.memo((props: any) => {
  const { src, onLoadedImgData, request = true, preview = false, isVideo, ...otherProps } = props;
  const { imageUrl, setSrc } = useFetchImageData();

  useEffect(() => {
    // 直接预览地址
    if (!request) {
      onLoadedImgData && onLoadedImgData(src);
      return;
    }
    setSrc(src);
  }, [src]);

  useEffect(() => {
    if (request) {
      onLoadedImgData && imageUrl && onLoadedImgData(imageUrl);
    }
  }, [imageUrl]);

  if (!imageUrl && request) {
    return null;
  }
  if (isVideo) {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video src={imageUrl} controls {...otherProps} />
    );
  }
  return (
    <AntdImage
      key={src}
      preview={preview}
      src={request ? imageUrl : `${previewFile(src)}`}
      {...otherProps}
    />
  );
});

export default Image;
