import { useLocales } from '@/Locales';
import { PlusOutlined } from '@ant-design/icons';
import React, { CSSProperties, MouseEventHandler } from 'react';
import styles from './index.less';

interface TimeTypeButtonProps {
  title: string;
  style?: CSSProperties | undefined;
  onClick?: MouseEventHandler<any> | undefined;
}

const TimeTypeButton: React.FC<TimeTypeButtonProps> = (props) => {
  const { t } = useLocales();

  return (
    <div className={styles.timeline} style={props?.style} onClick={props?.onClick}>
      <PlusOutlined />
      {t(props?.title)}
    </div>
  );
};

export default TimeTypeButton;
