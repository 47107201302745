import Label from '@/components/Label';
import ReactViewer from '@/components/ReactViewer';
import Upload from '@/components/Upload';
import OcrImage from '@/pages/patient/detail/components/OcrImage';
import { Space } from '@sinohealth/butterfly-ui-components';
import { useEffect, useState } from 'react';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import styles from './index.less';

const RecordImgUpload = (props: any) => {
  const { value, onChange, disabled, onEdit, label = '报告图片', accept = 'image/*' } = props;
  const [images, setImages] = useState<string[]>([]);
  // 所有的预览地址
  const [downloadImage, setDownloadImage] = useState<any[]>([]);
  const [viewImages, setViewImages] = useState<ImageDecorator[]>([]);
  const [viewImagesIndex, setViewImagesIndex] = useState(0);

  useEffect(() => {
    setImages(value ? value.split(',') : []);
    setViewImagesIndex(0);
  }, [value]);

  const onUploadSuccess = (info: any) => {
    const imgVar = info.file?.response?.data;
    onChange([...images, imgVar].join(','));
  };

  const onDelete = (imgSrc: string) => {
    const imgs = [...images].filter((item) => item !== imgSrc);
    setDownloadImage(downloadImage.filter((el) => el.url !== imgSrc));
    onChange(imgs.join(','));
  };

  const onUpdate = (index: number, imgSrc: string) => {
    const imgs = [...images];
    imgs[index] = imgSrc; // 替换为新的图片地址[imgSrc]
    onChange(imgs.join(','));
  };

  return (
    <div className={styles.upload}>
      <div style={{ marginBottom: label ? 12 : 0 }}>
        {label && <Label>{label}</Label>}
        {images?.length === 0 && disabled && <span style={{ marginLeft: 12 }}>--</span>}
      </div>
      {(images?.length > 0 || !disabled) && (
        <div className={styles.imageList}>
          <Space wrap>
            {images?.map((url, index) => {
              return (
                <OcrImage
                  className={styles.imgItem}
                  key={url}
                  src={url}
                  onDelete={(e: any) => onDelete(e)}
                  onUpdate={(e: any) => onUpdate(index, e)}
                  onEdit={onEdit}
                  disabled={disabled}
                  onImageUrl={(ul: string) => {
                    const urls = [...downloadImage];
                    urls.push({ src: ul, index, url });
                    setDownloadImage(urls.sort((a, b) => a.index - b.index));
                  }}
                  onPreview={() => {
                    setViewImages([...downloadImage]);
                    setViewImagesIndex(index);
                  }}
                />
              );
            })}
            {!disabled && (
              <Upload
                accept={accept}
                minDimension={15}
                maxDimension={8192}
                onUploadSuccess={onUploadSuccess}
              />
            )}
          </Space>
        </div>
      )}
      {/* 自定义图片-大屏预览 */}
      <ReactViewer
        images={viewImages}
        activeIndex={viewImagesIndex}
        onClose={() => {
          setViewImages([]);
          setViewImagesIndex(0);
        }}
      />
    </div>
  );
};

export default RecordImgUpload;
