import useDict from '@/hooks/dict/useDict';
import { useLocales } from '@/Locales';
import { useAssociatedDetailsColumns } from '@/pages/combinedWard/hook/config';
import { tranType, usePatientDetail } from '@/pages/combinedWard/hook/useTimeline';
import { formatToDate } from '@/utils';
import { Table, Tabs, TabsProps } from '@sinohealth/butterfly-ui-components';
import React, { useEffect, useState } from 'react';
import styles from './index.less';

interface AssociatedTabProps {
  refId?: string;
  refType?: string;
  tableHeight: number;
  onChange?: (refId: string, refType: string, time: string) => void;
}

const AssociatedTab: React.FC<AssociatedTabProps> = (props) => {
  const { refId, refType, tableHeight = 200, onChange } = props;

  const tableBorder = true;

  const { patientDetail } = usePatientDetail(); // 获取患者信息
  const dict = useDict();
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [tabType, setTabType] = useState(tranType[refType || '']);
  const { t } = useLocales();

  useEffect(() => {
    // console.log('refId', refId);
    if (refId) {
      setSelectedRowKeys([refId]);
    }
  }, [refId]);

  const {
    admissionRecordColumns,
    dischargeRecordColumns,
    imageReportColumns,
    surgicalColumns,
    pathologicalReportColumns,
    inspectionColumns,
    geneticColumns,
    medicaRecordColumns,
    comparePicturesColumns,
    chartTrendColumns,
    referencesColumns,
  } = useAssociatedDetailsColumns({ dict });

  const items: TabsProps['items'] = [
    patientDetail.MEDICAL_RECORD.length && {
      key: 'medicalRecord',
      label: t('入院记录'),
      children: (
        <Table
          scroll={{ y: tableHeight }}
          rowKey="id"
          bordered={tableBorder}
          rowSelection={{
            type: 'radio',
            columnTitle: t('操作'),
            columnWidth: 80,
            selectedRowKeys, // 默认选中
            onChange: (rowKeys: any[], selectedRows: any[]) => {
              onChange?.(rowKeys[0], 'MEDICAL_RECORD', formatToDate(selectedRows[0].recordTime));
            },
          }}
          dataSource={patientDetail.MEDICAL_RECORD}
          columns={admissionRecordColumns}
          pagination={false}
        />
      ),
    },
    patientDetail.HOSPITAL_DISCHARGE_RECORD.length && {
      key: 'hospitalDischargeRecords',
      label: t('出院记录'),
      children: (
        <Table
          scroll={{ y: tableHeight }}
          rowKey="id"
          bordered={tableBorder}
          rowSelection={{
            type: 'radio',
            columnTitle: t('操作'),
            columnWidth: 80,
            selectedRowKeys, // 默认选中
            onChange: (rowKeys: any[], selectedRows: any[]) => {
              onChange?.(
                rowKeys[0],
                'HOSPITAL_DISCHARGE_RECORD',
                formatToDate(selectedRows[0].dischargeTime),
              );
            },
          }}
          dataSource={patientDetail.HOSPITAL_DISCHARGE_RECORD}
          columns={dischargeRecordColumns}
          pagination={false}
        />
      ),
    },
    patientDetail.IMAGE_REPORT.length && {
      key: 'imageReport',
      label: t('影像报告'),
      children: (
        <Table
          scroll={{ y: tableHeight }}
          rowKey="id"
          bordered={tableBorder}
          rowSelection={{
            type: 'radio',
            columnTitle: t('操作'),
            columnWidth: 80,
            selectedRowKeys, // 默认选中
            onChange: (rowKeys: any[], selectedRows: any[]) => {
              onChange?.(rowKeys[0], 'IMAGE_REPORT', formatToDate(selectedRows[0].recordTime));
            },
          }}
          dataSource={patientDetail.IMAGE_REPORT}
          columns={imageReportColumns}
          pagination={false}
        />
      ),
    },
    patientDetail.SURGICAL_RECORD.length && {
      key: 'surgicalRecord',
      label: t('手术信息'),
      children: (
        <Table
          scroll={{ y: tableHeight }}
          rowKey="id"
          bordered={tableBorder}
          rowSelection={{
            type: 'radio',
            columnTitle: t('操作'),
            columnWidth: 80,
            selectedRowKeys, // 默认选中
            onChange: (rowKeys: any[], selectedRows: any[]) => {
              onChange?.(rowKeys[0], 'SURGICAL_RECORD', formatToDate(selectedRows[0].recordTime));
            },
          }}
          dataSource={patientDetail.SURGICAL_RECORD}
          columns={surgicalColumns}
          pagination={false}
        />
      ),
    },
    patientDetail.PATHOLOGY_REPORT.length && {
      key: 'pathologyReport',
      label: t('病理报告'),
      children: (
        <Table
          scroll={{ y: tableHeight }}
          rowKey="id"
          bordered={tableBorder}
          rowSelection={{
            type: 'radio',
            columnTitle: t('操作'),
            columnWidth: 80,
            selectedRowKeys, // 默认选中
            onChange: (rowKeys: any[], selectedRows: any[]) => {
              onChange?.(rowKeys[0], 'PATHOLOGY_REPORT', formatToDate(selectedRows[0].recordTime));
            },
          }}
          dataSource={patientDetail.PATHOLOGY_REPORT}
          columns={pathologicalReportColumns}
          pagination={false}
        />
      ),
    },
    patientDetail.INSPECTION_REPORT.length && {
      key: 'inspectionReport',
      label: t('检验报告'),
      children: (
        <Table
          scroll={{ y: tableHeight }}
          rowKey="id"
          bordered={tableBorder}
          rowSelection={{
            type: 'radio',
            columnTitle: t('操作'),
            columnWidth: 80,
            selectedRowKeys, // 默认选中
            onChange: (rowKeys: any[], selectedRows: any[]) => {
              onChange?.(rowKeys[0], 'INSPECTION_REPORT', formatToDate(selectedRows[0].recordTime));
            },
          }}
          dataSource={patientDetail.INSPECTION_REPORT}
          columns={inspectionColumns}
          pagination={false}
        />
      ),
    },
    patientDetail.GENETIC_TESTING.length && {
      key: 'geneticTesting',
      label: t('基因检测'),
      children: (
        <Table
          scroll={{ y: tableHeight }}
          rowKey="id"
          bordered={tableBorder}
          rowSelection={{
            type: 'radio',
            columnTitle: t('操作'),
            columnWidth: 80,
            selectedRowKeys, // 默认选中
            onChange: (rowKeys: any[], selectedRows: any[]) => {
              onChange?.(rowKeys[0], 'GENETIC_TESTING', formatToDate(selectedRows[0].recordTime));
            },
          }}
          dataSource={patientDetail.GENETIC_TESTING}
          columns={geneticColumns}
          pagination={false}
        />
      ),
    },
    patientDetail.DRUG_RECORD.length && {
      key: 'drugRecord',
      label: t('用药记录'),
      children: (
        <Table
          scroll={{ y: tableHeight }}
          rowKey="id"
          bordered={tableBorder}
          rowSelection={{
            type: 'radio',
            columnTitle: t('操作'),
            columnWidth: 80,
            selectedRowKeys, // 默认选中
            onChange: (rowKeys: any[], selectedRows: any[]) => {
              onChange?.(rowKeys[0], 'DRUG_RECORD', formatToDate(selectedRows[0].recordTime));
            },
          }}
          dataSource={patientDetail.DRUG_RECORD}
          columns={medicaRecordColumns}
          pagination={false}
        />
      ),
    },
    patientDetail.COMPARISO_CHART.length && {
      key: 'compareImage',
      label: t('对比图片'),
      children: (
        <Table
          scroll={{ y: tableHeight }}
          rowKey="id"
          bordered={tableBorder}
          rowSelection={{
            type: 'radio',
            columnTitle: t('操作'),
            columnWidth: 80,
            selectedRowKeys, // 默认选中
            onChange: (rowKeys: any[], selectedRows: any[]) => {
              onChange?.(rowKeys[0], 'COMPARISO_CHART', selectedRows[0].name);
            },
          }}
          dataSource={patientDetail.COMPARISO_CHART}
          columns={comparePicturesColumns}
          pagination={false}
        />
      ),
    },
    patientDetail.CORRELATION_CHART.length && {
      key: 'correlationCharts',
      label: t('趋势图'),
      children: (
        <Table
          scroll={{ y: tableHeight }}
          rowKey="id"
          bordered={tableBorder}
          rowSelection={{
            type: 'radio',
            columnTitle: t('操作'),
            columnWidth: 80,
            selectedRowKeys, // 默认选中
            onChange: (rowKeys: any[], selectedRows: any[]) => {
              onChange?.(rowKeys[0], 'CORRELATION_CHART', selectedRows[0].name);
            },
          }}
          dataSource={patientDetail.CORRELATION_CHART}
          columns={chartTrendColumns}
          pagination={false}
        />
      ),
    },
    patientDetail.OTHER_INFORMATION.length && {
      key: 'otherInformation',
      label: t('参考资料'),
      children: (
        <Table
          scroll={{ y: tableHeight }}
          rowKey="id"
          bordered={tableBorder}
          rowSelection={{
            type: 'radio',
            columnTitle: t('操作'),
            columnWidth: 80,
            selectedRowKeys, // 默认选中
            onChange: (rowKeys: any[], selectedRows: any[]) => {
              onChange?.(
                rowKeys[0],
                'OTHER_INFORMATION',
                formatToDate(selectedRows[0].informationDate),
              );
            },
          }}
          dataSource={patientDetail.OTHER_INFORMATION}
          columns={referencesColumns}
          pagination={false}
        />
      ),
    },
  ];

  const handleOnChangeTab = (key: string) => {
    setTabType(key);
  };

  return (
    <Tabs className={styles.tab} activeKey={tabType} items={items} onChange={handleOnChangeTab} />
  );
};

export default AssociatedTab;
