import { useLocales } from '@/Locales';
import { DownOutlined } from '@ant-design/icons';
import { Button, Input, Space } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import AssociatedTab from '../AssociatedTab';
import styles from './index.less';

interface AssociatedDetailsProps {
  refId?: string;
  refContent?: string;
  refType?: string;
  onSubmit?: (id: string, type: string, time: string) => void;
}

/**
 * @description: 联合病房-时间轴讲稿-病例时间轴-关联档案详情
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-09 11:07:14
 */
const AssociatedDetails: React.FC<AssociatedDetailsProps> = (props) => {
  const { refContent, refId, refType, onSubmit } = props;
  const { t } = useLocales();

  const [open, setOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<string>('');

  useEffect(() => {
    if (refType) {
      setSelectedTab(refType);
    }
    if (refId) {
      setSelectedRowKeys(refId);
    }
    if (refContent) {
      setSelectedTime(refContent);
    }
    // console.log({ refType, refId, refContent });
  }, [refType, refId, refContent]);

  useEffect(() => {
    if (!open) {
      setSelectedRowKeys('');
      setSelectedTab('');
      setSelectedTime('');
    }
  }, [open]);

  return (
    <div className={styles['associated-details']}>
      <Input
        placeholder={t('请选择')}
        value={refContent}
        suffix={<DownOutlined style={{ color: '#d9d9d9' }} />}
        onClick={() => setOpen(!open)}
        allowClear
        onChange={(e) => {
          if (!e.target.value) {
            setSelectedRowKeys('');
            setSelectedTab('');
            setSelectedTime('');
            onSubmit?.('', '', '');
          }
        }}
      />
      {open && (
        <div className={classNames(styles.associated)} style={{ maxWidth: 1000 }}>
          <AssociatedTab
            tableHeight={200}
            refId={selectedRowKeys || refId}
            refType={selectedTab || refType}
            onChange={(id, type, time) => {
              setSelectedRowKeys(id);
              setSelectedTab(type);
              setSelectedTime(time);
            }}
          />
          <div className={styles['action-footer']}>
            <span />
            <Space>
              <Button onClick={() => setOpen(false)}>{t('取消')}</Button>
              <Button
                type="primary"
                onClick={() => {
                  onSubmit?.(selectedRowKeys, selectedTab, selectedTime);
                  setOpen(false);
                }}
              >
                {t('确认')}
              </Button>
            </Space>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssociatedDetails;
