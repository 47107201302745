/* eslint-disable no-param-reassign */
/*
 * @Description: 讲稿演示-主要内容-病史摘要
 * @Author: likaifeng
 * @Date: 2023-11-30 16:27:15
 * @LastEditTime: 2024-10-11 14:07:36
 * @LastEditors: 阿峰
 */
import { useLocales } from '@/Locales';
import { usePatientDetail } from '@/pages/speechLecture/hook/makeSpeech';
import {
  speechFilterEditAtom,
  speechMainDetailsAtom,
  speechMainDetailTabsAtom,
  speechOriginDataAtom,
} from '@/store/speech';
import React, { useLayoutEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import SpeechCard from '../SpeechCard';
import styles from './index.less';
import SummaryForm from './SummaryForm';

/**
 * @description: 讲稿演示-主要内容-病史摘要
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:24:17
 */
const SpeechSummary: React.FC = () => {
  const speechOriginData = useRecoilValue(speechOriginDataAtom);
  const setSpeechMainDetails = useSetRecoilState(speechMainDetailsAtom);
  const setSpeechMainDetailTabs = useSetRecoilState(speechMainDetailTabsAtom);
  const speechFilterEditFlag = useRecoilValue(speechFilterEditAtom);
  const { t } = useLocales();

  const { patientDetail, setReload } = usePatientDetail();

  /**
   * @description: 给a标签绑定事件
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-12-13 09:59:37
   */
  const handleClickPatientHistory = () => {
    const aDom: any = document.getElementsByTagName('a');
    if (aDom.length) {
      const len = aDom.length;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < len; i++) {
        aDom[i].onclick = function (e: any) {
          if (e.preventDefault) {
            e.preventDefault();
          } else {
            e.returnValue = false;
          }
          const { id, type } = e.target.dataset;
          const { hash, href } = e.target;
          e.target.className = 'hover-class';
          console.log(e.target.href);
          // console.log(type);
          // console.log(hash);
          if (id && type) {
            handleCalcMainDetail(id, type);
          } else if (hash) {
            const tabName = handleGetQueryVariable('type', hash);
            const ids = handleGetQueryVariable('id', hash);
            handleCalcMainDetail(ids, tabName);
          } else if (href) {
            window.open(href);
          }
        };
      }
    }
  };

  /**
   * @description: 根据hash值获取对应字段的值
   * @author: likaifeng
   * @param {*} name 字段名
   * @param {*} url 地址
   * @return {*}
   * @Date: 2023-12-13 10:06:23
   */
  const handleGetQueryVariable = (name: string, url: string) => {
    const reg = new RegExp(`(^|#)${name}=([^#]*)(#|$)`);
    const r = url.match(reg);

    if (r !== null) {
      return decodeURIComponent(r[2]);
    }
    return '';
  };

  /**
   * @description: 病史摘要获取关联讲稿的数据
   * @author: likaifeng
   * @param {string} ids 讲稿id
   * @param {string} tab 讲稿类型
   * @return {*}
   * @Date: 2023-12-04 14:42:45
   */
  const handleCalcMainDetail = (ids: string, tab: string) => {
    if (!ids || !tab) return;
    const arr: any[] = Object.assign([], speechOriginData[tab]).map((el: any) => ({ ...el }));

    const d = arr.filter((el: any) => Number(el.id) === Number(ids));

    console.log(ids, tab, speechOriginData, d);
    if (d && d.length) {
      setSpeechMainDetails(d[0]);
    } else {
      // 先清空数据
      setSpeechMainDetails(null);
    }
    setSpeechMainDetailTabs(tab);
  };

  useLayoutEffect(() => {
    handleClickPatientHistory();
  }, [patientDetail?.history, speechOriginData, speechFilterEditFlag]);

  return (
    <SpeechCard
      id="patientHistory"
      title="病史摘要"
      subTitle={
        speechFilterEditFlag
          ? '摘要关联病例详情操作：在下方编辑窗口中，单击右键，选择“关联讲稿”，选择关联模块（如入院记录、影像报告等），选择需关联的报告日期。'
          : ''
      }
    >
      {speechFilterEditFlag && (
        <SummaryForm
          patientId={patientDetail?.id}
          history={patientDetail?.history}
          onUpdate={() => setReload(true)}
        />
      )}
      {patientDetail?.history && !speechFilterEditFlag && (
        <div
          className={styles.simmary}
          dangerouslySetInnerHTML={{ __html: patientDetail?.history }}
        />
      )}
      {!patientDetail?.history && !speechFilterEditFlag && (
        <div className={styles.simmary}>{t('未填写')}</div>
      )}
      <div style={{ height: 16 }} />
    </SpeechCard>
  );
};

export default SpeechSummary;
