/*
 * @Description: 讲稿演示-头部
 * @Author: likaifeng
 * @Date: 2023-11-30 15:47:43
 * @LastEditTime: 2024-10-24 15:50:41
 * @LastEditors: 阿峰
 */
import { useLocales } from '@/Locales';
import { useDoctorDetail } from '@/pages/speechLecture/hook/make';
import { usePatientSpeechSubmit } from '@/pages/speechLecture/hook/makeSpeech';
import Screenfull from '@/pages/speechLecture/lecture/components/Screenfull';
import SpeechStopWatch from '@/pages/speechLecture/lecture/components/SpeechStopWatch';
import { speechFontSizesAtom, speechLectureTypeAtom } from '@/store/speech';
import { Button, Select, Space } from '@sinohealth/butterfly-ui-components';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styles from './index.less';

interface SpeechHeaderProps {
  doctorId: string;
  onEdit?: (b: boolean) => void;
  onSave?: (b: boolean) => void;
}

/**
 * @description: 联合病房-时间轴讲稿-病例时间轴-头部数据
 * @author: KaifengLi
 * @param {*} props
 * @version:
 * @Date: 2024-10-17 10:23:37
 */
const TimelineHeader: React.FC<SpeechHeaderProps> = (props) => {
  const { onEdit, onSave } = props;

  const [params] = useSearchParams();
  const name = params.getAll('patientName')[0];
  const consultationId = params.getAll('consultationId')[0]; // 有时不可以编辑
  // 首页不能编辑
  const editOrigin = params.getAll('edit')[0] === 'home';

  const [loading, setLoading] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const { doctorDetail } = useDoctorDetail(props.doctorId);
  const { setCanSubmit } = usePatientSpeechSubmit();
  const [speechFontSize, setSpeechFontSize] = useRecoilState(speechFontSizesAtom);
  const setSpeechLectureType = useSetRecoilState(speechLectureTypeAtom);

  const { t } = useLocales();

  /**
   * @description: 保存讲稿
   * @author: KaifengLi
   * @version: 2.20.0
   * @Date: 2024-08-06 10:45:02
   */
  const handleSaveSpeechFilter = _.throttle(() => {
    onSave?.(true);
    setLoading(true);
    const timer = setTimeout(() => {
      setCanSubmit(true);
      setCanEdit(false);
      onEdit?.(false);
      onSave?.(false);
      setLoading(false);
      clearTimeout(timer);
    }, 2000);
  }, 500);

  const handleSelectFontSize = (e: any) => {
    // window.document.documentElement.setAttribute('data-size', e);
    setSpeechFontSize(e);
  };

  useEffect(() => {
    window.document.documentElement.setAttribute('data-size', speechFontSize);
  }, [speechFontSize]);

  return (
    <header className={styles['speech-header']}>
      <div className={styles['header-container']}>
        <div className={styles['header-title']}>
          <h2 className={styles['speech-title']}>{`${name?.substring(0, 1)}**${t('会诊讲稿')}`}</h2>
          <div className={styles['speech-doctor']}>
            {t('医生')}：{doctorDetail?.name}（{doctorDetail?.hospitalName}{' '}
            {doctorDetail?.departmentName} {doctorDetail?.jobTitle}）
          </div>
        </div>
        <div className={`${styles['header-tools']} ${styles['tools-width']}`}>
          <Space size={12}>
            {canEdit ? (
              <Button
                type="primary"
                shape="round"
                loading={loading}
                onClick={handleSaveSpeechFilter}
              >
                {t('保存')}
              </Button>
            ) : null}
            {!canEdit && !editOrigin && !consultationId && (
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  setCanEdit(true);
                  onEdit?.(true);
                }}
              >
                {t('编辑')}
              </Button>
            )}

            {consultationId && <SpeechStopWatch />}
            <div className={styles['font-size']}>
              <span className={styles['font-label']}>{t('字体大小')}</span>
              <Select
                className={styles['font-select']}
                value={speechFontSize}
                onChange={handleSelectFontSize}
                options={[
                  { value: 1, label: t('小号') },
                  { value: 2, label: t('中号') },
                  { value: 3, label: t('大号') },
                ]}
              />
            </div>
            {!canEdit && (
              <Button
                type="primary"
                shape="round"
                ghost
                onClick={() => {
                  setSpeechLectureType('summaryLecture');
                  document.exitFullscreen();
                }}
              >
                {t('切换摘要型讲稿')}
              </Button>
            )}
            <Screenfull />
          </Space>
        </div>
      </div>
    </header>
  );
};

export default TimelineHeader;
