import TinymceEditor from '@/components/TinymceEditor';
import { useLocales } from '@/Locales';
import { tranRelaType, tranType } from '@/pages/combinedWard/hook/useTimeline';
import RelaModel from '@/pages/combinedWard/timelineLecture/components/relaModel';
import { updatePatientHistory } from '@/services/patient';
import { speechOriginDataAtom, speechUpdateTimeAtom } from '@/store/speech';
import { getUuid } from '@/utils';
import _ from 'lodash';
import moment from 'moment';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

interface SummaryFormProps {
  patientId: string;
  history: string;
  onUpdate?: () => void;
}

const SummaryForm = memo((props: SummaryFormProps) => {
  const { patientId, history } = props;

  const relaModelRef = useRef<any>();
  const [editorRef, setEditorRef] = useState<any>(null);
  const [summary, setSummary] = useState<any>('');
  const [loaded, setLoaded] = useState<any>(false);
  const [pluginConfig, setPluginConfig] = useState<any>({});
  const { t, locales } = useLocales();
  const speechUpdateTime = useRecoilValue(speechUpdateTimeAtom);
  const [speechOriginData] = useRecoilState(speechOriginDataAtom);

  // 当切换语言或者患者信息更新时，刷新富文本编辑器
  const tinymceEditorKey = useMemo(() => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    return patientId + locales;
  }, [patientId, locales]);

  useMemo(() => {
    setSummary(props.history);
  }, [history]);

  useEffect(() => {
    const pluginName = getUuid();
    if (patientId) {
      const menuName = `${pluginName}myLink`;
      addEditorMenu(pluginName, menuName);
      setPluginConfig({
        plugins: [pluginName],
        contextmenu: `link | ${menuName} | un${menuName}`,
      });
      // 这里是因为切换语言时，必须得让插件内容更新完再渲染编辑器，才能正常生效
      setTimeout(() => {
        setLoaded(true);
      }, 200);
    }
    return () => {
      setLoaded(false);
      // @ts-ignore
      const tinymce: any = window.tinymce;
      if (tinymce) {
        tinymce.PluginManager.remove(pluginName);
      }
    };
  }, [patientId, tinymceEditorKey]);

  const handleChange = (v: string) => {
    // onChange(v);
    setSummary(v);
    throttledOnFieldsChange(v);
  };

  // 使用 throttle 函数限制 onFieldsChange 的执行频率
  const throttledOnFieldsChange = useCallback(
    _.debounce(
      (v) => {
        // console.log('Fields changed', val);
        handleSave(v);
      },
      speechUpdateTime,
      { leading: false },
    ),
    [],
  ); // 3000 milliseconds = 3

  const handleSave = (v: string) => {
    return updatePatientHistory({
      id: patientId,
      history: v,
    }).then(() => {
      console.log('更新成功');
      props?.onUpdate && props?.onUpdate();
    });
  };

  const getTreeMenu = () => {
    const optionsConfig = [
      {
        key: 'MedicalRecords',
        type: 'medicalRecord',
        label: t('入院记录'),
      },
      {
        key: 'hospitalDischargeRecords',
        type: 'hospitalDischargeRecords',
        label: t('出院记录'),
        field: {
          label: 'dischargeTime',
        },
      },
      {
        key: 'zhImageReports',
        type: 'imageReport',
        label: t('影像报告'),
      },
      {
        key: 'zhSurgicalRecords',
        type: 'surgicalRecord',
        label: t('手术信息'),
      },
      {
        key: 'zhPathologyReports',
        type: 'pathologyReport',
        label: t('病理报告'),
      },
      {
        key: 'zhInspectionReports',
        type: 'inspectionReport',
        label: t('检验报告'),
      },
      {
        key: 'zhGeneticTestings',
        type: 'geneticTesting',
        label: t('基因检测'),
      },
      {
        key: 'zhDrugRecords',
        type: 'drugRecord',
        label: t('用药记录'),
      },
      {
        key: 'otherInformations',
        type: 'otherInformation',
        label: t('参考文献'),
        field: {
          label: 'informationDate',
        },
      },
      // {
      //   key: 'zhPatientMdtIssues',
      //   type: 'mdtIssues',
      //   label: 'MDT议题',
      // },
      {
        key: 'comparisoCharts',
        type: 'compareImage',
        label: '对比图片',
        field: {
          label: 'name',
        },
      },
      {
        key: 'correlationCharts',
        type: 'correlationCharts',
        label: '趋势图',
        field: {
          label: 'name',
        },
      },
    ];
    const tree = optionsConfig.map((item) => {
      return {
        label: item.label,
        key: item.key,
        children: speechOriginData[item.type].map((childrenItem: any) => {
          const key = item.field?.label || 'recordTime';
          return {
            label: childrenItem[key] ? moment(childrenItem[key]).format('YYYY-MM-DD') : '--',
            key: childrenItem[key] + childrenItem.id + item.key,
            tabType: item.type,
            ...childrenItem,
          };
        }),
      };
    });
    return tree;
  };

  // 添加富文本编辑器中关联讲稿的菜单
  const addEditorMenu = (pluginName: string, menuName: string) => {
    // @ts-ignore
    const tinymce: any = window.tinymce;
    if (tinymce) {
      // 菜单选中事件
      const treeOptions = getTreeMenu();
      tinymce.PluginManager.add(pluginName, (editor: any) => {
        const onAction = (id: string, type: string, text: string, parentLabel: string) => {
          // const url = `#type=${type}#id=${id}`;
          const selectionNode = editor.selection.getNode();
          // 如果选中的是a标签，则替换整个a标签
          if (selectionNode.tagName === 'A') {
            editor.selection.select(selectionNode);
          }
          const selectionText =
            editor.selection.getContent({ format: 'text' }) || `${parentLabel}【${text}】`;
          editor.insertContent(
            `<a href="javaScript:void(0);" data-type="${type}" data-id="${id}" title="${parentLabel}${text}" style="color: blue;cursor: pointer;text-decoration: underline;">${selectionText}</a>`,
          );
        };
        const mapTree = (options: any[], parent?: any) => {
          return options
            .filter((item) => {
              const hasChildren = Object.hasOwn(item, 'children');
              return hasChildren ? item.children.length > 0 : true;
            })
            .map((item) => {
              const hasChildren = Object.hasOwn(item, 'children');
              // eslint-disable-next-line no-shadow
              let params = {};
              if (hasChildren) {
                params = {
                  text: item.label,
                  getSubmenuItems() {
                    const childMenuKeys = mapTree(item.children, item);
                    return childMenuKeys.join(' | ');
                  },
                };
              } else {
                params = {
                  text: item.label,
                  onAction() {
                    onAction(item.id, item.tabType, item.label, parent.label);
                  },
                };
              }
              editor.ui.registry.addMenuItem(`${item.key}`, params);
              return item.key;
            });
        };

        editor.ui.registry.addMenuItem(menuName, {
          type: 'submenu',
          icon: 'link',
          text: t('关联讲稿'),
          // getSubmenuItems() {
          //   const keys = mapTree(treeOptions);
          //   return keys.join(' | ');
          // },
          onAction() {
            setEditorRef(editor);
            relaModelRef.current.open();
          },
        });

        editor.ui.registry.addMenuItem(`un${menuName}`, {
          icon: 'unLink',
          text: t('取消关联讲稿'),
          onAction() {
            const selectionNode = editor.selection.getNode();
            editor.selection.select(selectionNode);
            editor.insertContent(selectionNode.innerText);
          },
        });

        editor.ui.registry.addContextMenu(menuName, {
          update: (element: any) => {
            return menuName;
          },
        });

        editor.ui.registry.addContextMenu(`un${menuName}`, {
          update: (element: any) => {
            if (element.tagName === 'A' && element.getAttribute('data-id')) {
              return `un${menuName}`;
            }
            return '';
          },
        });
      });
    }
  };

  return (
    <div style={{ height: 500 }}>
      {loaded && (
        <TinymceEditor
          key={tinymceEditorKey}
          height="100%"
          {...pluginConfig}
          value={summary}
          onChange={handleChange}
        />
      )}
      <RelaModel
        ref={relaModelRef}
        onChange={(refId: string, refType: string, time: string) => {
          // console.log('onChange', editorRef);
          // console.log('onChange', refId, refType, time);
          // const url = `#type=${type}#id=${id}`;
          const selectionNode = editorRef.selection.getNode();
          // 如果选中的是a标签，则替换整个a标签
          if (selectionNode.tagName === 'A') {
            editorRef.selection.select(selectionNode);
          }
          const selectionText =
            editorRef.selection.getContent({ format: 'text' }) ||
            `${tranRelaType[refType]}【${time}】`;
          editorRef.insertContent(
            `<a href="javaScript:void(0);" data-type="${tranType[refType]}" data-id="${refId}" title="${tranRelaType[refType]}${time}" style="color: blue;cursor: pointer;text-decoration: underline;">${selectionText}</a>`,
          );
        }}
      />
    </div>
  );
});

export default SummaryForm;
