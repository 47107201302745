/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import styles from './index.less';

import useDict from '@/hooks/dict/useDict';
import { useTimeLineSave } from '@/pages/combinedWard/hook/useTimeline';
import { Divider, Modal, Space } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import ImageItem from './components/ImageItem';
import Inspec from './components/Inspec';
import Other from './components/Other';
import Treatment from './components/Treatment';

const { confirm } = Modal;

interface TimelineStepProps {
  arrow?: boolean;
  timeStepLine?: boolean;
  edit?: boolean;
  move?: boolean;
  dateTime?: string;
  treatmentData?: any[];
  imageData?: any[];
  inspecData?: any[];
  otherData?: any[];
  heights: number;
  style?: CSSProperties | undefined;
  onChangeHeight?: (height: any) => void;
  onDelete?: () => void;
  onUpdate?: (value?: any) => void;
  onRela?: (id: string, ty: string) => void;
}

export const ToolFooter = (prop: any) => {
  return prop?.edit ? (
    <Space
      style={{ paddingLeft: 12 }}
      size={0}
      split={<Divider style={{ borderColor: '#FFF' }} type="vertical" />}
    >
      <EditOutlined style={{ color: '#FFF', cursor: 'pointer' }} onClick={prop?.onEdit} />
      <DeleteOutlined
        style={{ color: '#FFF', cursor: 'pointer' }}
        onClick={() => {
          confirm({
            title: '操作确认',
            icon: <ExclamationCircleFilled />,
            content: '请确认是否删除该信息?',
            onOk() {
              prop?.onDel();
            },
            onCancel() {
              console.log('Cancel');
            },
          });
        }}
      />
    </Space>
  ) : null;
};

/**
 * @description: 联合病房-时间轴讲稿-病例时间轴 - 病例时间轴
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-11 17:54:15
 */
const TimelineStep: React.FC<TimelineStepProps> = (props) => {
  const {
    arrow = true,
    timeStepLine,
    edit,
    move,
    dateTime,
    treatmentData,
    imageData,
    inspecData,
    otherData,
    heights,
    style,
    onChangeHeight,
    onDelete,
    onUpdate,
    onRela,
  } = props;

  const treatmentRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const inpectionRef = useRef<HTMLDivElement>(null);
  const otherRef = useRef<HTMLDivElement>(null);
  const dict: any = useDict();
  const { setTimelineParams, patientId, saveSuccess } = useTimeLineSave();
  const [cardHeight, setCardHeight] = useState({
    treatmentHeight: 0,
    inspectHeight: 0,
    imageHeight: 0,
    otherHeight: 0,
  });

  useEffect(() => {
    const d = cardHeight;
    if (treatmentRef.current) {
      const newTreatmentHeight = treatmentRef.current.scrollHeight; // 获取新的高度
      // console.log('treatmentHeight', newTreatmentHeight);
      d.treatmentHeight = newTreatmentHeight;
    } else {
      d.treatmentHeight = 0;
    }
    if (imageRef.current) {
      const height = imageRef.current.scrollHeight; // 获取新的高度
      // console.log('imageHeight', height);
      d.imageHeight = height;
    } else {
      d.imageHeight = 0;
    }
    if (inpectionRef.current) {
      const height = inpectionRef.current.scrollHeight; // 获取新的高度
      // console.log('inspectHeight', height);
      d.inspectHeight = height;
    } else {
      d.inspectHeight = 0;
    }
    if (otherRef.current) {
      const height = otherRef.current.scrollHeight; // 获取新的高度
      // console.log('otherHeight', height);
      d.otherHeight = height;
    } else {
      d.otherHeight = 0;
    }
    // console.log(treatmentData);
    setCardHeight({ ...d });
  }, [treatmentData, imageData, edit, inspecData, otherData]);

  useEffect(() => {
    onChangeHeight?.(cardHeight.treatmentHeight);
  }, [cardHeight]);

  useEffect(() => {
    saveSuccess && onDelete?.();
  }, [saveSuccess]);

  return (
    <div className={styles['timeline-step']} style={style}>
      <div className={classNames(styles['card-wrapper'])} style={{ minHeight: heights + 36 }}>
        {treatmentData?.length ? (
          <Treatment
            treatmentData={treatmentData}
            dict={dict}
            edit={edit}
            move={move}
            ref={treatmentRef}
            onUpdate={(el) => onUpdate?.(el)}
            onRela={onRela}
            onDelete={(el) => {
              setTimelineParams({
                patientId,
                timeLineList: [
                  {
                    dateStr: dateTime,
                    treatmentRecords: [{ ...el, deleted: 1 }],
                  },
                ],
              });
            }}
            onSort={(el) => {
              setTimelineParams({
                patientId,
                timeLineList: [
                  {
                    dateStr: dateTime,
                    treatmentRecords: el,
                  },
                ],
              });
            }}
          />
        ) : null}
        <div style={{ height: 36 }} />
      </div>
      <div
        className={classNames(
          styles['time-step'],
          timeStepLine && styles['time-step-border'],
          arrow && classNames(styles['triangle-right']),
        )}
      >
        {dateTime}
      </div>
      {imageData?.length ? (
        <ImageItem
          imageData={imageData}
          edit={edit}
          move={move}
          ref={imageRef}
          dict={dict}
          onUpdate={(el) => onUpdate?.(el)}
          onRela={onRela}
          onDelete={(el) => {
            setTimelineParams({
              patientId,
              timeLineList: [
                {
                  dateStr: dateTime,
                  imageReportRecords: [{ ...el, deleted: 1 }],
                },
              ],
            });
          }}
          onSort={(el) => {
            setTimelineParams({
              patientId,
              timeLineList: [
                {
                  dateStr: dateTime,
                  imageReportRecords: el,
                },
              ],
            });
          }}
        />
      ) : null}

      {inspecData?.length ? (
        <Inspec
          ref={inpectionRef}
          inspecData={inspecData}
          edit={edit}
          move={move}
          cardHeight={cardHeight}
          onUpdate={(el) => onUpdate?.(el)}
          onRela={onRela}
          onDelete={(el) => {
            setTimelineParams({
              patientId,
              timeLineList: [
                {
                  dateStr: dateTime,
                  detectionRecords: [
                    {
                      ...el,
                      diagnosisDescription: el?.diagnosisDescription
                        ? JSON.stringify(el?.diagnosisDescription)
                        : undefined,
                      deleted: 1,
                    },
                  ],
                },
              ],
            });
          }}
          onSort={(el) => {
            setTimelineParams({
              patientId,
              timeLineList: [
                {
                  dateStr: dateTime,
                  detectionRecords: el,
                },
              ],
            });
          }}
        />
      ) : null}

      {otherData?.length ? (
        <Other
          ref={otherRef}
          cardHeight={cardHeight}
          dict={dict}
          otherData={otherData}
          edit={edit}
          move={move}
          onUpdate={(el) => onUpdate?.(el)}
          onRela={onRela}
          onDelete={(el) => {
            setTimelineParams({
              patientId,
              timeLineList: [
                {
                  dateStr: dateTime,
                  diagnosisRecords: [{ ...el, deleted: 1 }],
                },
              ],
            });
          }}
          onSort={(el) => {
            setTimelineParams({
              patientId,
              timeLineList: [
                {
                  dateStr: dateTime,
                  diagnosisRecords: el,
                },
              ],
            });
          }}
        />
      ) : null}
    </div>
  );
};

export default TimelineStep;
