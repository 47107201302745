import { request } from '@/common/request';

// 医生信息库列表
export const getDoctorInfoList = (params: any) => {
  return request.post<any, any>('/doctor/database/page', params);
};
// 医生信息库详情
export const getDoctorDatabaseDetail = (params: any) => {
  return request.post<any, any>('/doctor/database/detail', params);
};
// 新增医生信息
export const addDoctorInfo = (params: any) => {
  return request.post<any, any>('/doctor/database/add', params);
};
// 更新医生信息
export const updateDoctorInfo = (params: any) => {
  return request.post<any, any>('/doctor/database/update', params);
};
// 删除医生信息
export const deleteDoctorInfo = (id: string) => {
  return request.post<any, any>('/doctor/database/delete', { id });
};
// 医生信息统计
export const getDoctorInfoStatistical = () => {
  return request.post<any, any>('/doctor/database/statistical');
};
// 医生信息详情
export const getDoctorInfoDetail = (id: string) => {
  return request.post<any, any>('/doctor/info', { id });
};

export default {};
