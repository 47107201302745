/**
 * 权限编码表
 * * */
const codes: any = {
  commonLayout: {
    team: 'isShowTeam', // 是否显示当前机构
  },
  // 患者列表
  patientList: {
    add: 'patientListAdd', // 添加患者
    delete: 'patientListDelete', // 删除患者
    ppt: 'patientListPPT', // PPT报告列
    mdt: 'patientListMdt', // MDT列
    shareBtn: 'patientListShare', // 分享患者按钮
    lectureBtn: 'patientListLecture', // 制作讲稿按钮
  },
  // 患者详情
  patientDetail: {
    edit: 'patientDetailEdit', // 编辑患者权限
    patientInfoOcr: 'patientInfoOcr',
  },
  // MDT团队列表
  mdtTeamList: {
    add: 'mdtAdd', // 新增团队
    delete: 'mdtDelete', // 删除团队
    detail: 'mdtTeamDetails', // 查询团队详情
  },
  // MDT团队详情
  mdtTeamDetail: {
    mdtUpdate: 'mdtUpdate', // 基本信息
    editMember: 'editMember', // 编辑成员
    changeAdmin: 'mtdMemberUpdate', // 更换管理员
    addMember: 'addMember', // 新增成员
    deleteMember: 'deleteMember', // 删除成员
  },
  // 联合病房MDT团队
  combinedWardMdtTeamList: {
    add: 'unionwardTeamAdd', // 新增团队
    delete: 'unionwardTeamDelete', // 删除团队
    detail: 'unionwardTeamDetail', // 查询团队详情
  },
  // 联合病房MDT团队详情
  combinedWardMdtTeamDetail: {
    mdtUpdate: 'teamMemberUpdate', // 基本信息
    editMember: 'editTeamMemberBtn', // 编辑成员
    changeAdmin: 'teamMemberUpdate', // 更换管理员
    addMember: 'teamMemberAdd', // 新增成员
    deleteMember: 'teamMemberDelete', // 删除成员
  },
  // 病例库权限
  caseHistoryList: {
    delPtt: 'patientPPTDelete',
    delMdt: 'patientMdtReportDelete',
    patiendDetail: 'casePatientDetail',
    caseMaking: 'caseMaking',
    caseConsultationDetail: 'caseConsultationDetail',
    shareOrCancel: 'shareOrCancel',
  },
  // 数据统计
  mdtStatistics: {
    overviewAnalysis: 'OverviewAnalysis', // Mdt数据统计-数据概览-tab权限
    activityAnalysis: 'ActivityAnalysis', // Mdt数据统计-活动分析-tab权限
    caseAnalysis: 'CaseAnalysis', // Mdt数据统计-病例分析-tab权限
    statisticalImage: 'statisticalStatisticImage', // MDT数据统计-病例分析-影像类型比例
    statisticalPathology: 'statisticalStatisticGeneAndPathology', // MDT数据统计-病例分析-检测比例
    statisticalSex: 'statisticalSex', // MDT数据统计-病例分析-性别
    statisticalAge: 'statisticalAge', // MDT数据统计-病例分析-患者年龄分布
    statisticalEnter: 'statisticalEnter', // MDT数据统计-病例分析-入库病例增长趋势
    statisticalSummary: 'statisticalStatisticEnterSummary', // MDT数据统计-病例分析-入库病例
    statisticalJobTitle: 'statisticalStatisticJobTitle', // MDT数据统计-活动分析-医生职称占比
    statisticalDepartment: 'statisticalStatisticDepartment', // MDT数据统计-活动分析-科室人数占比
    statisticalRank: 'statisticalStatisticRank', // MDT数据统计-活动分析-团队MDT排名
    statisticalDoctor: 'statisticalStatisticDoctor', // MDT数据统计-活动分析-医生MDT次数排名TOP10
    statisticalTrend: 'statisticalStatisticTrend', // MDT数据统计-活动分析-MDT开展场次趋势
    totalNumberOfCases: 'totalNumberOfCases', // MDT数据统计-数据概览-病例总数
    numberOfExperts: 'numberOfCooperativeExperts', // MDT数据统计-数据概览-合作专家数
    numberOfTeams: 'numberOfTeams', // MDT数据统计-数据概览-团队数
    mdtSessionsCarriedOut: 'mdtSessionsCarriedOut', // MDT数据统计-数据概览-已开展MDT场次
    statisticalDrugRank: 'statisticalStatisticDrugRank', // MDT数据统计-数据概览-药品使用排行
    statisticalProvinceTotal: 'statisticalStatisticProvinceTotal', // MDT数据统计-数据概览-MDT地市分布
    statisticalOrganization: 'statisticalStatisticOrganization', // MDT数据统计-数据概览-机构MDT排名
    statisticalDdtTrend: 'statisticalDdtTrend', // MDT数据统计-数据概览-近12月MDT开展场次趋势
    agencyDataListPage: 'agencyDataListPage', // Mdt数据统计-BI配置列表
  },
  doctorList: {
    add: 'doctorListAdd', // 添加医生
  },
  doctorDetail: {
    edit: 'DoctorMangeDetailEdit', // 编辑医生
    // 医生详情-患者列表
    patient: {
      add: 'DoctorPatientListAdd', // 添加患者
      delete: 'DoctorPatientListDelete', // 删除患者
      ppt: 'DoctorPatientListPPT', // PPT报告列
      mdt: 'DoctorPatientListMdt', // MDT列
      shareBtn: 'DoctorPatientListShare', // 分享患者按钮
      lectureBtn: 'DoctorPatientListLecture', // 制作讲稿按钮
    },
  },
  doctorInfoList: {
    add: 'DoctorInfoAdd', // 添加医生信息
    delete: 'DoctorInfoDelete', // 删除医生信息
  },
  doctorAssistant: {
    add: 'DoctorAssistantListAdd', // 添加医助
    delete: 'DoctorAssistantListDelete', // 删除医助
  },
  // 机构管理权限
  institutionAdminList: {
    isSearchAllAdd: 'mechanismManagement:mechanismAdd:isSearchAllAddNew', // 机构管理-添加机构-是否查询所有
  },
};
export default codes;
