import { useLocales } from '@/Locales';
import TumorStageSelect from '@/components/TumorStageSelect';
import { formButtonColor, useTimeLineSave } from '@/pages/combinedWard/hook/useTimeline';
import { DatePicker, Form, Input, Modal } from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import AssociatedDetails from '../AssociatedDetails';
import FormLabel from '../FormLabel';
import TimeTypeButton from '../TimeTypeButton';

interface PathologyFormProps {
  button?: boolean;
  onSubmit?: () => void;
}

/**
 * @description: 联合病房-时间轴讲稿-病例时间轴-病理信息-FORM
 * @author: KaifengLi
 * @version: v2.24.0
 * @Date: 2024-10-10 15:46:19
 */
const PathologyForm = forwardRef((props: PathologyFormProps, ref: any) => {
  const { t } = useLocales();

  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const { patientId, setTimelineParams, setFormParams, formParams, saveSuccess } =
    useTimeLineSave();

  useImperativeHandle(ref, () => ({
    open: (val: any) => {
      setOpen(true);
      form.setFieldsValue({
        ...val,
        ...val?.diagnosisDescription,
        recordTime: val.recordTime ? dayjs(val.recordTime) : null,
      });
      setFormParams(val);
    },
  }));

  const handleSubmit = () => {
    form.validateFields().then((formValue) => {
      setTimelineParams({
        patientId,
        timeLineList: [
          {
            dateStr: formValue.recordTime.format('YYYY-MM'),
            detectionRecords: [
              {
                id: formParams?.id,
                diagnosisDescription: JSON.stringify({
                  summary: formValue.summary,
                  tumorStaging: formValue.tumorStaging,
                  immunohistochemistry: formValue.immunohistochemistry,
                }),
                recordTime: formValue.recordTime.format('YYYY-MM-DD'),
                refId: formParams?.refId,
                refType: formParams?.refType,
                refContent: formParams?.refContent,
                nodeType: 'PATHOLOGY_REPORT',
              },
            ],
          },
        ],
      });
    });
  };

  useEffect(() => {
    if (saveSuccess) {
      props?.onSubmit?.();
      setOpen(false);
    }
  }, [saveSuccess]);

  return (
    <>
      {props?.button && (
        <TimeTypeButton
          title="病理信息"
          style={{ backgroundColor: formButtonColor.PathologyForm }}
          onClick={() => setOpen(true)}
        />
      )}
      <Modal
        title={`${formParams?.id ? t('编辑') : t('新增')} ${t('病理信息')}`}
        open={open}
        maskClosable={false}
        style={{ top: 20 }}
        onOk={handleSubmit}
        onCancel={() => setOpen(false)}
        afterClose={() => {
          form.resetFields();
          setFormParams({});
        }}
      >
        <Form
          name="basic"
          layout="vertical"
          colon={false}
          form={form}
          labelCol={{ span: 12 }}
          autoComplete="off"
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="recordTime"
            rules={[{ required: true, message: t('请选择检查时间') }]}
            label={<FormLabel label="检查时间" />}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label={<FormLabel label="病理概述" />}
            name="summary"
            rules={[{ required: true, message: t('请输入病理概述') }]}
          >
            <Input.TextArea
              maxLength={1000}
              showCount
              autoSize={{ minRows: 4, maxRows: 4 }}
              placeholder={t('请输入病理概述')}
            />
          </Form.Item>
          <Form.Item label={<FormLabel label="免疫组化" />} name="immunohistochemistry">
            <Input.TextArea
              maxLength={200}
              showCount
              autoSize={{ minRows: 4, maxRows: 4 }}
              placeholder={t('请输入免疫组化')}
            />
          </Form.Item>
          <Form.Item label={<FormLabel label="病理分期" />} name="tumorStaging">
            <TumorStageSelect style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label={<FormLabel label="关联档案详情" tooltip />} name="refContent">
            <AssociatedDetails
              refContent={formParams?.refContent}
              refId={formParams?.refId}
              refType={formParams?.refType}
              onSubmit={(id, type, time) => {
                setFormParams({
                  ...formParams,
                  refId: id || undefined,
                  refType: type || undefined,
                  refContent: time || undefined,
                });
                form.setFieldsValue({ refContent: time });
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});

export default PathologyForm;
