import { getLocalStorage } from '@/utils/cookies';
import { atom } from 'recoil';

export const speechEditDataAtom = atom<any>({
  key: 'speechEditData',
  default: getLocalStorage('SPEECH_EDIT_DATA_KEY') || null,
});

export const speechEditTimeAtom = atom<any>({
  key: 'speechEditTime',
  default: 10000,
});

export const timelineHeightAtom = atom<any>({
  key: 'timelineHeight',
  default: 0,
});
