import { Tag, TagProps } from '@sinohealth/butterfly-ui-components';
import classNames from 'classnames';
import React from 'react';
import styles from './index.less';

interface PatientTagProps extends TagProps {
  border?: boolean;
  padding?: boolean;
}

const PatientTag: React.FC<PatientTagProps> = (props) => {
  const { border, padding = true } = props;
  return (
    <Tag
      {...props}
      className={classNames(
        props?.className,
        styles['tag-round'],
        !border && styles['tag-border-none'],
        !props.color && styles['tag-default'],
        padding && styles['patient-tag'],
      )}
    >
      {props?.children}
    </Tag>
  );
};

export default PatientTag;
