import TimelineCases from '@/pages/combinedWard/timelineLecture/components/TimelineCases';
import { speechLectureTypeAtom } from '@/store/speech';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Lecture from './components/Lecture';

export interface CreateFormProps {
  item: any;
  index: number;
  patientId: string;
  firstRender: boolean;
  speechUpdateTime: number;
  ocrContent: React.Component;
  onUpdate?: (value?: any) => void;
  onDelete?: (value?: any) => void;
  onAdd?: () => void;
}

/**
 * @description: 能编辑的讲稿页面
 * @author: KaifengLi
 * @version: 2.20.0
 * @Date: 2024-08-06 12:04:00
 */
const LectureContainer: React.FC = () => {
  const [params] = useSearchParams();
  const timelecture = params.getAll('type')[0]; // 是否为时间轴讲稿

  const [speechLectureType, setSpeechLectureType] = useRecoilState(speechLectureTypeAtom);

  useEffect(() => {
    setSpeechLectureType(timelecture);
  }, [timelecture]);

  if (speechLectureType === 'timelineLecture') {
    return <TimelineCases timeline />;
  }

  if (speechLectureType === 'summaryLecture') {
    return <Lecture />;
  }

  return null;
};

export default LectureContainer;
