import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import menuConfig, { MenuItem } from '@/config/menu';
import useCurrentLoginUserInfo from '@/hooks/useCurrentLoginUserInfo';
import { useLocales } from '@/Locales';

export function useMenuConfig() {
  const { hash } = window.location;
  const navigate = useNavigate();
  const pathname = hash.replace('#', '');
  const [userInfo] = useCurrentLoginUserInfo();
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([pathname]);
  const [defaultOpenKeys, setDefaultOpenKeys] = useState<string[]>([]);
  const [menuData, setMenuData] = useState<any>([]);
  const codes = userInfo?.resourceCodes; // 所有权限编码
  const { t, locales } = useLocales();
  useEffect(() => {
    if (userInfo) {
      initMenuConfig();
    }
  }, [userInfo, navigate, locales]);

  const mapMenu = (menus: MenuItem[]): any => {
    const newMenu: any = [];
    menus.forEach((item) => {
      if (item.code && codes && !codes.includes(item.code)) {
        return; // 无菜单权限
      }
      const menuItem = {
        ...item,
        label: t(item.label),
        key: item.path,
        children: item.children ? mapMenu(item.children) : null,
      };
      if (Array.isArray(menuItem.children) && menuItem.children.length === 0) {
        return; // 代表当前菜单为父菜单，切其下所有子菜单都没权限，所有也不用显示
      }
      newMenu.push(menuItem);
    });
    return newMenu;
  };
  const getSelectedKeyPatient = (key: string) => {
    let returnKeys: any = [];
    const forEach = (menus: MenuItem[], defaultPatientKeys: any = []) => {
      const patientKeys: any = [...defaultPatientKeys];
      menus.forEach((item) => {
        if (item.path === key) {
          returnKeys = [...patientKeys];
        } else if (item.children) {
          forEach(item.children, [item.path]);
        }
      });
    };
    forEach(menuConfig);
    return returnKeys;
  };

  const initMenuConfig = () => {
    setMenuData(mapMenu(menuConfig));
    setDefaultSelectedKeys([pathname]);
    setDefaultOpenKeys(getSelectedKeyPatient(pathname));
  };

  return {
    menuData,
    defaultOpenKeys,
    defaultSelectedKeys,
  };
}

export default useMenuConfig;
