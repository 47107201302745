import Text from '@/components/Text';
import { PatientInfoStatus } from '@/pages/patient/detail';
import { Badge, DatePicker, Space } from '@sinohealth/butterfly-ui-components';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import styles from './index.less';

type TabHeaderProps = {
  title: React.ReactNode | string;
  label?: React.ReactNode | string;
  disabled?: Boolean;
  action?: React.ReactNode;
};
const TabHeader = (props: TabHeaderProps) => {
  const { title, action, disabled = true, label } = props;
  const permission = useRecoilValue(PatientInfoStatus);

  return (
    <div className={styles.tabHeader}>
      <div className={styles.left}>
        <Badge status="processing" />
        &nbsp;&nbsp;&nbsp;{label || <Text>检查时间</Text>}：{title}
      </div>
      <Space className={styles.right}>{permission.edit || !disabled ? action : ''}</Space>
    </div>
  );
};

export const TabHeaderInput = (props: any) => {
  const { value, label, onChange, disabled = false, action } = props;
  const dateValue = dayjs(value || Date.now());

  useEffect(() => {
    onChange(dateValue.format('YYYY-MM-DD'));
  }, []);

  const title = disabled ? (
    value
  ) : (
    <DatePicker
      value={dateValue}
      onChange={(v) => onChange(v ? v.format('YYYY-MM-DD') : null)}
      format="YYYY-MM-DD"
      allowClear={false}
      style={{ width: 240 }}
    />
  );
  return <TabHeader label={label} title={title} disabled={disabled} action={action} />;
};

export default TabHeader;
