import AsyncSelect from '@/components/AsyncSelect';
import { useLocales } from '@/Locales';
import { getHospitalList } from '@/services/common';

const HospitalSelect = (props: any) => {
  const { placeholder } = props;
  const { t } = useLocales();
  const fetchHospitalList = (v: string = '') => {
    return getHospitalList({
      pageSize: 99999,
      currentPage: 1,
      name: v,
    }).then((res) => {
      return res.list.map((item: any) => {
        return {
          ...item,
          label: item.name,
          value: item.id,
        };
      });
    });
  };
  return (
    <AsyncSelect
      {...props}
      showSearch
      placeholder={placeholder || t('请搜索选择所属医院')}
      fetchOptions={fetchHospitalList}
    />
  );
};

export default HospitalSelect;
