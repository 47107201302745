import { useLocales } from '@/Locales';
import {
  speechFilterEditAtom,
  speechOriginDataAtom,
  speechSaveLoadingAtom,
  speechSideMenusAtom,
  speechSideTabsAtom,
} from '@/store/speech';
import { removeLocalStorage } from '@/utils/cookies';
import { Spin } from '@sinohealth/butterfly-ui-components';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styles from '../../index.less';
import SpeechHeader from '../SpeechHeader';
import SpeechIssue from '../SpeechIssue';
import SpeechMain from '../SpeechMain';
import SpeechSide from '../SpeechSide';
import SpeechSubfield from '../SpeechSubfield';

export interface CreateFormProps {
  item: any;
  index: number;
  patientId: string;
  firstRender: boolean;
  speechUpdateTime: number;
  ocrContent: React.Component;
  onUpdate?: (value?: any) => void;
  onDelete?: (value?: any) => void;
  onAdd?: () => void;
}

/**
 * @description: 能编辑的讲稿页面
 * @author: KaifengLi
 * @version: 2.20.0
 * @Date: 2024-08-06 12:04:00
 */
const Lecture: React.FC = () => {
  const [params] = useSearchParams();
  const name = params.getAll('patientName')[0];
  const patientId = params.getAll('patientId')[0];
  // 会诊ID, 旧跳转入口有使用id, 有使用consultationId,所在这里做个兼容
  const consultationId = params.getAll('id')[0] || params.getAll('consultationId')[0];

  const [speechTitle, setSpeechTitle] = useState('');

  const setSpeechSideTab = useSetRecoilState(speechSideTabsAtom);
  const [speechSideMenu] = useRecoilState(speechSideMenusAtom);
  const [speechSaveLoading, setSpeechSaveLoading] = useRecoilState(speechSaveLoadingAtom);
  const [speechOriginData] = useRecoilState(speechOriginDataAtom);
  const speechFilterEdit = useRecoilValue(speechFilterEditAtom);
  const [layoutLoading, setLayoutLoading] = useState(true);

  const { t } = useLocales();

  /**
   * @description: 计算内容区的高度集合
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-12-05 16:48:41
   */
  const handleCalcDomHeight = () => {
    const timer = setTimeout(() => {
      const heightSum: any = {};
      const labelItem: any[] = [];
      const heightArr: any[] = [];
      let sum = 0;
      speechSideMenu.forEach((el: any, index: number) => {
        const labelDom = document.getElementById(el.label);
        const fatherHei = labelDom ? labelDom.offsetHeight : 0;
        // console.log(fatherHei)
        sum += fatherHei;
        heightSum[el.label] = sum;
        heightArr.push(sum);
        labelItem.push(el.label);
        if (el?.children?.length) {
          el.children.forEach((item: any, ind: number) => {
            const sonDom = document.getElementById(item.label);
            // console.log(sonDom)
            const sonHei = sonDom ? sonDom.offsetHeight : 0;
            // if (item?.label.indexOf('otherInformation_') > -1 && ind === el.children.length - 1) {
            //   console.log(item?.label);
            //   // 最后一个otherInformation
            //   sum += sonHei;
            //   sum += 300;
            // } else {
            // }
            sum += sonHei;
            heightSum[item.label] = sum;
            heightArr.push(sum);
            labelItem.push(item.label);
          });
        }
        if (!el?.children?.length && el.label !== 'baseInfo' && el.label !== 'patientHistory') {
          sum += 146;
          heightSum[`${el.label}_${index}`] = sum;
          heightArr.push(sum);
          labelItem.push(`${el.label}_${index}`);
        }
      });
      sum += 250;
      heightSum.otherInformation_footer = sum;
      heightArr.push(sum);
      labelItem.push('otherInformation_footer');
      // console.log(heightSum);
      // console.log(heightArr);
      handleBindScrollEvent(heightArr, labelItem);
      clearTimeout(timer);
    }, 1000);
  };

  /**
   * @description: 内容区滚动事件
   * @author: likaifeng
   * @param {*} arr 高度集合
   * @param {*} label 标签集合
   * @return {*}
   * @Date: 2023-12-05 16:43:19
   */
  const handleBindScrollEvent = (arr: any[], label: any) => {
    const Dom = document.getElementById('speechMain');

    if (!Dom) return;
    Dom.onscroll = () => {
      const scrollTop = Dom.scrollTop;

      const scrollWhichIndex = arr.findIndex((item, index) => {
        return scrollTop >= arr[index] && scrollTop < arr[index + 1];
      });
      // console.log(scrollTop)
      // console.log('所在区间', scrollWhichIndex)
      let index = 0;
      if (scrollWhichIndex > -1) {
        index = scrollWhichIndex + 1;
      } else {
        index = 0;
      }
      // console.log(label[index]);
      setSpeechSideTab(label[index]);

      const menuDom = document.querySelector(`#menu_${label[index]}`);

      if (!menuDom) return;

      menuDom.scrollIntoView({
        behavior: 'auto',
        block: 'center',
      });
    };
  };

  useMemo(() => {
    if (speechOriginData) {
      setSpeechTitle(`${name.substring(0, 1)}**${t('会诊讲稿')}`);
      speechOriginData && handleCalcDomHeight();
    }
  }, [speechOriginData, speechSideMenu, speechFilterEdit]);

  useLayoutEffect(() => {
    // setSpeechSaveLoading(true);
    setTimeout(() => {
      setLayoutLoading(false);
    }, 1000);
  }, []);

  const handleBeforeUnload = (event: any) => {
    // 阻止默认行为
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.returnValue = '';
    // 设置返回值
    const key = `SPEECH_MAKING_EDIT_ERROR_${patientId}`;
    removeLocalStorage(`SPEECH_MAKING_EDIT_MENUS_${patientId}`);
    removeLocalStorage(key);
    removeLocalStorage(`SPEECH_MAKING_EDIT_DETAIL_KEY_${patientId}`);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <Spin spinning={layoutLoading || speechSaveLoading}>
      <section className={styles['speech-draft']}>
        <SpeechHeader title={speechTitle} />
        <main className={styles['speech-container']}>
          <SpeechSide />
          <SpeechMain />
          <SpeechSubfield />
          <SpeechIssue consultationId={consultationId} />
        </main>
      </section>
    </Spin>
  );
};

export default Lecture;
