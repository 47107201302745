import localEnum from '@/hooks/dict/localEnum';
import { useLocales } from '@/Locales';
import { updatePatientIssue } from '@/services/patient';
import { speechUpdateTimeAtom } from '@/store/speech';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { message, Space } from '@sinohealth/butterfly-ui-antd';
import { Form, Input, Radio } from '@sinohealth/butterfly-ui-components/lib';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

const IssueForm = (props: any) => {
  const { layout = 'horizontal', edit } = props;
  const [form] = Form.useForm();
  const { t } = useLocales();
  const { MDT_ISSUES_TYPE, LETTER_LIST } = localEnum;

  const speechUpdateTime = useRecoilValue(speechUpdateTimeAtom);
  const [firstRender, setFirstRender] = useState(true);
  const [issuePlaceholder, setIssuePlaceholder] = useState('请输入需讨论的议题');
  const [options] = useState<string[]>(['', '']);

  const values = Form.useWatch([], form);

  const typeList = [
    { value: MDT_ISSUES_TYPE.VOTING, label: t('投票型议题') },
    { value: MDT_ISSUES_TYPE.DISCUSSION, label: t('讨论型议题') },
  ];

  useEffect(() => {
    if (props?.type === MDT_ISSUES_TYPE.VOTING) {
      setIssuePlaceholder(t('请输入需投票的议题'));
    } else {
      setIssuePlaceholder(t('请输入需讨论的议题'));
    }
  }, [props?.type]);

  useEffect(() => {
    try {
      form?.validateFields().then(
        () => {
          if (edit && !firstRender) {
            // console.log({ values, firstRender });
            throttledOnFieldsChange(values);
          }
        },
        (err) => {
          // console.log(err);
        },
      );
    } catch (err) {
      console.log(err);
    }
  }, [form, values]);

  // 使用 throttle 函数限制 onFieldsChange 的执行频率
  const throttledOnFieldsChange = useCallback(
    _.debounce(
      (val) => {
        // console.log('Fields changed', val);
        if (val?.id) {
          const empty = val?.options?.filter((el: string) => !el);
          if (empty?.length) {
            // message.error('问题选项不能为空');
            return;
          }
          updatePatientIssue({
            ...val,
          }).then(() => {
            console.log('更新成功');
            props?.onUpdate && props?.onUpdate();
          });
        }
      },
      speechUpdateTime,
      { leading: false },
    ),
    [],
  ); // 3000 milliseconds = 3

  useEffect(() => {
    if (edit) {
      const timer = setTimeout(() => {
        setFirstRender(false);
        clearTimeout(timer);
      }, 3000);
    } else {
      setFirstRender(true);
    }
  }, [edit]);

  return (
    <Form
      layout={layout}
      style={{ width: layout === 'inline' && '100%' }}
      requiredMark={false}
      form={form}
      {...props}
    >
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="type" hidden>
        <Radio.Group defaultValue={props?.type}>
          {typeList.map((item) => {
            return (
              <Radio key={item.value} value={item.value}>
                {' '}
                {item.label}{' '}
              </Radio>
            );
          })}
        </Radio.Group>
      </Form.Item>
      {props?.type === MDT_ISSUES_TYPE.DISCUSSION && (
        <Form.Item
          name="issue"
          style={{ width: '100%' }}
          rules={[{ required: true, message: issuePlaceholder }]}
        >
          {layout === 'horizontal' ? (
            <Input.TextArea
              placeholder={issuePlaceholder}
              maxLength={100}
              showCount
              autoSize={{ minRows: 6, maxRows: 6 }}
            />
          ) : (
            <Input
              style={{ width: '100%' }}
              placeholder={issuePlaceholder}
              maxLength={100}
              showCount
            />
          )}
        </Form.Item>
      )}
      {props?.type === MDT_ISSUES_TYPE.VOTING && (
        <Space>
          <Form.Item name="issue" rules={[{ required: true, message: issuePlaceholder }]}>
            <Input placeholder={issuePlaceholder} maxLength={100} />
          </Form.Item>

          <Form.Item className="option-container">
            <Form.List name="options" initialValue={options}>
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, inx: number) => (
                      <Form.Item
                        style={{
                          width: 260,
                          display: 'inline-block',
                        }}
                        key={field.name}
                        label={`${LETTER_LIST[inx]}.`}
                      >
                        <Form.Item {...field} noStyle>
                          <Input
                            placeholder={t('请输入选项内容')}
                            style={{ width: fields.length - 1 === inx ? '89%' : '100%' }}
                            maxLength={15}
                          />
                        </Form.Item>
                        <Form.Item noStyle>
                          {inx === fields.length - 1 && (
                            <a
                              style={{
                                color: fields.length <= 2 ? '#d9d9d9' : '#1677ff',
                                padding: '8px 0 8px 10px',
                              }}
                              onClick={() => {
                                if (fields.length <= 2) {
                                  message.error(t('最少需要2个选项'));
                                  return;
                                }
                                remove(field.name);
                              }}
                            >
                              <MinusCircleOutlined />
                            </a>
                          )}
                        </Form.Item>
                      </Form.Item>
                    ))}
                    {fields.length < 5 && (
                      <Form.Item style={{ display: 'inline-block' }}>
                        <a style={{ fontSize: 14 }} type="link" onClick={() => add()}>
                          <PlusCircleOutlined /> {t('增加选项')}
                        </a>
                      </Form.Item>
                    )}
                  </>
                );
              }}
            </Form.List>
          </Form.Item>
        </Space>
      )}
    </Form>
  );
};

export default IssueForm;
