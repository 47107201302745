import { request } from '@/common/request';

/**
 * 主数据管理-系统标签分页
 * @param
 * @returns
 */
export const apiLabelsListPageV2 = (params: any) => {
  return request.post<any, any>('/labels/listPageV2', params);
};

/**
 * 主数据管理-系统标签列表
 * @param
 * @returns
 */
export const apiLabelsList = (params: any) => {
  return request.post<any, any>('/labels/list', params);
};

/**
 * 主数据管理-新增系统标签
 * @param
 * @returns
 */
export const apiLabelsAdd = (params: any) => {
  return request.post<any, any>('/labels/add', params, {
    // @ts-ignore
    hiddenErrorMsg: true,
  });
};

/**
 * 主数据管理-编辑系统标签
 * @param
 * @returns
 */
export const apiLabelsUpdate = (params: any) => {
  return request.post<any, any>('/labels/update', params, {
    // @ts-ignore
    hiddenErrorMsg: true,
  });
};

/**
 * 主数据管理-删除单个系统标签
 * @param
 * @returns
 */
export const apiLabelsDelete = (params: any) => {
  return request.post<any, any>('/labels/delete', params);
};

/**
 * 查询患者绑定标签编号
 * @param
 * @returns
 */
export const apiLabelsBindList = (patientId: string) => {
  return request.post<any, any>('/label/bindList', { patientId });
};

/**
 * 新增患者绑定标签
 * @param
 * @returns
 */
export const apiAddPatientTag = (params: any) => {
  return request.post<any, any>('/label/add', params);
};

// 搜索区域列表
export const apiSsearchAreaList = (params: any) => {
  return request.post<any, any>('/area/listPage', params);
};

// 更新区域
export const apiAreaUpdate = (params: any) => {
  return request.post<any, any>('/area/update', params);
};

// 移除区域
export const apiAreaDelete = (params: any) => {
  return request.post<any, any>('/area/delete', params);
};

/**
 * 医院管理-搜索区域列表
 * @param
 * @returns
 */
export const apiHospitalListPage = (params: any) => {
  return request.post<any, any>('/hospital/listPage', params);
};

// 医院管理添加
export const apiHospitalAdd = (params: any) => {
  return request.post<any, any>('/hospital/add', params);
};

// 医院管理编辑保存
export const apiHospitalUpdate = (params: any) => {
  return request.post<any, any>('/hospital/update', params);
};
// 医院管理移除
export const apiHospitalDelete = (params: any) => {
  return request.post<any, any>('/hospital/delete', params);
};

// 科室管理列表
export const apiDepartmentListPage = (params: any) => {
  return request.post<any, any>('/department/listPage', params);
};

// 科室管理新增
export const apiDepartmentAdd = (params: any) => {
  return request.post<any, any>('/department/add', params);
};
// 科室管理更新
export const apiDepartmentUpdate = (params: any) => {
  return request.post<any, any>('/department/update', params);
};
// 科室管理移除
export const apiDepartmentDelete = (params: any) => {
  return request.post<any, any>('/department/delete', params);
};

// 职称管理列表
export const apiJobTitleListPage = (params: any) => {
  return request.post<any, any>('/jobTitle/listPage', params);
};
// 职称管理创建
export const apiJobTitleAdd = (params: any) => {
  return request.post<any, any>('/jobTitle/add', params);
};
// 职称管理修改
export const apiJobTitleUpdate = (params: any) => {
  return request.post<any, any>('/jobTitle/update', params);
};
// 职称管理删除
export const apiJobTitleDelete = (params: any) => {
  return request.post<any, any>('/jobTitle/delete', params);
};

// BI 配置列表
export const apiAgencyDatListPage = (params: any) => {
  return request.post<any, any>('/agencyData/listPage', params);
};

// BI 配置 查询评分策略列表
export const apiAgencyListScoringStrategy = () => {
  return request.post<any, any>('/agency/listScoringStrategy');
};

// 保存 权限配置
export const apiAgencyDataAdd = (params: any) => {
  return request.post<any, any>('/agencyData/add', params);
};

// 会诊申请列表
export const apiConclusionApplyPage = (params: any) => {
  return request.post<any, any>('/conclusion/apply/page', params);
};

// 会诊申请详情
export const apiConclusionApplyDetail = (params: any) => {
  return request.post<any, any>('/conclusion/apply/detail', params);
};

// 会诊申请列表 - 查询联盟信息
export const apiUnionList = (params: any) => {
  return request.post<any, any>('/union/list', params);
};

// 保存申请会诊记录
export const apiConclusionApplyCreateOrUpdate = (params: any) => {
  return request.post<any, any>('/conclusion/apply/createOrUpdate', params);
};

// 合作申请列表
export const apiConsociationApplyPagePage = (params: any) => {
  return request.post<any, any>('/consociation/apply/page', params);
};

// 查询申请合作记录
export const apiConsociationApplyDetail = (params: any) => {
  return request.post<any, any>('/consociation/apply/detail', params);
};

// 创建或更新申请合作记录
export const apiConsociationApplyCreateOrUpdate = (params: any) => {
  return request.post<any, any>('/consociation/apply/createOrUpdate', params);
};

// 机构列表分页
export const apiInstitutionAgencyListPage = (params: any) => {
  return request.post<any, any>('/agency/listPage', params);
};

// 查询用户是否存在
export const apiDoctorGetUserName = (params: any) => {
  return request.post<any, any>('/doctor/getUserName', params);
};
// 添加机构
export const apiAgencyAdd = (params: any) => {
  return request.post<any, any>('/agency/add', params);
};

// 移除机构
export const apiAgencyDelete = (params: any) => {
  return request.post<any, any>('/agency/delete', params);
};

// 查询机构详情
export const apiAgencyGetDetails = (params: any) => {
  return request.post<any, any>('/agency/getDetails', params);
};

// 管理服务号
export const apiServiceNumberListPage = (params: any) => {
  return request.post<any, any>('/serviceNumber/listPage', params);
};

// 机构更新
export const apiAgencyUpdate = (params: any) => {
  return request.post<any, any>('/agency/update', params);
};

// 机构负责人信息
export const apiAgencyGetUser = (params: any) => {
  return request.post<any, any>('/agency/getUser', params);
};

// 机构管理，修改密码
export const apiAgencyUpdateUser = (params: any) => {
  return request.post<any, any>('/agency/updateUser', params);
};

export default {};
