import localEnum from '@/hooks/dict/localEnum';
import { useLocales } from '@/Locales';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { message } from '@sinohealth/butterfly-ui-antd';
import { Button, Form, Input, Radio } from '@sinohealth/butterfly-ui-components/lib';
import { useEffect, useState } from 'react';

const FormModalContent = (props: any) => {
  const { t } = useLocales();
  const { MDT_ISSUES_TYPE, LETTER_LIST } = localEnum;

  const [type, setType] = useState<any>(MDT_ISSUES_TYPE.VOTING);
  const [issuePlaceholder, setIssuePlaceholder] = useState('请输入需讨论的议题');
  const [options] = useState<string[]>(['', '']);

  const typeList = [
    { value: MDT_ISSUES_TYPE.VOTING, label: t('投票型议题') },
    { value: MDT_ISSUES_TYPE.DISCUSSION, label: t('讨论型议题') },
  ];

  const changeType = (e: any) => {
    const value = e.target.value;
    setType(value);
  };

  useEffect(() => {
    if (props.defaultFormData?.type) {
      setType(props.defaultFormData?.type);
    }
  }, [props.defaultFormData]);

  useEffect(() => {
    if (type === MDT_ISSUES_TYPE.VOTING) {
      setIssuePlaceholder(t('请输入需投票的议题'));
    } else {
      setIssuePlaceholder(t('请输入需讨论的议题'));
    }
  }, [type]);

  return (
    <Form layout="horizontal" requiredMark={false} {...props} labelCol={{ span: 6 }}>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item label={t('选择议题类型')} name="type">
        <Radio.Group defaultValue={type} onChange={changeType}>
          {typeList.map((item) => {
            return <Radio value={item.value}> {item.label} </Radio>;
          })}
        </Radio.Group>
      </Form.Item>
      {type === MDT_ISSUES_TYPE.DISCUSSION && (
        <Form.Item
          label={t('问题')}
          name="issue"
          rules={[{ required: true, message: issuePlaceholder }]}
        >
          <Input.TextArea
            placeholder={issuePlaceholder}
            maxLength={100}
            showCount
            autoSize={{ minRows: 6, maxRows: 6 }}
          />
        </Form.Item>
      )}
      {type === MDT_ISSUES_TYPE.VOTING && (
        <>
          <Form.Item
            label={t('问题')}
            name="issue"
            rules={[{ required: true, message: issuePlaceholder }]}
          >
            <Input placeholder={issuePlaceholder} maxLength={100} />
          </Form.Item>

          <Form.Item label={t('选项')}>
            <Form.List name="options" initialValue={options}>
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, inx: number) => (
                      <Form.Item key={field.name} label={`${LETTER_LIST[inx]}.`}>
                        <Form.Item {...field} noStyle>
                          <Input
                            placeholder={t('请输入选项内容')}
                            maxLength={15}
                            style={{ width: '90%' }}
                          />
                        </Form.Item>
                        <Form.Item noStyle>
                          {inx === fields.length - 1 && (
                            <Button
                              type="link"
                              disabled={fields.length <= 2}
                              icon={<MinusCircleOutlined style={{ color: '#F53F3F' }} />}
                              onClick={() => {
                                if (fields.length <= 2) {
                                  message.error(t('最少需要2个选项'));
                                  return;
                                }
                                remove(field.name);
                              }}
                            />
                          )}
                        </Form.Item>
                      </Form.Item>
                    ))}
                    <Form.Item label=" ">
                      <Button
                        type="link"
                        icon={<PlusCircleOutlined />}
                        disabled={fields.length >= 5}
                        onClick={() => add()}
                      >
                        {t('增加选项')}
                      </Button>
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default FormModalContent;
