import { request } from '@/common/request';

/**
 * @description: 分页查询联合病房MDT团队列表
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-11 13:41:48
 */
export const httpUnionwardTeamList = (params: any) => {
  return request.post<any, any>('/unionward/team/list', params);
};

/**
 * @description: 联合病房MDT团队详情
 * @author: KaifengLi
 * @param {any} teamId
 * @version: v2.22.0
 * @Date: 2024-09-11 15:55:30
 */
export const httpUnionwardTeamDetail = (id: string) => {
  return request.post<any, any>('/unionward/team/detail', { id });
};

/**
 * @description: 修改联合病房MDT团队
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-11 13:40:53
 */
export const httpUnionwardTeamUpdate = (params: any) => {
  return request.post<any, any>('/unionward/team/update', params);
};

/**
 * @description: 删除联合病房MDT团队
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-11 13:42:48
 */
export const httpUnionwardTeamDelete = (params: any) => {
  return request.post<any, any>('/unionward/team/delete', params);
};

/**
 * @description: 新增联合病房MDT团队以及成员
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-11 13:47:22
 */
export const httpUnionwardTeamAdd = (params: any) => {
  return request.post<any, any>('/unionward/team/add', params);
};

/**
 * @description: 修改联合病房MDT团队成员
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-11 13:46:41
 */
export const httpUnionwardTeamMemberUpdate = (params: any) => {
  return request.post<any, any>('/unionward/teamMember/update', params);
};

/**
 * @description: 删除联合病房MDT团队成员
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-11 13:46:29
 */
export const httpUnionwardTeamMemberDelete = (params: any) => {
  return request.post<any, any>('/unionward/teamMember/delete', params);
};

/**
 * @description: 新增联合病房MDT团队成员
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-11 13:46:17
 */
export const httpUnionwardTeamMemberAdd = (params: any) => {
  return request.post<any, any>('/unionward/teamMember/add', params);
};

export const httpEditTeamMember = (params: any) => {
  return request.post<any, any>('/unionward/team/editTeamMembers', params);
};

/**
 * @description: 联合病房MDT团队病例列表分页查询
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-12 10:43:05
 */
export const httpUnionwardTeamPatiets = (params: any) => {
  return request.post<any, any>('/unionward/team/teamPatietsListPage', params);
};

/**
 * @description: 联合会诊分页列表
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-12 14:15:27
 */
export const httpUnionwardConsultaList = (params: any) => {
  return request.post<any, any>('/unionward/consultation/listPage', params);
};

/**
 * @description: 按时间范围查询是否存在会诊，返回日期列表
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-12 16:28:15
 */
export const httpUnionwardConsultaTime = (params: any) => {
  return request.post<any, any>('/unionward/consultation/listConsultationCountGroupByTime', params);
};

/**
 * @description: 联合会诊详情
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-13 09:28:09
 */
export const httpUnionwardConsultaDetail = (params: any) => {
  return request.post<any, any>('/unionward/consultation/detail', params);
};

/**
 * @description: 新增联合病房MTD会诊
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-13 17:11:38
 */
export const httpUnionwardConsultaCreate = (params: any) => {
  return request.post<any, any>('/unionward/consultation/add', params);
};

/**
 * @description: 查询团队成员列表
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-13 15:53:44
 */
export const httpUnionwardTeamMembers = (params: any) => {
  return request.post<any, any>('/unionward/team/listTeamMembers', params);
};

/**
 * @description: 联合病房MDT团队病例列表 - 患者列表
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-13 16:22:21
 */
export const httpUnionwardTeamPatient = (params: any) => {
  return request.post<any, any>('/unionward/team/teamPatietsList', params);
};

/**
 * @description: 联合病房会诊添加患者
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-14 09:18:42
 */
export const httpUnionwardConsultatAddPatient = (params: any) => {
  return request.post<any, any>('/unionward/consultation/addPatients', params);
};

/**
 * @description: 联合病房会诊添加成员医生
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-14 09:19:11
 */
export const httpUnionwardConsultatAddDoctor = (params: any) => {
  return request.post<any, any>('/unionward/consultation/addDoctors', params);
};

/**
 * @description: 会诊回复列表
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-14 17:42:26
 */
export const httpUnionwardConsultatReplyList = (params: any) => {
  return request.post<any, any>('/unionward/zhMdtConsultationReply/list', params);
};

/**
 * @description: 新增会诊回复
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-14 17:48:01
 */
export const httpUnionwardConsultatReply = (params: any) => {
  return request.post<any, any>('/unionward/zhMdtConsultationReply/add', params);
};

/**
 * @description: 查询患者联合病房会诊列表
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-15 11:05:57
 */
export const httpUnionwardConsultatByPatientIds = (params: any) => {
  return request.post<any, any>('/unionward/consultation/listByPatientId', params);
};

/**
 * @description: 分页查询MDT团队列表
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.22.0
 * @Date: 2024-09-24 17:20:20
 */
export const httpUnionwardTeamListPage = (params: any) => {
  return request.post<any, any>('/unionward/team/listPage', params);
};

/** ------------------- 质控评分 --------------------- */

/**
 * @description: 质控评分 - 确认评分
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.23.0
 * @Date: 2024-09-26 09:34:49
 */
export const httpPatientScoreConfirm = (params: any) => {
  return request.post<any, any>('/patientScore/confirm', params);
};

/**
 * @description: 质控评分 - 开始计算评分
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.23.0
 * @Date: 2024-09-26 09:36:01
 */
export const httpPatientScoreStart = (params: any) => {
  // @ts-ignore
  return request.post<any, any>('/patientScore/start', params, { hiddenErrorMsg: true });
};

/**
 * @description: 质控评分 - 患者病历评分详情
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.23.0
 * @Date: 2024-09-26 09:36:42
 */
export const httpPatientScoreDetail = (params: any) => {
  return request.post<any, any>('/patientScore/detail', params);
};

/**
 * @description: 质控评分 - 查询患者评分状态
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.23.0
 * @Date: 2024-09-26 09:37:17
 */
export const httpPatientScoreRatingStatus = (params: any) => {
  return request.post<any, any>('/patientScore/getRatingStatus', params);
};

/**
 * @description: 保存时间轴
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.24.0
 * @Date: 2024-10-15 11:28:02
 */
export const httpTimeLineLectureNoteSave = (params: any) => {
  return request.post<any, any>('/timeLineLectureNotes/save', params);
};

/**
 * @description: 获取时间轴讲稿详情
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.24.0
 * @Date: 2024-10-15 11:28:48
 */
export const httpTimeLineLectureNoteDetail = (params: any) => {
  return request.post<any, any>('/timeLineLectureNotes/detail', params);
};

/**
 * @description: 单个-入院记录详情
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.24.0
 * @Date: 2024-10-18 11:55:27
 */
export const httpMedicalGetOne = (params: any) => {
  return request.post<any, any>('/medical/getOne', params);
};

/**
 * @description: 单个-查询出院记录成功
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.24.0
 * @Date: 2024-10-18 11:56:26
 */
export const httpHospitalDischargeDetail = (params: any) => {
  return request.post<any, any>('/hospital/discharge/detail', params);
};

/**
 * @description: 单个-影像报告详情-
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.24.0
 * @Date: 2024-10-18 11:57:24
 */
export const httpImagesGetOne = (params: any) => {
  return request.post<any, any>('/images/getOne', params);
};

/**
 * @description: 单个-手术记录详情-
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.24.0
 * @Date: 2024-10-18 11:57:24
 */
export const httpSurgicalGetOne = (params: any) => {
  return request.post<any, any>('/surgical/getOne', params);
};

/**
 * @description: 单个-病理报告详情-
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.24.0
 * @Date: 2024-10-18 11:57:24
 */
export const httpPathologyGetOne = (params: any) => {
  return request.post<any, any>('/pathology/getOne', params);
};

/**
 * @description: 单个-检验报告详情-
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.24.0
 * @Date: 2024-10-18 11:57:24
 */
export const httpInspectionGetOne = (params: any) => {
  return request.post<any, any>('/inspection/getOne', params);
};

/**
 * @description: 单个-基因检测详情-
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.24.0
 * @Date: 2024-10-18 11:57:24
 */
export const httpGeneticTestingGetOne = (params: any) => {
  return request.post<any, any>('/geneticTesting/getOne', params);
};

/**
 * @description: 单个-用药记录详情-
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.24.0
 * @Date: 2024-10-18 11:57:24
 */
export const httpDrugRecordsGetOne = (params: any) => {
  return request.post<any, any>('/drugRecords/getOne', params);
};

/**
 * @description: 单个-其他资料详情-
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.24.0
 * @Date: 2024-10-18 11:57:24
 */
export const httpOtherInfoDetail = (params: any) => {
  return request.post<any, any>('/other/information/detail', params);
};

/**
 * @description: 查询病历点评列表
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.25.0
 * @Date: 2024-10-24 19:07:43
 */
export const httpRecordCommentsList = (params: any) => {
  return request.post<any, any>('/zhMedicalRecordComments/list', params);
};

/**
 * @description: 新增病历点评
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.25.0
 * @Date: 2024-10-24 19:08:49
 */
export const httpRecordCommentsAdd = (params: any) => {
  return request.post<any, any>('/zhMedicalRecordComments/add', params);
};

/**
 * @description: 删除病例点评
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.25.0
 * @Date: 2024-10-24 19:30:47
 */
export const httpRecordCommentsDelete = (params: any) => {
  return request.post<any, any>('/zhMedicalRecordComments/delete', params);
};

/**
 * @description: 分页查询病历更新记录
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.25.0
 * @Date: 2024-10-24 19:14:10
 */
export const httpRecordUpdatePage = (params: any) => {
  return request.post<any, any>('/zhMedicalRecordUpdate/page', params);
};

/**
 * @description: 查询标签树
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.25.0
 * @Date: 2024-10-25 11:44:56
 */
export const httpLabelsTree = (params: any) => {
  return request.post<any, any>('/labels/tree', params);
};

/**
 * @description: 查询组织病种标签树
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.25.0
 * @Date: 2024-10-25 16:43:55
 */
export const httpLabelsTreeByOrg = (params: any) => {
  return request.post<any, any>('/labels/treeByOrg', params);
};

/**
 * @description: 批量修改标签
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.25.0
 * @Date: 2024-10-27 15:28:15
 */
export const httpLabelsUpdateBatch = (params: any) => {
  return request.post<any, any>('/labels/updateBatch', params);
};

/**
 * @description: 保存患者标签
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.25.0
 * @Date: 2024-10-27 15:27:36
 */
export const httpPatientLabelSave = (params: any) => {
  return request.post<any, any>('/patientLabel/save', params);
};

/**
 * @description: 查询患者绑定标签编号
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.25.0
 * @Date: 2024-10-27 15:59:40
 */
export const httpPatientLabelBindList = (params: any) => {
  return request.post<any, any>('/patientLabel/bindList', params);
};

/**
 * @description: 标签同名查询
 * @author: KaifengLi
 * @param {any} params
 * @version: v2.25.0
 * @Date: 2024-10-30 19:19:17
 */
export const httpLabelsListByName = (params: any) => {
  return request.post<any, any>('/labels/listByName', params);
};
