import { request } from '@/common/request';

/**
 * 账号管理列表
 * @param id
 * @returns
 */
export const systemUserListPage = (params: any) => {
  return request.post<any, any>('/system/user/listPage', params);
};

/**
 * 角色列表
 * @param id
 * @returns
 */
export const systemRoleList = (params: any) => {
  return request.post<any, any>('/system/role/list', params);
};

/**
 * 用户角色关联表
 * @param id
 * @returns
 */
export const systemUserRoleList = (params: any) => {
  return request.post<any, any>('/system/userRole/list', params);
};

/**
 * 关联角色保存
 * @param id
 * @returns
 */
export const systemUserRoleSaveUserRole = (params: any) => {
  return request.post<any, any>('/system/user/role/auth', params);
};

/**
 * 创建账号
 * @param
 * @returns
 */
export const systemUserSave = (params: any) => {
  return request.post<any, any>('/system/user/save', params);
};

/**
 * 更新账号/禁用账号/启用用账号
 * @param
 * @returns
 */
export const systemUserUpdatePersonalInfo = (params: any) => {
  return request.post<any, any>('/system/user/updatePersonalInfo', params);
};

/**
 * 删除账号
 * @param
 * @returns
 */
export const systemUserDelete = (params: any) => {
  return request.post<any, any>('/system/user/delete', params);
};

/**
 * 角色管理列表
 * @param
 * @returns
 */
export const apiSystemRoleListPage = (params: any) => {
  return request.post<any, any>('/system/role/listPage', params);
};

/**
 * 移除角色
 * @param
 * @returns
 */
export const apiSystemRoleDelete = (params: any) => {
  return request.post<any, any>('/system/role/delete', params);
};

/**
 * 创建角色
 * @param
 * @returns
 */
export const apiSystemRoleSaveOrUpdate = (params: any) => {
  return request.post<any, any>('/system/role/saveOrUpdate', params);
};

/**
 * 绑定角色角色
 * @param
 * @returns
 */
export const apiSystemRoleSaveRoleMenu = (params: any) => {
  return request.post<any, any>('/system/role/menu/auth', params);
};
/**
 * 获取系统管理
 * @param
 * @returns
 */
export const apiSystemRoleMenuList = (params: any) => {
  return request.post<any, any>('/system/role/menu/list', params);
};
/**
 * 获取菜单树
 * @param
 * @returns
 */
export const apiSystemMenuTreeMenu = (params: any) => {
  return request.post<any, any>('/system/menu/tree', params);
};

/**
 * 菜单管理列表
 * @param
 * @returns
 */
export const apiSystemMenuListPage = (params: any) => {
  return request.post<any, any>('/system/menu/listPage', params);
};

/**
 * 获取系统管理-菜单上级菜单ID
 * @param
 * @returns
 */
export const apiSystemMenuGetMenus = (params: any) => {
  return request.post<any, any>('/system/menu/getMenus', params);
};

/**
 * 获取系统管理-创建菜单
 * @param
 * @returns
 */
export const apiSystemMenuSave = (params: any) => {
  return request.post<any, any>('/system/menu/save', params);
};

/**
 * 系统管理-移除菜单
 * @param
 * @returns
 */
export const apiSystemMenuDelete = (params: any) => {
  return request.post<any, any>('/system/menu/delete', params);
};

// 权限组
export const saveOrUpdateGroup = (params: any) => {
  return request.post('/system/acl/saveOrUpdate', params);
};
export const deleteGroup = (params: any) => {
  return request.post('/system/acl/delete', params);
};
export const getGroupListPage = (params: any) => {
  return request.post('/system/acl/listPage', params);
};
export const getGroupList = (params: any) => {
  return request.post('/system/acl/list', params);
};
export const getGroupMenuList = (params: any) => {
  return request.post('/system/acl/menu/list', params);
};
export const saveGroupMenu = (params: any) => {
  return request.post('/system/acl/menu/auth', params);
};
// 判断账号名是否存在
export const existUser = (params: any) => {
  return request.post('/system/user/exist', params);
};
export const getUserDetail = (params: any) => {
  return request.post<any, any>('/system/user/detail', params);
};

// 病种管理
export const getDiseaseListPage = (params: any) => {
  return request.post('/diseases/page', params);
};
export const saveDisease = (params: any) => {
  return request.post('/diseases/save', params);
};
export const deleteDisease = (params: any) => {
  return request.post('/diseases/delete ', params);
};

export default {};
