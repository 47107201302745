import { Line } from '@ant-design/plots';
import { useEffect, useRef } from 'react';

const ChartLine = (props: any) => {
  const canvas = useRef<any>(null);
  const { config = {}, onChange } = props;
  const { name, xAxisUnit, yAxisUnit, correlationChartDatas } = config;

  useEffect(() => {
    if (canvas.current) {
      setTimeout(() => {
        toDataURL();
      }, 600);
    }
  }, [canvas]);
  const lineConfig: any = {
    data: correlationChartDatas?.map((item: any) => {
      return {
        ...item,
        yAxis: Number(item.yAxis),
      };
    }),
    padding: 60,
    height: 340,
    xField: 'xAxis',
    yField: 'yAxis',
    xAxis: {
      title: {
        text: xAxisUnit,
        position: 'end',
        autoRotate: true,
      },
    },
    yAxis: {
      title: {
        text: yAxisUnit,
        position: 'end',
        autoRotate: true,
      },
    },
    theme: {
      styleSheet: {
        backgroundColor: '#f5f5f5', // background: #8bafe359;
      },
    },
    onReady(chart: any) {
      canvas.current = chart;
    },
  };
  // 获取图表 base64 数据
  const toDataURL = () => {
    const imgUrl = canvas.current?.toDataURL();
    onChange && onChange(imgUrl);
    // canvas.current?.downloadImage();
  };
  return (
    <div style={{ background: '#fff', borderRadius: 4 }}>
      {config.name && <Line key={config.key} {...lineConfig} />}
    </div>
  );
};

export default ChartLine;
