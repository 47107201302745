/* eslint-disable indent */
/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/*
 * @Description: 制作讲稿
 * @Author: likaifeng
 * @Date: 2023-11-30 09:52:30
 * @LastEditTime: 2024-11-04 15:22:58
 * @LastEditors: 阿峰
 */
import { useLocales } from '@/Locales';
import {
  httpConsultationDetail,
  httpConsultationIssueVoteStatistics,
  httpGetDoctorInfo,
  httpPatientInfo,
} from '@/services/consultation';
import {
  getComparisoChartList,
  getCorrelationChartList,
  getDischargeListPage,
  getDrugRecordsListPage,
  getGeneticTestingListPage,
  getImagesListPage,
  getInspectionListPage,
  getIssueListPage,
  getMedicalListPage,
  getOtherInfoListPage,
  getPathologyListPage,
  getPatientDetail,
  getSurgicalListPage,
  patientSpeechSubmit,
} from '@/services/patient';
import {
  speechEditMenuObjAtom,
  speechFilterMakeDatasAtom,
  speechMakingDetailkKeyAtom,
  speechOriginDataAtom,
  speechSaveLoadingAtom,
  speechSideMenusAtom,
  speechTabEnumKeysAtom,
} from '@/store/speech';
import { formatDate, previewFile, transformTumorStage } from '@/utils';
import { imageType } from '@/utils/constant';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '@/utils/cookies';
import type { MenuProps } from '@sinohealth/butterfly-ui-components';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ORIGIN_TYPE } from '../lecture/components/HocForm';
import {
  BaseInfoCheckbox,
  DrugRecordCheckbox,
  GeneticTestingCheckbox,
  HospitalDischargeRecordsCheckbox,
  ImageReportCheckbox,
  InspectionReportCheckbox,
  MedicalRecordCheckbox,
  OtherInformationCheckbox,
  PathologyReportCheckbox,
  SurgicalRecordCheckbox,
} from './config';

/**
 * @description: 患者详情
 * @author: likaifeng
 * @param {string} pId 患者id
 * @param {string} id 会诊id
 * @param {string} name 患者名称
 * @param {boolean} flag 是否为讲稿预览
 * @return {*}
 * @Date: 2023-11-30 10:08:29
 */
export const usePatientInfo = (pId: string, id: string, name: string, flag?: boolean): any => {
  const [patientDetail, setPatientDetail] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [rePatientDetail, setRePatientDetail] = useState(false);
  // 患者数据
  const [patientSource, setPatientSource] = useState<any[]>([]);
  const [currentPatient, setCurrentPatient] = useState<any>();
  const [patientIds, setPatientIds] = useState('');
  const [speechTitle, setSpeechTitle] = useState('');
  const [patientName, setPatientName] = useState('');
  const [dropdownItems, setDropdownItems] = useState<MenuProps['items']>([]);
  const { t, locales } = useLocales();

  const [speechMakingDetailkKeys, setSpeechMakingDetailkKeys] = useRecoilState(
    speechMakingDetailkKeyAtom,
  );
  const speechTabEnumKey = useRecoilValue(speechTabEnumKeysAtom);
  const setSpeechSideMenu = useSetRecoilState(speechSideMenusAtom);
  const setSpeechFilterMakeData = useSetRecoilState(speechFilterMakeDatasAtom);

  let keysT = {};
  let menus = [
    { label: 'baseInfo', name: '患者信息' },
    { label: 'patientHistory', name: '病史摘要' },
  ];

  /**
   * @description: 患者详情
   * @author: likaifeng
   * @param {string} ids 患者id
   * @return {*}
   * @Date: 2023-11-30 15:32:05
   */
  const httpPatientInfoReq = async (ids?: string) => {
    try {
      !flag && removeLocalStorage('SPEECH_MAKING_EDIT_DETAIL_KEY');
      keysT = {};
      menus = [
        { label: 'baseInfo', name: '患者信息' },
        { label: 'patientHistory', name: '病史摘要' },
      ];
      setLoading(true);
      const result: any = await httpPatientInfo(ids || pId, id);
      console.log('httpPatientInfoReq', result);
      // setSpeechOriginData(result);
      const {
        patient,
        MedicalRecords,
        hospitalDischargeRecords,
        zhImageReports,
        zhSurgicalRecords,
        zhPathologyReports,
        zhDrugRecords,
        zhInspectionReports,
        zhGeneticTestings,
        otherInformations,
        zhPatientMdtIssues,
        comparisoCharts,
        correlationCharts,
        pushedIssueVote,
      } = result;

      const filterDetail: any = {};
      // 基本信息
      filterDetail.baseInfo = !flag
        ? handleCalcBaseInfo({ ...patient }, BaseInfoCheckbox, 'baseInfo')
        : handleCalcBaseInfo(patient, [], 'baseInfo', 'baseInfo');

      // 入院记录
      filterDetail.medicalRecord = !flag
        ? handleCalcMedicalRecord([...MedicalRecords], MedicalRecordCheckbox, 'medicalRecord')
        : handleCalcMedicalRecord(MedicalRecords, [], 'medicalRecord', 'medicalRecord');

      // 出院记录
      filterDetail.hospitalDischargeRecords = !flag
        ? handleeCalcHospitalDischarge(
            [...hospitalDischargeRecords],
            HospitalDischargeRecordsCheckbox,
            'hospitalDischargeRecords',
          )
        : handleeCalcHospitalDischarge(
            hospitalDischargeRecords,
            [],
            'hospitalDischargeRecords',
            'hospitalDischargeRecords',
          );

      // 影像报告
      filterDetail.imageReport = !flag
        ? handleCalcMedicalRecord([...zhImageReports], ImageReportCheckbox, 'imageReport')
        : handleCalcMedicalRecord(zhImageReports, [], 'imageReport', 'imageReport');

      // 手术信息
      filterDetail.surgicalRecord = !flag
        ? handleCalcMedicalRecord([...zhSurgicalRecords], SurgicalRecordCheckbox, 'surgicalRecord')
        : handleCalcMedicalRecord(zhSurgicalRecords, [], 'surgicalRecord', 'surgicalRecord');

      // 病理报告
      filterDetail.pathologyReport = !flag
        ? handleCalcPathologyReport(
            [...zhPathologyReports],
            PathologyReportCheckbox,
            'pathologyReport',
          )
        : handleCalcPathologyReport(zhPathologyReports, [], 'pathologyReport', 'pathologyReport');

      // 检验报告
      filterDetail.inspectionReport = !flag
        ? handleCalcSpectionReport(
            [...zhInspectionReports],
            InspectionReportCheckbox,
            'inspectionReport',
          )
        : handleCalcSpectionReport(zhInspectionReports, [], 'inspectionReport', 'inspectionReport');

      // 基因检测
      filterDetail.geneticTesting = !flag
        ? handleCalcMedicalRecord([...zhGeneticTestings], GeneticTestingCheckbox, 'geneticTesting')
        : handleCalcMedicalRecord(zhGeneticTestings, [], 'geneticTesting', 'geneticTesting');

      // 用药记录
      filterDetail.drugRecord = !flag
        ? handleCalcMedicalRecord([...zhDrugRecords], DrugRecordCheckbox, 'drugRecord')
        : handleCalcMedicalRecord(zhDrugRecords, [], 'drugRecord', 'drugRecord');

      // 对比图
      filterDetail.compareImage = handleCalcComparisoCharts([...comparisoCharts], 'compareImage');

      // 趋势图
      filterDetail.correlationCharts = handleCalcCorrelationCharts(
        [...correlationCharts],
        'correlationCharts',
      );

      // 参考文献
      filterDetail.otherInformation = !flag
        ? handleCalcOtherInfo([...otherInformations], OtherInformationCheckbox, 'otherInformation')
        : handleCalcOtherInfo(otherInformations, [], 'otherInformation', 'otherInformation');

      // MDT议题
      filterDetail.mdtIssues = handleCalcMdtIssue(zhPatientMdtIssues);

      // 是否有推送过投票提醒
      filterDetail.pushedIssueVote = pushedIssueVote;

      setPatientDetail(result);
      console.log(filterDetail);

      // 存储到状态管理;
      setSpeechFilterMakeData(filterDetail);
      !flag && setLocalStorage('SPEECH_MAKING_EDIT_DETAIL_KEY', speechMakingDetailkKeys);

      setLoading(false);
      setRePatientDetail(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  /**
   * @description: 查询会诊详情
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-10-30 09:33:04
   */
  const handleGetConsultationDetailReq = async () => {
    try {
      const result: any = await httpConsultationDetail(id);
      if (result) {
        // 患者列表
        console.log(result);
        const { zhConsultationPatients } = result;
        setPatientSource(zhConsultationPatients);
        // 患者信息
        const patient = zhConsultationPatients.filter((el: any) => el.patientName === name);
        if (patient && patient.length) {
          setPatientName(patient[0].patientName);
          httpPatientInfoReq(patient[0].patientId);
          setPatientIds(patient[0].patientId);
          setCurrentPatient(patient[0]);
          calcDropdownItems(zhConsultationPatients);
        }

        // 会诊标题
        setSpeechTitle(result.name);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // 患者详情
  useEffect(() => {
    if (pId || rePatientDetail) {
      // httpPatientInfoReq();
      setPatientIds(pId);
    }
  }, [pId, rePatientDetail]);

  // 会诊详情
  useEffect(() => {
    id && handleGetConsultationDetailReq();
  }, [id]);

  // 患者名称
  useEffect(() => {
    if (name) {
      setSpeechTitle(`${name.substring(0, 1)}**${t('会诊讲稿')}`);
      setPatientName(name);
      setPatientSource([{ patientName: name, patientId: pId }]);
      calcDropdownItems([{ patientName: name, patientId: pId }]);
    }
  }, [name]);

  /**
   * @description: 处理患者基本信息
   * @author: likaifeng
   * @param {*} data 数据源 - 患者信息
   * @param {*} val 需要展示的key Objeact[]
   * @param {*} tab 当前tab
   * @param {*} fils 过滤的key string[]
   * @return {*}
   * @Date: 2023-10-26 11:26:57
   */
  const handleCalcBaseInfo = (data: any, val: any[], tab: string, fils?: string): any => {
    const keys = fils ? speechMakingDetailkKeys[fils] : val.map((el) => el.key);

    if (data) {
      // 肿瘤分期
      if (data.tumorStage) {
        const tumorStageStr = transformTumorStage(data.tumorStage);
        data.tumorStage = tumorStageStr;
      }

      // 性别
      // data.gender = gender[data.gender];
    }

    const d: any = {};

    Object.keys(data).forEach((el: any) => {
      keys.forEach((item: any) => {
        d[item] = data[item];
        d.id = data.id;
        d.doctorId = data.doctorId;
      });
    });

    !fils && handleUpdateSpeechMakeDetail(tab, keys);

    return d;
  };

  /**
   * @description: 处理 入院记录 影像报告 手术信息 数据
   * @author: likaifeng
   * @param {*} data 入院记录 影像报告 手术信息 数据源
   * @param {*} val 需要展示的key Objeact[]
   * @param {*} tab 当前tab
   * @param {*} fils 过滤的key string[]
   * @return {*}
   * @Date: 2023-10-26 11:43:29
   */
  const handleCalcMedicalRecord = (data: any, val: any[], tab: string, fils?: string): any => {
    let keys = val.map((el) => el.key);

    // 左侧菜单
    const menu: any = {
      label: tab,
      name: speechTabEnumKey[tab],
      children: [],
    };

    const arr: any = [];

    const sortArr = handleSortByLocale([...data], 'recordTime');

    sortArr.forEach((el: any) => {
      const d: any = {};

      // 处理时间格式
      el.recordTime = formatDate(el.recordTime);
      // 发起图片请求处理
      if (el.imagePath) {
        el.imagePath = handleStartDownloadImg(el.imagePath);
      }
      // 手术信息 发起图片请求处理
      if (el.reportImage) {
        el.reportImage = handleStartDownloadImg(el.reportImage);
      }

      // 对应 id 的 key
      if (fils && speechMakingDetailkKeys[fils]) {
        keys = speechMakingDetailkKeys[fils][Number(el.id)];
      }
      if (!keys.length) return;

      const hasFlag = keys.includes('remarks');

      // 左侧菜单处理
      if (tab === 'imageReport' && el.imagesType !== undefined) {
        // 影像类别
        el.imagesType = filterImageType(el.imagesType);

        menu.children.push({
          label: `${tab}_${el.id}`,
          name: el.recordTime,
          id: el.id,
          type: el.imagesType,
          remarks: hasFlag ? el.remarks : '',
        });
      } else {
        menu.children.push({
          label: `${tab}_${el.id}`,
          name: el.recordTime,
          id: el.id,
          remarks: hasFlag ? el.remarks : '',
        });
      }

      keys.forEach((item) => {
        d[item] = el[item];
        // 影像类别 字典处理
        if (tab === 'imageReport' && item === 'imagesType') {
          d[item] = el.imagesType;
        }
        // 用药记录特殊处理时间
        if (tab === 'drugRecord' && item === 'useTime') {
          // 处理时间格式
          d[item] = `${formatDate(el.startMedicationTime)}至${formatDate(el.endMedicationTime)}`;
        }
        d.id = el.id;
      });
      arr.push(d);
    });

    handleUploadSpeechSideMenu(menu, arr.length);

    if (!fils) {
      const c = mapKeysForId(sortArr, keys);
      handleUpdateSpeechMakeDetail(tab, c);
    }

    return arr || [];
  };

  /**
   * @description: 处理出院记录数据
   * @author: likaifeng
   * @param {*} data 出院记录 数据源
   * @param {*} val 需要展示的key Objeact[]
   * @param {*} tab 当前tab
   * @param {*} fils 过滤的key string[]
   * @return {*}
   * @Date: 2023-10-26 13:46:06
   */
  const handleeCalcHospitalDischarge = (data: any, val: any[], tab: string, fils?: string) => {
    let keys = val.map((el) => el.key);

    // 左侧菜单
    const menu: any = {
      label: tab,
      name: speechTabEnumKey[tab],
      children: [],
    };

    const arr: any[] = [];

    const sortArr = handleSortByLocale([...data], 'dischargeTime');

    sortArr.forEach((el: any) => {
      const d: any = {};

      // 处理时间格式
      el.admitTime = formatDate(el.admitTime);
      el.dischargeTime = formatDate(el.dischargeTime);
      //  发起图片请求处理
      el.imagePath = handleStartDownloadImg(el.imagePath);

      // 对应 id 的 key
      if (fils && speechMakingDetailkKeys[fils]) {
        keys = speechMakingDetailkKeys[fils][Number(el.id)];
      }
      if (!keys.length) return;

      menu.children.push({
        label: `${tab}_${el.id}`,
        name: el.dischargeTime,
        id: el.id,
      });

      keys.forEach((item: any) => {
        d[item] = el[item];
        d.id = el.id;
      });
      arr.push(d);
    });

    handleUploadSpeechSideMenu(menu, arr.length);

    if (!fils) {
      const c = mapKeysForId(sortArr, keys);
      handleUpdateSpeechMakeDetail(tab, c);
    }

    return arr;
  };

  /**
   * @description: 处理 病理报告 数据
   * @author: likaifeng
   * @param {*} data 病理报告 数据源
   * @param {*} val 需要展示的key Objeact[]
   * @param {*} tab 当前tab
   * @param {*} fils 过滤的key string[]
   * @return {*}
   * @Date: 2023-10-26 13:50:53
   */
  const handleCalcPathologyReport = (data: any, val: any[], tab: string, fils?: string) => {
    let keys = val.map((el) => el.key);

    // 左侧菜单
    const menu: any = {
      label: tab,
      name: speechTabEnumKey[tab],
      children: [],
    };

    const arr: any[] = [];

    const sortArr = handleSortByLocale([...data], 'recordTime');

    sortArr.forEach((el: any) => {
      if (typeof el.diagnosisDescription === 'string') {
        el.diagnosisDescription = JSON.parse(el.diagnosisDescription);
      }
      const d: any = {};

      // 处理时间格式
      el.recordTime = formatDate(el.recordTime);
      //  发起图片请求处理
      el.imagePath = handleStartDownloadImg(el.imagePath);

      // 对应 id 的 key
      if (fils && speechMakingDetailkKeys[fils]) {
        keys = speechMakingDetailkKeys[fils][Number(el.id)];
      }
      if (!keys.length) return;

      menu.children.push({
        label: `${tab}_${el.id}`,
        name: el.recordTime,
        id: el.id,
      });

      keys.forEach((item) => {
        d[item] = el[item];
        d.id = el.id;
        if ((item === 'immunohistochemistry' || item === 'summary') && el.diagnosisDescription) {
          d[item] = el.diagnosisDescription[item];
        }
        if (item === 'tumorStaging' && el.diagnosisDescription) {
          d[item] = transformTumorStage(el.diagnosisDescription[item]);
        }
      });
      arr.push(d);
    });

    handleUploadSpeechSideMenu(menu, arr.length);

    if (!fils) {
      const c = mapKeysForId(sortArr, keys);
      handleUpdateSpeechMakeDetail(tab, c);
    }

    return arr;
  };

  /**
   * @description: 处理 检验报告 数据
   * @author: likaifeng
   * @param {*} data 检验报告 数据源
   * @param {*} val 需要展示的key Objeact[]
   * @param {*} tab 当前tab
   * @param {*} fils 过滤的key string[]
   * @return {*}
   * @Date: 2023-10-26 13:55:14
   */
  const handleCalcSpectionReport = (data: any, val: any[], tab: string, fils?: string) => {
    let keys = val.map((el) => el.key);

    // 左侧菜单
    const menu: any = {
      label: tab,
      name: speechTabEnumKey[tab],
      children: [],
    };

    const arr: any = [];

    const sortArr = handleSortByLocale([...data], 'recordTime');

    sortArr.forEach((el: any) => {
      const d: any = {};

      // 处理时间
      el.recordTime = formatDate(el.recordTime);
      //  发起图片请求处理
      el.imagePath = handleStartDownloadImg(el.imagePath);

      // 对应 id 的 key
      if (fils && speechMakingDetailkKeys[fils]) {
        keys = speechMakingDetailkKeys[fils][Number(el.id)];
      }
      if (!keys.length) return;

      menu.children.push({
        label: `${tab}_${el.id}`,
        name: el.recordTime,
        id: el.id,
      });

      // console.log(keys);
      keys.forEach((item) => {
        // 检验项目 特殊处理
        const k = ['type', 'tumorMarkers', 'unit', 'resultValueItem', 'referenceItem'];
        if (item === 'zhInspectionItems') {
          d.zhInspectionItems = [];
          const kk: string[] = keys.filter((p: any) => k.includes(p));
          // console.log({ kk });

          const spection: any = [];
          el.zhInspectionItems.forEach((te: any) => {
            const c: any = {};

            kk.forEach((lis) => {
              // if (lis === 'type') {
              //   te.type = inspctionType[Number(te.type)];
              // }
              // if (lis === 'tumorMarkers') {
              //   console.log(tumorMarker?.[Number(te.tumorMarkers)]);
              //   te.tumorMarkers = tumorMarker?.[Number(te.tumorMarkers)];
              // }
              if (lis !== 'zhInspectionItems') {
                c[lis] = te[lis];
                c.id = te.id;
              }
            });
            // console.log(c);
            spection.push(c);
          });
          // console.log({ spection });
          d.zhInspectionItems = [...spection];
        } else if (!k.includes(item)) {
          d[item] = el[item];
          d.id = el.id;
        }
      });
      arr.push(d);
    });

    handleUploadSpeechSideMenu(menu, arr.length);

    if (!fils) {
      const c = mapKeysForId(sortArr, keys);
      handleUpdateSpeechMakeDetail(tab, c);
    }

    return arr;
  };

  /**
   * @description: 处理 参考文献 数据
   * @author: likaifeng
   * @param {*} data 参考文献 数据源
   * @param {*} val 需要展示的key Objeact[]
   * @param {*} tab 当前tab
   * @param {*} fils 过滤的key string[]
   * @return {*}
   * @Date: 2023-10-26 11:43:29
   */
  const handleCalcOtherInfo = (data: any, val: any[], tab: string, fils?: string): any => {
    let keys = val.map((el) => el.key);

    // 左侧菜单
    const menu: any = {
      label: tab,
      name: speechTabEnumKey[tab],
      children: [],
    };

    const arr: any = [];

    const sortArr = handleSortByLocale([...data], 'informationDate');

    sortArr.forEach((el: any) => {
      const d: any = {};

      // 处理时间格式
      el.informationDate = formatDate(el.informationDate);
      // 手术信息 发起图片请求处理
      if (el.reportImage) {
        el.reportImage = handleStartDownloadImg(el.reportImage);
      }

      // 对应 id 的 key
      if (fils && speechMakingDetailkKeys[fils]) {
        keys = speechMakingDetailkKeys[fils][Number(el.id)];
      }
      if (!keys.length) return;

      const hasFlag = keys.includes('informationType');

      // 左侧菜单处理
      menu.children.push({
        label: `${tab}_${el.id}`,
        name: el.informationDate,
        id: el.id,
        remarks: hasFlag ? el.informationType : '',
      });

      keys.forEach((item) => {
        d[item] = el[item];
        d.id = el.id;
      });
      arr.push(d);
    });

    handleUploadSpeechSideMenu(menu, arr.length);

    if (!fils) {
      const c = mapKeysForId(sortArr, keys);
      handleUpdateSpeechMakeDetail(tab, c);
    }

    return arr || [];
  };

  /**
   * @description: 处理 对比图 数据
   * @author: likaifeng
   * @param {*} data 对比图 源数据
   * @param {*} tab 当前tab
   * @return {*}
   * @Date: 2023-10-27 10:03:28
   */
  const handleCalcComparisoCharts = (data: any, tab: string) => {
    // 左侧菜单
    const menu: any = {
      label: tab,
      name: speechTabEnumKey[tab],
      children: [],
    };

    const arr = data.map((el: any) => {
      //  发起图片请求处理
      el.nextImage = handleStartDownloadImg(el.nextImage);
      el.previousImage = handleStartDownloadImg(el.previousImage);

      // 处理菜单
      menu.children.push({
        label: `${tab}_${el.id}`,
        name: el.name,
        id: el.id,
      });
      return el;
    });

    handleUploadSpeechSideMenu(menu, arr.length);

    return arr;
  };

  /**
   * @description: 处理 趋势图 数据
   * @author: likaifeng
   * @param {*} data 趋势图 数据源
   * @param {*} tab 当前tab
   * @return {*}
   * @Date: 2023-10-27 10:05:44
   */
  const handleCalcCorrelationCharts = (data: any, tab: string) => {
    // 左侧菜单
    const menu: any = {
      label: tab,
      name: speechTabEnumKey[tab],
      children: [],
    };
    const arr = data.map((el: any) => {
      //  发起图片请求处理
      el.thumbnail = handleStartDownloadImg(el.thumbnail);

      // 处理菜单
      menu.children.push({
        label: `${tab}_${el.id}`,
        name: el.name,
        id: el.id,
      });

      return el;
    });

    handleUploadSpeechSideMenu(menu, arr.length);

    return arr;
  };

  /**
   * @description: 处理MDT议题
   * @author: likaifeng
   * @param {*} data 议题数据源
   * @return {*}
   * @Date: 2023-10-27 09:53:28
   */
  const handleCalcMdtIssue = (data: any) => {
    return data.map((el: any) => {
      return {
        modifiedAt: formatDate(el.modifiedAt),
        createAt: formatDate(el.createAt),
        type: el.type,
        options: el.options,
        issue: el.issue,
        patientId: el.patientId,
        id: el.id,
      };
    });
  };

  /**
   * @description: 更新左侧菜单数据到状态管理
   * @author: likaifeng
   * @param {*} menu 菜单数据
   * @return {*}
   * @Date: 2023-10-30 17:18:23
   */
  const handleUploadSpeechSideMenu = (menu: any, flags: number) => {
    if (flags) {
      // 默认显示字段
      menus = [...menus, menu];
      setSpeechSideMenu(menus);
    }
  };

  /**
   * @description: 根据数据遍历 id 对应的数据key
   * @author: likaifeng
   * @param {*} data 数据源 对象数组
   * @param {*} keys 需要显示的key
   * @return {*}
   * @Date: 2023-10-26 13:44:22
   */
  const mapKeysForId = (data: any, keys: string[]) => {
    const e: any[] = data.map((el: any) => el.id);
    const c: any = {};
    e.forEach((el: any) => (c[el] = keys));

    return c;
  };

  /**
   * @description: 更新显示字段数据
   * @author: likaifeng
   * @param {*} tab 当前的tab
   * @param {*} keys 要展示的key
   * @return {*}
   * @Date: 2023-10-26 11:21:09
   */
  const handleUpdateSpeechMakeDetail = (tab: string, keys: string[]) => {
    if (!Object.keys(keys).length) {
      keysT = { ...keysT, [tab]: null };
    } else {
      // 默认显示字段
      keysT = { ...keysT, [tab]: keys };
    }
    setSpeechMakingDetailkKeys(keysT);
  };

  /**
   * @description: 计算患者下拉选项
   * @author: likaifeng
   * @param {any} arr
   * @return {*}
   * @Date: 2023-11-30 15:42:51
   */
  const calcDropdownItems = (arr: any[]) => {
    const a = arr.map((el) => {
      return {
        label: el.patientName,
        key: el.patientId,
      };
    });
    setDropdownItems(a);
  };

  return {
    patientDetail,
    loading,
    speechTitle,
    patientIds,
    patientName,
    setPatientName,
    patientSource,
    dropdownItems,
    currentPatient,
    setCurrentPatient,
    setRePatientDetail,
  };
};

/**
 * @description: 查询医生详情（单个）
 * @author: likaifeng
 * @param {string} id
 * @return {*}
 * @Date: 2023-11-30 14:54:35
 */
export const useDoctorDetail = (id: string | number) => {
  const [doctorDetail, setDoctorDetail] = useState<any>();

  /**
   * @description: 查询医生详情（单个）
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-10-30 09:33:04
   */
  const httpGetDoctorInfoReq = async () => {
    try {
      const result: any = await httpGetDoctorInfo({ id });
      if (result) {
        setDoctorDetail(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    id && httpGetDoctorInfoReq();
  }, [id]);

  return { doctorDetail };
};

/**
 * @description: 查询会诊患者议题投票统计结果（图表数据）
 * @param {string} id 会诊编号
 */

export const useIssueVoteStatistics = (id: string | number) => {
  const [issueVoteStatistics, setIssueVoteStatistics] = useState<any>();

  /**
   * @description: 查询医生详情（单个）
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-10-30 09:33:04
   */
  const httpConsultationIssueVoteStatisticsReq = async () => {
    try {
      const result: any = await httpConsultationIssueVoteStatistics({ id });
      if (result) {
        setIssueVoteStatistics(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    id && httpConsultationIssueVoteStatisticsReq();
  }, [id]);

  return { issueVoteStatistics };
};

/**
 * @description: 根据时间戳进行排序
 * @author: likaifeng
 * @param {*} arr 排序源数组
 * @param {*} key 需要比较的 key
 * @param {*} type 升序 asc 降序 desc 默认 asc
 * @return {*}
 * @Date: 2023-11-01 10:52:36
 */
export const handleSortByLocale = (arr: any[], key: string, type: any = 'desc'): any => {
  // 升序
  if (type === 'asc') {
    return arr.sort((a, b) => (b[key] > a[key] ? 1 : -1));
  }
  // 降序
  return arr.sort((a, b) => (a[key] > b[key] ? 1 : -1));
};

/**
 * @description: 发起图片请求处理
 * @author: likaifeng
 * @param {string} imgPath 图片 key 集合 字符串
 * @return {*} 数组
 * @Date: 2023-10-26 14:23:08
 */
export const handleStartDownloadImg = (imgPath: string, preview?: boolean): any => {
  if (!imgPath) return [];
  const paths = imgPath.split(',');
  const imageList: any[] = [];

  paths.forEach((itm, index) => {
    imageList.push({
      id: new Date().getTime() + index,
      key: itm,
      type: handleVerdictUrlType(itm),
      previewUrl: preview ? previewFile(itm) : '',
      url: '',
    });
  });
  return imageList;
};

/**
 * @description: 根据地址判断是图片还是视频
 * @author: likaifeng
 * @param {*} url 文件地址
 * @return {*}
 * @Date: 2023-10-26 17:01:34
 */
export const handleVerdictUrlType = (url: string): any => {
  // 声明支持的图片和视频文件扩展名
  const imageExtensions = ['jpg', 'jpeg', 'gif', 'png'];
  const videoExtensions = ['mp4', 'wmv', 'avi', 'mov'];
  const ext = url.split('.')[1]?.toLowerCase();
  if (imageExtensions.includes(ext)) return 'image';

  if (videoExtensions.includes(ext)) return 'video';
};

/**
 * @description: 影像类别 字典处理
 * @author: likaifeng
 * @param {*} type 影像类别
 * @return {*}
 * @Date: 2023-10-26 14:33:05
 */
export const filterImageType = (type: string) => {
  let imageTypeName = '';
  imageType.forEach((itm) => {
    if (itm.label === Number(type)) {
      if (itm.label === 6) {
        imageTypeName = itm.name;
      } else {
        imageTypeName = itm.name;
      }
    }
  });
  return imageTypeName;
};

// export const httpGetMedicalListPageReq = (id: string | number) => {
//   getMedicalListPage({ patientId: id, pageSize: 99999 }).then((res) => {
//     console.log(res);
//   });
// };

/**
 * @description: 获取患者入院记录
 * @author: KaifengLi
 * @param {string} id
 * @version: v2.20.0
 * @Date: 2024-08-07 11:59:41
 */
export const useMedicalList = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const [reload, setReload] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const speechSaveLoading = useRecoilValue(speechSaveLoadingAtom);
  const [medicalList, setMedicalList] = useState<any[]>([]);
  const [menus, setMenus] = useState<any>();

  const httpGetMedicalListPageReq = async () => {
    try {
      if (saveLoading) return;
      setSaveLoading(true);
      const res: any = await getMedicalListPage({ patientId, pageSize: 99999 });

      const { list } = res;

      setMenus(handleCalcMedicalMenus('medicalRecord', list, '入院记录', 2));

      setMedicalList(list || []);

      setReload(false);
    } catch (err) {
      console.log(err);
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    httpGetMedicalListPageReq();
  }, [patientId]);

  useEffect(() => {
    reload && httpGetMedicalListPageReq();
  }, [reload]);

  useEffect(() => {
    speechSaveLoading && setReload(speechSaveLoading);
  }, [speechSaveLoading]);

  return { menus, medicalList, setMedicalList, patientId, setReload };
};

const handleCalcMedicalMenus = (tab: string, list: any, name: string, sort: number) => {
  const menu: any = {
    label: tab,
    name,
    sort,
    children: [],
  };

  if (tab === 'imageReport') {
    list.forEach((el: any) => {
      menu.children.push({
        label: `${tab}_${el.id}`,
        name: formatDate(el.recordTime),
        id: el.id,
        type: filterImageType(el.imagesType),
        remarks: el.remarks || '',
      });
    });
  } else {
    list.forEach((el: any) => {
      menu.children.push({
        label: `${tab}_${el.id}`,
        name: formatDate(el.recordTime),
        id: el.id,
        remarks: el.remarks || '',
      });
    });
  }

  return menu;
};

/**
 * @description: 获取出院记录
 * @author: KaifengLi
 * @version: v2.20.0
 * @Date: 2024-08-07 14:59:30
 */
export const useDischargeList = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const [reload, setReload] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [menus, setMenus] = useState<any>();
  const [dischargeRecords, setDischargeRecords] = useState<any[]>([]);

  const speechSaveLoading = useRecoilValue(speechSaveLoadingAtom);

  const httpGetDischargeListPageReq = async () => {
    try {
      if (saveLoading) return;
      setSaveLoading(true);
      const res: any = await getDischargeListPage({ patientId, pageSize: 99999 });

      const menu: any = {
        label: 'hospitalDischargeRecords',
        name: '出院记录',
        sort: 3,
        children: [],
      };

      res.forEach((el: any) => {
        menu.children.push({
          label: `hospitalDischargeRecords_${el.id}`,
          name: formatDate(el.dischargeTime),
          id: el.id,
        });
      });

      setMenus(menu);

      setDischargeRecords(res || []);
      setReload(false);
    } catch (err) {
      console.log(err);
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    httpGetDischargeListPageReq();
  }, [patientId]);

  useEffect(() => {
    reload && httpGetDischargeListPageReq();
  }, [reload]);

  useEffect(() => {
    speechSaveLoading && setReload(speechSaveLoading);
  }, [speechSaveLoading]);

  return { dischargeRecords, setDischargeRecords, setReload, patientId, menus };
};

/**
 * @description: 获取患者影像报告
 * @author: KaifengLi
 * @version: v2.20.0
 * @Date: 2024-08-07 15:33:04
 */
export const useImagesList = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const [reload, setReload] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [imagesList, setImagesList] = useState<any[]>([]);
  const [menus, setMenus] = useState<any>();

  const speechSaveLoading = useRecoilValue(speechSaveLoadingAtom);

  const httpGetImagesListPageReq = async () => {
    try {
      if (saveLoading) return;
      setSaveLoading(true);
      const res: any = await getImagesListPage({ patientId, pageSize: 99999 });

      setMenus(handleCalcMedicalMenus('imageReport', res.list, '影像报告', 4));
      setImagesList(res.list || []);
      setReload(false);
    } catch (err) {
      console.log(err);
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    httpGetImagesListPageReq();
  }, [patientId]);

  useEffect(() => {
    reload && httpGetImagesListPageReq();
  }, [reload]);

  useEffect(() => {
    speechSaveLoading && setReload(speechSaveLoading);
  }, [speechSaveLoading]);

  return { imagesList, setImagesList, setReload, patientId, menus };
};

/**
 * @description: 获取患者手术信息
 * @author: KaifengLi
 * @version: v2.20.0
 * @Date: 2024-08-07 16:13:43
 */
export const useSurgicalList = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const [reload, setReload] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [surgicalList, setSurgicalList] = useState<any[]>([]);

  const [menus, setMenus] = useState<any>();

  const speechSaveLoading = useRecoilValue(speechSaveLoadingAtom);

  const httpGetSurgicalListPageReq = async () => {
    try {
      if (saveLoading) return;
      setSaveLoading(true);
      const res: any = await getSurgicalListPage({ patientId, pageSize: 99999 });

      setMenus(handleCalcMedicalMenus('surgicalRecord', res.list, '手术信息', 5));
      setSurgicalList(res.list || []);
      setReload(false);
    } catch (err) {
      console.log(err);
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    httpGetSurgicalListPageReq();
  }, [patientId]);

  useEffect(() => {
    reload && httpGetSurgicalListPageReq();
  }, [reload]);

  useEffect(() => {
    speechSaveLoading && setReload(speechSaveLoading);
  }, [speechSaveLoading]);

  return { surgicalList, setSurgicalList, setReload, patientId, menus };
};

/**
 * @description: 获取患者病理报告
 * @author: KaifengLi
 * @version: v2.20.0
 * @Date: 2024-08-07 16:44:22
 */
export const usePathologyList = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const [reload, setReload] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [pathologyList, setPathologyList] = useState<any[]>([]);
  const [menus, setMenus] = useState<any>();

  const speechSaveLoading = useRecoilValue(speechSaveLoadingAtom);

  const httpGetPathologyListPageReq = async () => {
    try {
      if (saveLoading) return;
      setSaveLoading(true);
      const res: any = await getPathologyListPage({ patientId, pageSize: 99999 });
      const { list } = res;

      const menu: any = {
        label: 'pathologyReport',
        name: '病理报告',
        sort: 6,
        children: [],
      };
      const temp = list.map((el: any) => {
        if (typeof el.diagnosisDescription === 'string') {
          el.diagnosisDescription = JSON.parse(el.diagnosisDescription);
        }
        menu.children.push({
          label: `pathologyReport_${el.id}`,
          name: formatDate(el.recordTime),
          id: el.id,
        });
        return {
          ...el,
          ...el.diagnosisDescription,
        };
      });

      setMenus(menu);
      setPathologyList(temp || []);
      setReload(false);
    } catch (err) {
      console.log(err);
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    httpGetPathologyListPageReq();
  }, [patientId]);

  useEffect(() => {
    reload && httpGetPathologyListPageReq();
  }, [reload]);

  useEffect(() => {
    speechSaveLoading && setReload(speechSaveLoading);
  }, [speechSaveLoading]);

  return { pathologyList, setPathologyList, setReload, patientId, menus };
};

/**
 * @description: 获取患者检验报告
 * @author: KaifengLi
 * @version: v2.20.0
 * @Date: 2024-08-07 17:03:45
 */
export const useInspectionList = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const [reload, setReload] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [inspectionList, setInspectionList] = useState<any[]>([]);
  const [menus, setMenus] = useState<any>();

  const speechSaveLoading = useRecoilValue(speechSaveLoadingAtom);

  const httpGetInspectionListPageReq = async () => {
    try {
      if (saveLoading) return;
      setSaveLoading(true);
      const res: any = await getInspectionListPage({ patientId, pageSize: 99999 });
      const { list } = res;
      // 左侧菜单
      const menu: any = {
        label: 'inspectionReport',
        name: '检验报告',
        sort: 8,
        children: [],
      };

      list.forEach((el: any) => {
        menu.children.push({
          label: `inspectionReport_${el.id}`,
          name: formatDate(el.recordTime),
          id: el.id,
        });
      });

      setMenus(menu);

      setInspectionList(list || []);
      setReload(false);
    } catch (err) {
      console.log(err);
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    httpGetInspectionListPageReq();
  }, [patientId]);

  useEffect(() => {
    reload && httpGetInspectionListPageReq();
  }, [reload]);

  useEffect(() => {
    speechSaveLoading && setReload(speechSaveLoading);
  }, [speechSaveLoading]);

  return { inspectionList, setInspectionList, setReload, patientId, menus };
};

/**
 * @description: 获取患者基因检测信息
 * @author: KaifengLi
 * @version: v2.20.0
 * @Date: 2024-08-07 17:46:22
 */
export const useGeneticTestingList = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const [reload, setReload] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [geneticTestingList, setGeneticTestingList] = useState<any[]>([]);

  const [menus, setMenus] = useState<any>();

  const speechSaveLoading = useRecoilValue(speechSaveLoadingAtom);

  const httpGetGeneticTestingListPageReq = async () => {
    try {
      if (saveLoading) return;
      setSaveLoading(true);
      const res: any = await getGeneticTestingListPage({ patientId, pageSize: 99999 });
      const { list } = res;

      setMenus(handleCalcMedicalMenus('geneticTesting', list, '基因检测', 9));
      setGeneticTestingList(list || []);
      setReload(false);
    } catch (err) {
      console.log(err);
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    httpGetGeneticTestingListPageReq();
  }, [patientId]);

  useEffect(() => {
    reload && httpGetGeneticTestingListPageReq();
  }, [reload]);

  useEffect(() => {
    speechSaveLoading && setReload(speechSaveLoading);
  }, [speechSaveLoading]);

  return { geneticTestingList, setGeneticTestingList, setReload, patientId, menus };
};

/**
 * @description: 获取患者用药记录信息
 * @author: KaifengLi
 * @version: v2.20.0
 * @Date: 2024-08-07 18:12:29
 */
export const useDrugRecordsList = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const [reload, setReload] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [drugRecordsList, setDrugRecordsList] = useState<any[]>([]);
  const [menus, setMenus] = useState<any>();

  const speechSaveLoading = useRecoilValue(speechSaveLoadingAtom);

  const httpGetDrugRecordsListPageReq = async () => {
    try {
      if (saveLoading) return;
      setSaveLoading(true);
      const res: any = await getDrugRecordsListPage({ patientId, pageSize: 99999 });
      const { list } = res;
      setMenus(handleCalcMedicalMenus('drugRecord', list, '用药记录', 10));
      setDrugRecordsList(list || []);
      setReload(false);
    } catch (err) {
      console.log(err);
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    httpGetDrugRecordsListPageReq();
  }, [patientId]);

  useEffect(() => {
    reload && httpGetDrugRecordsListPageReq();
  }, [reload]);

  useEffect(() => {
    speechSaveLoading && setReload(speechSaveLoading);
  }, [speechSaveLoading]);

  return { drugRecordsList, setDrugRecordsList, setReload, patientId, menus };
};

/**
 * @description: 获取患者其他资料
 * @author: KaifengLi
 * @version: v2.20.0
 * @Date: 2024-08-07 18:26:58
 */
export const useOtherInfoList = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const [reload, setReload] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [otherInfoList, setOtherInfoList] = useState<any[]>([]);
  const [menus, setMenus] = useState<any>();

  const speechSaveLoading = useRecoilValue(speechSaveLoadingAtom);

  const httpGetOtherInfoListPageReq = async () => {
    try {
      if (saveLoading) return;
      setSaveLoading(true);
      const res: any = await getOtherInfoListPage({ patientId, pageSize: 99999 });
      const { list } = res;

      const menu: any = {
        label: 'otherInformation',
        name: '参考文献',
        sort: 13,
        children: [],
      };
      list.forEach((el: any) => {
        menu.children.push({
          label: `otherInformation_${el.id}`,
          name: formatDate(el.informationDate),
          id: el.id,
          remarks: el.informationType || '',
        });
      });
      setMenus(menu);
      setOtherInfoList(list || []);
      setReload(false);
    } catch (err) {
      console.log(err);
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    httpGetOtherInfoListPageReq();
  }, [patientId]);

  useEffect(() => {
    reload && httpGetOtherInfoListPageReq();
  }, [reload]);

  useEffect(() => {
    speechSaveLoading && setReload(speechSaveLoading);
  }, [speechSaveLoading]);

  return { otherInfoList, setOtherInfoList, setReload, patientId, menus };
};

/**
 * @description: 获取患者信息
 * @author: KaifengLi
 * @version: v2.20.0
 * @Date: 2024-08-08 11:05:41
 */
export const usePatientDetail = (consultationId?: string) => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const formType = params.getAll('share')[0]; // share 共享病例库
  const [reload, setReload] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [patientDetail, setPatientDetail] = useState<any>();

  const speechSaveLoading = useRecoilValue(speechSaveLoadingAtom);

  const httpGetPatientDetailReq = async () => {
    try {
      if (saveLoading) return;
      setSaveLoading(true);
      const res: any = await getPatientDetail({
        patientId,
        consultationId,
        needDesensitization: formType === 'share', // 是否脱敏
      });

      setPatientDetail(res || undefined);
      setReload(false);
    } catch (err) {
      console.log(err);
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    httpGetPatientDetailReq();
  }, [patientId]);

  useEffect(() => {
    reload && httpGetPatientDetailReq();
  }, [reload]);

  useEffect(() => {
    speechSaveLoading && setReload(speechSaveLoading);
  }, [speechSaveLoading]);

  return { patientDetail, setPatientDetail, setReload };
};

/**
 * @description:  获取患者对比图片信息
 * @author: KaifengLi
 * @version: v2.20.0
 * @Date: 2024-08-08 11:36:35
 */
export const useComparisoChart = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const [reload, setReload] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [comparisoChart, setComparisoChart] = useState<any[]>([]);
  const [menus, setMenus] = useState<any>();

  const httpGetComparisoChartListReq = async () => {
    try {
      if (saveLoading) return;
      setSaveLoading(true);
      const res: any = await getComparisoChartList({ patientId });

      const menu: any = {
        label: 'compareImage',
        name: '对比图',
        sort: 11,
        children: [],
      };

      res?.forEach((el: any) => {
        // 处理菜单
        menu.children.push({
          label: `compareImage_${el.id}`,
          name: el.name,
          id: el.id,
        });
        return el;
      });
      setMenus(menu);

      setComparisoChart(res || []);
      setReload(false);
    } catch (err) {
      console.log(err);
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    httpGetComparisoChartListReq();
  }, [patientId]);

  useEffect(() => {
    reload && httpGetComparisoChartListReq();
  }, [reload]);

  return { comparisoChart, setReload, patientId, menus };
};

/**
 * @description: 获取患者趋势图信息
 * @author: KaifengLi
 * @version: v2.20.0
 * @Date: 2024-08-08 13:51:02
 */
export const useCorrelationChart = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const [reload, setReload] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [correlationChart, setCorrelationChart] = useState<any[]>([]);
  const [menus, setMenus] = useState<any>();

  const httpGetCorrelationChartListReq = async () => {
    try {
      if (saveLoading) return;
      setSaveLoading(true);
      const res: any = await getCorrelationChartList({ patientId });
      const menu: any = {
        label: 'correlationCharts',
        name: '趋势图',
        sort: 12,
        children: [],
      };

      const arr = res?.map((el: any) => {
        //  发起图片请求处理
        el.thumbnail = handleStartDownloadImg(el.thumbnail);
        // 处理菜单
        menu.children.push({
          label: `correlationCharts_${el.id}`,
          name: el.name,
          id: el.id,
        });
        return el;
      });
      setMenus(menu);

      setCorrelationChart(arr || []);
      setReload(false);
    } catch (err) {
      console.log(err);
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    httpGetCorrelationChartListReq();
  }, [patientId]);

  useEffect(() => {
    reload && httpGetCorrelationChartListReq();
  }, [reload]);

  return { correlationChart, setReload, patientId, menus };
};

/**
 * @description: 获取患者MDT议题
 * @author: KaifengLi
 * @version: v2.20.0
 * @Date: 2024-08-08 14:39:04
 */
export const useIssueList = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const [reload, setReload] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [issueList, setIssueList] = useState<any[]>([]);

  const httpGetIssueListPageReq = async () => {
    try {
      if (saveLoading) return;
      setSaveLoading(true);
      const res: any = await getIssueListPage({ patientId, pageSize: 99999 });

      setIssueList(res?.list || []);
      setReload(false);
    } catch (err) {
      console.log(err);
    } finally {
      setSaveLoading(false);
    }
  };

  useEffect(() => {
    httpGetIssueListPageReq();
  }, [patientId]);

  useEffect(() => {
    reload && httpGetIssueListPageReq();
  }, [reload]);

  return { issueList, setReload, patientId };
};

/**
 * @description: 存储所有的数据源
 * @author: KaifengLi
 * @param {ORIGIN_TYPE} type 数据类型
 * @param {any} origin 元数据
 * @version: v2.20.0
 * @Date: 2024-08-09 11:00:56
 */
export const useCombinateData = (type?: ORIGIN_TYPE, origin?: any, menus?: any) => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const [speechEditMenuObj, setSpeechEditMenuObj] = useRecoilState(speechEditMenuObjAtom);
  const [speechOriginData, setSpeechOriginData] = useRecoilState(speechOriginDataAtom);

  useEffect(() => {
    if (type && origin) {
      const detail = getLocalStorage(`SPEECH_MAKING_EDIT_DETAIL_KEY_${patientId}`);
      const menu = getLocalStorage(`SPEECH_MAKING_EDIT_MENUS_${patientId}`);
      if (!detail) {
        const temp = { ...speechOriginData };
        temp[type] = origin;
        setLocalStorage(`SPEECH_MAKING_EDIT_DETAIL_KEY_${patientId}`, temp);
      } else {
        detail[type] = origin;
        setLocalStorage(`SPEECH_MAKING_EDIT_DETAIL_KEY_${patientId}`, detail);
      }
      if (menus) {
        if (!menu) {
          const tempMenu = { ...speechEditMenuObj };
          tempMenu[type] = menus;
          setLocalStorage(`SPEECH_MAKING_EDIT_MENUS_${patientId}`, tempMenu);
        } else {
          menu[type] = menus;
          setLocalStorage(`SPEECH_MAKING_EDIT_MENUS_${patientId}`, menu);
        }
      }

      setSpeechEditMenuObj(getLocalStorage(`SPEECH_MAKING_EDIT_MENUS_${patientId}`));
      setSpeechOriginData(getLocalStorage(`SPEECH_MAKING_EDIT_DETAIL_KEY_${patientId}`));
    }
  }, [type, origin]);
};

/**
 * @description: 存储编辑图片时的状态
 * @author: KaifengLi
 * @param {boolean} v
 * @version: v2.20.0
 * @Date: 2024-08-13 15:24:38
 */
export const useSetImageFlag = () => {
  const [speechEditImage, setImageEditFlag] = useState<boolean>(
    getLocalStorage('EDIT_IMAGES_FLAG'),
  );

  const handleSetImageEditFlag = (v: boolean) => {
    setLocalStorage('EDIT_IMAGES_FLAG', v);
    setImageEditFlag(v);
  };

  // const speechEditImage = getLocalStorage('EDIT_IMAGES_FLAG');

  return { speechEditImage, handleSetImageEditFlag };
};

/**
 * @description: 提交患者讲稿
 * @author: KaifengLi
 * @version: v2.21.0
 * @Date: 2024-08-15 13:44:11
 */
export const usePatientSpeechSubmit = () => {
  const [params] = useSearchParams();
  const patientId = params.getAll('patientId')[0];
  const submitType = params.getAll('submit')[0];

  const [canSubmit, setCanSubmit] = useState(false);

  const httpPatientSpeechSubmitReq = async () => {
    try {
      const res = await patientSpeechSubmit({ patientId });
      console.log(res);
    } catch (err) {
      console.log(err);
    } finally {
      setCanSubmit(false);
    }
  };

  useEffect(() => {
    // 只有提交时才发送请求
    if (canSubmit && submitType === 'submit') {
      httpPatientSpeechSubmitReq();
    }
  }, [submitType, canSubmit]);

  return { setCanSubmit };
};
