import { useLocales } from '@/Locales';
import { Modal } from '@sinohealth/butterfly-ui-components';
import { forwardRef, useImperativeHandle, useState } from 'react';
import AssociatedTab from '../AssociatedTab';

interface RelaModelProps {
  onChange?: (id: string, type: string, time: string) => void;
}

const RelaModel = forwardRef((props: RelaModelProps, ref: any) => {
  const { onChange } = props;
  const [open, setOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<string>('');

  const { t } = useLocales();

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
  }));

  return (
    <Modal
      title={t('关联讲稿')}
      open={open}
      width={1000}
      onOk={() => {
        onChange?.(selectedRowKeys, selectedTab, selectedTime);
        setOpen(false);
      }}
      onCancel={() => setOpen(false)}
      afterClose={() => {
        setSelectedRowKeys('');
        setSelectedTab('');
        setSelectedTime('');
      }}
    >
      <AssociatedTab
        tableHeight={200}
        refId={selectedRowKeys}
        refType={selectedTab}
        onChange={(id, type, time) => {
          // console.log('id', id, 'type', type, 'time', time);
          setSelectedRowKeys(id);
          setSelectedTab(type);
          setSelectedTime(time);
        }}
      />
    </Modal>
  );
});

export default RelaModel;
