import { useLocales } from '@/Locales';
import { PatientInfoStatus } from '@/pages/patient/detail';
import { CloseOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Timeline } from '@sinohealth/butterfly-ui-components';
import classes from 'classnames';
import moment from 'moment';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styles from './index.less';

const RecordsTimeLine = (props: any, ref: any) => {
  const { data, value, onChange, onDelete, label = '记录' } = props;
  const [options, setOptions] = useState<any>([]);
  const [createBtnDisabled, setCreateBtnDisabled] = useState<boolean>(false);
  const permission = useRecoilValue(PatientInfoStatus);
  const { t } = useLocales();

  useEffect(() => {
    setOptions(
      data.map((item: any) => {
        return {
          ...item,
          label: moment(item.label).format('YYYY-MM-DD'),
        };
      }),
    );
    // 当没有选中某记录，或者新增数据后，或者删除数据后，默认选中第一条
    if (!value || value === 'new' || !data.map((item: any) => item.value).includes(value)) {
      const defaultItem = data[0] || {};
      onChange(defaultItem.value, defaultItem.data);
    } else {
      const selectItem = data.filter((item: any) => item.value === value)[0];
      if (selectItem) {
        onChange(selectItem.value, selectItem.data);
      }
    }
  }, [data]);

  useEffect(() => {
    setCreateBtnDisabled(options.map((item: any) => item.value).includes('new'));
  }, [options]);

  useImperativeHandle(ref, () => {
    return {
      handleCreate,
    };
  });

  const handleCreate = () => {
    const newItem = {
      label: `${t('新增')}${label}`,
      value: 'new',
      data: {},
    };
    setOptions([newItem, ...options]);
    onChange(newItem.value, newItem.data);
  };
  if (options.length === 0) {
    return null;
  }
  console.log(permission.edit && !createBtnDisabled);
  return (
    <div className={styles.timeLine}>
      {permission.edit && !createBtnDisabled && (
        <Button
          disabled={!permission.edit || createBtnDisabled}
          type="primary"
          block
          onClick={handleCreate}
        >
          {t('新增')}
          {label}
          <DoubleRightOutlined />
        </Button>
      )}
      <div className={styles.body}>
        <Timeline>
          {options.map((item: any) => {
            const classNames = classes({
              [styles.timeItem]: true,
              [styles.selected]: item.value === value,
            });
            return (
              <Timeline.Item color={item.value === value ? 'blue' : 'gray'} key={item.value}>
                <span className={classNames} onClick={() => onChange(item.value, item.data)}>
                  {item.label}
                </span>
                {item.value === value && item.value !== 'new' && permission.edit && (
                  <Popconfirm
                    title={`${t('确认删除')}？`}
                    description={t('删除后数据不可恢复')}
                    onConfirm={() => onDelete && onDelete(item)}
                    okText={t('删除')}
                    cancelText={t('取消')}
                  >
                    <CloseOutlined className={styles.deleteBtn} />
                  </Popconfirm>
                )}
              </Timeline.Item>
            );
          })}
        </Timeline>
      </div>
    </div>
  );
};

export default React.forwardRef(RecordsTimeLine);
